const firebaseConfig = {
  apiKey: "AIzaSyCoSEMz4v6MsNChROUPmdaJIalKYBElM2w",
  authDomain: "bookings-458d1.firebaseapp.com",
  databaseURL: "https://bookings-458d1.firebaseio.com",
  projectId: "bookings-458d1",
  storageBucket: "bookings-458d1.appspot.com",
  messagingSenderId: "26978550656",
  appId: "1:26978550656:web:aabeb4e3dfb80577303765",
  measurementId: "G-CJ9DM6DFJF"
};

export default firebaseConfig;