import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import BusinessDaysSchedule from './BusinessDay';
import SettingsRepository from './SettingsRepository';
import { v4 as uuid } from 'uuid';

/**
 * Creates a new `BusinessDaySchedule` by duplicating the schedule
 * of another value, assigning to it a new description.
 */
export default class CreateBusinessDayScheduleUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
  ) {
  }

  execute = (copyFromScheduleId: string, description: string): Observable<BusinessDaysSchedule> =>
    this.settingsRepository
      .getBusinessDaysSchedule(copyFromScheduleId)
      .pipe(
        take(1),
        switchMap((schedule): Observable<BusinessDaysSchedule> => {
          return this.settingsRepository.saveBusinessDaySchedule({
            ...schedule,
            identifier: uuid(),
            description: description,
          });
        })
      )
}