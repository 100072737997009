import { Observable } from "rxjs";
import { dateTruncatingTime } from "../../Utils/DateUtils";
import Appointment from "./Appointment";
import AppointmentsRepository from "./AppointmentsRepository";

export default class GetAppointmentsUseCase {

  constructor(
    private readonly appointmentsRepository: AppointmentsRepository
  ) {
  }

  execute = (from: Date, to: Date): Observable<Appointment[]> =>
    this.appointmentsRepository.getAppointments(
      dateTruncatingTime(from), dateTruncatingTime(to)
    );
}