import DateFnsUtils from '@date-io/date-fns';
import { Box, createStyles, IconButton, makeStyles, TextField } from '@material-ui/core';
import { AddCircleOutline, DeleteOutline } from '@material-ui/icons';
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers';
import React from 'react';
import strings from '../../Utils/LocalizedStrings';
import { locale } from '../../../Utils/DateUtils';

const useStyles = makeStyles((theme) => createStyles({
  column: {
    display: "flex",
    flexDirection: "column",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap"
  },
  timePicker: {
    width: "44px"
  },
  peopleCount: {
    width: "34px"
  },
  horizontalPadding: {
    marginLeft: theme.spacing(1),
  }
}));

interface BusinessHoursPickerProps {
  readonly slots: BusinessHourSlot[];
  readonly onSlotStartChange: (index: number, date: Date) => void;
  readonly onSlotEndChange: (index: number, date: Date) => void;
  readonly onSlotCustomersLimitChange: (index: number, limit: number) => void;
  readonly onSlotDelete: (index: number) => void;
  readonly onSlotAdd: () => void;

  /**
   * True to tell the component that a `MuiPickersUtilsProvider` component is already
   * set as parent for it.
   */
  readonly inheritsPickerUtilsProvider?: boolean;
}

export interface BusinessHourSlot {
  readonly start: Date;
  readonly end: Date;
  readonly customersLimit: number;
}

const BusinessHoursPicker = (props: BusinessHoursPickerProps) => {

  const classes = useStyles();

  const shouldShowAddButton = (index: number) => index === props.slots.length - 1;
  const shouldShowDeleteButton = (index: number) => index > 0;

  const component = (
    <Box className={classes.column}>
      {props.slots.map((slot, index) =>
        <Box className={classes.row} key={index.toString()}>
          <span>{strings.admin.settings.from}</span>
          <TimePicker
            className={`${classes.timePicker} ${classes.horizontalPadding}`}
            autoOk
            cancelLabel={strings.commons.cancel}
            ampm={false}
            minutesStep={5}
            value={slot.start}
            onChange={(date) => date && props.onSlotStartChange(index, date)}
          />
          <span className={`${classes.horizontalPadding}`}>{strings.admin.settings.to}</span>
          <TimePicker
            className={`${classes.timePicker} ${classes.horizontalPadding}`}
            autoOk
            cancelLabel={strings.commons.cancel}
            ampm={false}
            minutesStep={5}
            value={slot.end}
            onChange={(date) => date && props.onSlotEndChange(index, date)}
          />
          <span className={`${classes.horizontalPadding}`}>{strings.admin.settings.customersLimit}</span>
          <TextField
            className={`${classes.peopleCount} ${classes.horizontalPadding}`}
            type="number"
            value={slot.customersLimit}
            onChange={(event) => {
              const number = event.target.value;
              if (number) {
                props.onSlotCustomersLimitChange(index, parseInt(number))
              }
            }}
          />
          {shouldShowDeleteButton(index)
            ? <IconButton
              className={`${classes.horizontalPadding}`}
              onClick={() => props.onSlotDelete(index)}
              size="small">
              <DeleteOutline/>
            </IconButton>
            : null
          }
          {shouldShowAddButton(index)
            ? <IconButton
              className={shouldShowDeleteButton(index) ? `` : `${classes.horizontalPadding}`}
              onClick={() => props.onSlotAdd()}
              size="small">
              <AddCircleOutline/>
            </IconButton>
            : null
          }
        </Box>
      )}
    </Box>
  );

  return props.inheritsPickerUtilsProvider
    ? component
    : <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>{component}</MuiPickersUtilsProvider>;
}

export default BusinessHoursPicker;