import { FormControl, InputLabel, MenuItem, Select as MaterialSelect, FormHelperText } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import KeyValuePair from '../../Utils/KeyValuePair';

interface SelectProps {
  readonly label?: string;
  readonly entries: KeyValuePair<string, string>[];
  readonly value?: string;
  readonly onSelect: (key: string) => void;
  readonly errorMessage?: string;
  readonly className?: string;
  readonly enabled?: boolean;
}

/**
 * A wrapped on top of the outlined Material Select that provides managed label positioning and 
 * value change events.
 */
const Select = (props: SelectProps) => {
  const [value, setValue] = useState("");
  const [labelWidth, setLabelWidth] = useState(0);
  const formItemLabel = useRef<HTMLLabelElement>(null);
  useEffect(() => {
    setLabelWidth(formItemLabel.current?.offsetWidth ?? 0)
  }, []);
  const id = uuid();
  return (
    <FormControl
      variant="outlined"
      className={props.className}
      error={!(!props.errorMessage)}
      disabled={!props.enabled}>
      <InputLabel ref={formItemLabel} id={`select-label+${id}`}>{props.label ?? ""}</InputLabel>
      <MaterialSelect
        labelId={`select-label+${id}`}
        labelWidth={labelWidth}
        value={props.value ?? value}
        onChange={e => {
          const value = e.target?.value as string;
          if (value) {
            props.onSelect(value);
            setValue(value);
          }
        }} >
        {props.entries.map(entry =>
          <MenuItem key={entry.key} value={entry.key}>{entry.value}</MenuItem>
        )}
      </MaterialSelect>
      {props.errorMessage ? <FormHelperText>{props.errorMessage}</FormHelperText> : null}
    </FormControl>
  )
}

export default Select;