import DateFnsUtils from '@date-io/date-fns';
import { Box, createStyles, FormControl, FormHelperText, Grid, makeStyles, TextField } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import React from 'react';
import NewCustomerErrors from './NewCustomerErrors';
import NewCustomerInfo from './NewCustomerInfo';
import strings from '../../../Utils/LocalizedStrings';

interface NewCustomerViewProps {
  readonly info?: NewCustomerInfo;
  readonly errors: NewCustomerErrors;
  readonly onCustomerInfoChange: (info: NewCustomerInfo) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  gridItem: {
    display: "flex",
    flexGrow: 1,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  sectionTitle: {
    ...theme.typography.h6,
    display: "flex",
    flexGrow: 1,
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1)
  }
}));

const NewCustomerScreen = (props: NewCustomerViewProps) => {
  const { info, errors, onCustomerInfoChange } = props;
  const prevInfo: () => NewCustomerInfo = () => info ?? { name: "", surname: "", email: "" };

  // Function that accepts a callback that is invoked passing as argument the value that
  // has been obtained from the changed component and the current customer info value.
  // Returns a function that obtains such value and calls the callback with it and with
  // the customer info.
  const handleChange: (callback: (value: string, prevInfo: NewCustomerInfo) => NewCustomerInfo) => (e: React.ChangeEvent<HTMLInputElement>) => void =
    (callback) => e => {
      const value = e?.target?.value ?? ""
      onCustomerInfoChange(callback(value, prevInfo()));
    }

  const classes = useStyles();

  return (
    <Grid container>
      <Grid item xs={1} md={2} lg={3} />
      <Grid item xs={12} md={8} lg={6}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Box className={classes.fieldsContainer}>

            <span className={classes.sectionTitle}>
              {strings.admin.clients.creation.mainSection}
            </span>

            {/* Name */}
            <FormControl
              className={classes.gridItem}
              error={!(!errors.nameError)}>
              <TextField
                label={strings.admin.clients.detail.name}
                variant="outlined"
                value={info?.name ?? ""}
                onChange={handleChange((value, prevInfo) => ({ ...prevInfo, name: value }))}
                autoFocus />
              <FormHelperText>{errors.nameError ?? ''}</FormHelperText>
            </FormControl>

            {/* Surname */}
            <FormControl
              className={classes.gridItem}
              error={!(!errors.surnameError)}>
              <TextField
                label={strings.admin.clients.detail.surname}
                variant="outlined"
                value={info?.surname ?? ""}
                onChange={handleChange((value, prevInfo) => ({ ...prevInfo, surname: value }))} />
              <FormHelperText>{errors.surnameError ?? ''}</FormHelperText>
            </FormControl>

            {/* Email */}
            <FormControl
              className={classes.gridItem}
              error={!(!errors.emailError)}>
              <TextField
                label={strings.admin.clients.detail.email}
                placeholder="i.e.: user@example.com"
                variant="outlined"
                value={info?.email ?? ""}
                onChange={handleChange((value, prevInfo) => ({ ...prevInfo, email: value }))}
                type="email" />
              <FormHelperText>{errors.emailError ?? ''}</FormHelperText>
            </FormControl>

            {/* Phone */}
            <FormControl
              className={classes.gridItem}
              error={!(!errors.phoneError)}>
              <TextField
                label={`${strings.admin.clients.detail.phone} (${strings.commons.optional})`}
                placeholder="es. +39 333 1234567"
                variant="outlined"
                value={info?.phoneNumber ?? ""}
                onChange={handleChange((value, prevInfo) => ({ ...prevInfo, phoneNumber: value }))}
                type="tel" />
              <FormHelperText>{errors.phoneError ?? ''}</FormHelperText>
            </FormControl>

          </Box>
        </MuiPickersUtilsProvider>
      </Grid>
      <Grid item xs={1} md={2} lg={3} />
    </Grid>
  )
};

export default NewCustomerScreen;
