import { Observable, throwError } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import CustomersRepository from "../Customers/CustomersRepository";
import GetUserPackageOnDateUseCase from "../Packages/GetUserPackageOnDateUseCase";
import BookingsRepository from "./BookingsRepository";

/**
 * Cancels a booking reserved by a user.
 * 
 * Be aware that the given `bookingId` refers to a booking, not to an appointment.
 */
export default class CancelBookingUseCase {

  constructor(
    private readonly getUserPackageOnDate: GetUserPackageOnDateUseCase,
    private readonly bookingsRepository: BookingsRepository,
    private readonly customersRepository: CustomersRepository,
  ) { }

  execute = (bookingId: string): Observable<void> =>
    this.bookingsRepository
      .getBooking(bookingId)
      .pipe(
        switchMap((booking) =>
          this.getUserPackageOnDate
            .execute(booking.userId, booking.startDate)
        ),
        take(1),
        switchMap((pack) => {
          if (pack) {
            return this.bookingsRepository
              .deleteBooking(bookingId)
              .pipe(switchMap(customerId =>
                this.customersRepository
                  .updateTokenCount(customerId, pack.identifier, count => count + 1)
              ));
          } else {
            return throwError(`Booking ${bookingId} does not fit into an existing package`);
          }
        })
      );
}