import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import { Calendar as BigCalendar, Event, EventProps } from 'react-big-calendar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import CustomToolbar from '../../Commons/CustomToolbar';
import CalendarLocalizer from '../../Utils/CalendarLocalizer';
import "./../../Commons/calendar.css";
import UiSimpleEvent from './UiSimpleEvent';
import {
  calendarMaxTimeInColumnView,
  calendarMinTimeInColumnView,
  calendarSteps,
  calendarTimeSlots
} from "../../Commons/DragAndDropCalendar.helpers";

const useStyles = makeStyles((theme) => createStyles({
  calendar: {
    height: '85vh'
  },
  endSpacing: {
    marginRight: theme.spacing(1)
  },
}));

export type CalendarViewType = 'month' | 'week' | 'day';

interface CalendarProps {
  readonly events: UiSimpleEvent[];
  readonly startDate: Date;
  readonly defaultView: CalendarViewType;
  readonly onEventSelected: (start?: Date, end?: Date) => void;
  readonly onViewChanged: (view: CalendarViewType) => void;
  readonly onDateRangeChanged: (start: Date, end: Date) => void;
}

const CalendarScreen = (props: CalendarProps) => {

  const classes = useStyles();

  const events: Event[] = props.events.map(event => ({
    title: event.title,
    start: event.startDate,
    end: event.endDate,
    allDay: false
  }))

  return (
    <BigCalendar
      localizer={CalendarLocalizer}
      culture={navigator.language}
      defaultDate={props.startDate}
      defaultView={props.defaultView}
      onView={view => props.onViewChanged(view as CalendarViewType)}
      onRangeChange={dates => {
        // The given `dates` can be an array when the view is "month"
        // or it can be an object with the "week" and "day" views.
        if (dates instanceof Array) {
          props.onDateRangeChanged(dates[0], dates[dates.length - 1]);
        } else if (dates.start instanceof Date && dates.end instanceof Date) {
          props.onDateRangeChanged(dates.start, dates.end);
        }
      }}
      selectable
      toolbar
      step={calendarSteps}
      timeslots={calendarTimeSlots}
      min={calendarMinTimeInColumnView}
      max={calendarMaxTimeInColumnView}
      events={events}
      onSelectEvent={e => props.onEventSelected(e.start, e.end)}
      components={{
        toolbar: CustomToolbar,
        event: CustomEvent
      }}
      className={classes.calendar}>

    </BigCalendar>
  );
};

const CustomEvent = (props: EventProps<Event>) => {
  return (
    <Box display="flex" flexDirection="row">
      <span>{props.title}</span>
    </Box>
  );
}

export default CalendarScreen;