import firebase from 'firebase';
import 'firebase/firestore';
import { defer, Observable, of, throwError } from "rxjs";
import { catchError, map, switchMap } from 'rxjs/operators';
import { Role } from '../../Domain/Roles';
import User from "../../Domain/User";
import { asObservable } from '../../Utils/PromiseUtils';
import { observe } from '../FirestoreUtils';
import { RestError } from './RestErrors';
import UserApi from "./UserApi";


/**
 * The set of Firestore status codes. The codes are the same at the ones
 * exposed by gRPC here:
 * https://github.com/grpc/grpc/blob/master/doc/statuscodes.md
 *
 * Possible values:
 * - 'cancelled': The operation was cancelled (typically by the caller).
 * - 'unknown': Unknown error or an error from a different error domain.
 * - 'invalid-argument': Client specified an invalid argument. Note that this
 *   differs from 'failed-precondition'. 'invalid-argument' indicates
 *   arguments that are problematic regardless of the state of the system
 *   (e.g. an invalid field name).
 * - 'deadline-exceeded': Deadline expired before operation could complete.
 *   For operations that change the state of the system, this error may be
 *   returned even if the operation has completed successfully. For example,
 *   a successful response from a server could have been delayed long enough
 *   for the deadline to expire.
 * - 'not-found': Some requested document was not found.
 * - 'already-exists': Some document that we attempted to create already
 *   exists.
 * - 'permission-denied': The caller does not have permission to execute the
 *   specified operation.
 * - 'resource-exhausted': Some resource has been exhausted, perhaps a
 *   per-user quota, or perhaps the entire file system is out of space.
 * - 'failed-precondition': Operation was rejected because the system is not
 *   in a state required for the operation's execution.
 * - 'aborted': The operation was aborted, typically due to a concurrency
 *   issue like transaction aborts, etc.
 * - 'out-of-range': Operation was attempted past the valid range.
 * - 'unimplemented': Operation is not implemented or not supported/enabled.
 * - 'internal': Internal errors. Means some invariants expected by
 *   underlying system has been broken. If you see one of these errors,
 *   something is very broken.
 * - 'unavailable': The service is currently unavailable. This is most likely
 *   a transient condition and may be corrected by retrying with a backoff.
 * - 'data-loss': Unrecoverable data loss or corruption.
 * - 'unauthenticated': The request does not have valid authentication
 *   credentials for the operation.
 */
// export type FirestoreErrorCode =
//   | 'cancelled'
//   | 'unknown'
//   | 'invalid-argument'
//   | 'deadline-exceeded'
//   | 'not-found'
//   | 'already-exists'
//   | 'permission-denied'
//   | 'resource-exhausted'
//   | 'failed-precondition'
//   | 'aborted'
//   | 'out-of-range'
//   | 'unimplemented'
//   | 'internal'
//   | 'unavailable'
//   | 'data-loss'
//   | 'unauthenticated';

/** An error returned by a Firestore operation. */
// TODO(b/63008957): FirestoreError should extend firebase.FirebaseError
// export interface FirestoreError {
//   code: FirestoreErrorCode;
//   message: string;
//   name: string;
//   stack?: string;
// }




type UserDto = firebase.firestore.DocumentData & {
  readonly name: string,
  readonly surname: string,
  readonly email: string,
  readonly phone?: string,
  readonly role: string,
}

export default class FirebaseUserApi implements UserApi {

  constructor(
    private readonly auth: () => firebase.auth.Auth,
    private readonly firestore: () => firebase.firestore.Firestore,
    private readonly functions: () => firebase.functions.Functions,
  ) {
  }

  getUser = () => {
    const userDataObservable = defer(() => {
      const userId = this.auth().currentUser?.uid;
      if (userId) {
        const userRef = this.firestore().collection("users").doc(userId);
        return observe(userRef).pipe(
          switchMap(doc => {
            if (!doc.get("role")) {
              // when there is no connection, properties are undefined.
              // Force a network call in order to get the proper error in the observable stream.
              return asObservable(userRef.get());
            } else {
              return of(doc);
            }
          })
        );
      } else {
        return throwError(new Error("Unable to get the userId from logged user!"));
      }
    });
    return userDataObservable.pipe(
      map(snapshot => {
        const doc = snapshot.data() as UserDto;
        const user: User = {
          identifier: snapshot.id,
          name: doc.name,
          surname: doc.surname,
          email: doc.email,
          phone: doc.phone ?? null,
          role: doc.role as Role,
        };
        return user;
      }),
      catchError(error => {
        if (error.code === "unavailable") {
          return throwError(new RestError("network-unavailable"));
        } else {
          return throwError(error);
        }
      })
    )
  }

  updatePhoneNumber = (userId: string, phoneNumber: string): Observable<void> => defer(() =>
    asObservable(
      this.firestore()
        .collection("users")
        .doc(userId)
        .update({ phone: phoneNumber })
    )
  );

  updatePassword = (userId: string, newPassword: string): Observable<void> => defer(() =>
    asObservable(
      this.functions()
        .httpsCallable("updateUserPassword")({ userId: userId, newPassword: newPassword })
    ).pipe(map(() => { }))
  );

  resetPassword = (userId: string) => defer(() =>
    asObservable(
      this.functions().httpsCallable("resetUserPassword")({ userId: userId })
    ).pipe(map(() => { }))
  );

};