import { Observable } from "rxjs";
import SettingsRepository from "./SettingsRepository";

export default class DeleteBusinessDayScheduleUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
  ) {
  }

  execute = (scheduleId: string): Observable<void> =>
    this.settingsRepository.deleteBusinessDaySchedule(scheduleId);
}