import BusinessDaysSchedule, { BusinessHoursSlot } from "../../Domain/Settings/BusinessDay";
import { fromFloatingDateString, toFloatingDateString } from "../../Utils/DateUtils";
import { Constants } from "../../Utils/Constants";

export default interface BusinessDayScheduleDto extends firebase.firestore.DocumentData {
  readonly identifier: string;
  readonly description: string;
  readonly startDate: string;
  readonly endDate: string;
  readonly businessDays: BusinessDaysDto;
}

export interface BusinessDaysDto {
  readonly 1: BusinessSlotsDto[] | null,
  readonly 2: BusinessSlotsDto[] | null,
  readonly 3: BusinessSlotsDto[] | null,
  readonly 4: BusinessSlotsDto[] | null,
  readonly 5: BusinessSlotsDto[] | null,
  readonly 6: BusinessSlotsDto[] | null,
  readonly 7: BusinessSlotsDto[] | null,
}

export interface BusinessSlotsDto {
  readonly startHour: number;
  readonly startMinute: number;
  readonly endHour: number;
  readonly endMinute: number;
  readonly customersLimit: number | null;
}

/**
 * Maps a `BusinessDayScheduleDto` to a `BusinessDaysSchedule` domain model.
 * @param dto `BusinessDayScheduleDto` to map
 */
export const mapToBusinessDaysSchedule = (dto: BusinessDayScheduleDto): BusinessDaysSchedule => {
  return {
    identifier: dto.identifier,
    description: dto.description,
    startDate: fromFloatingDateString(dto.startDate),
    endDate: fromFloatingDateString(dto.endDate),
    businessDays: Object.keys(dto.businessDays)
      .map(day => parseInt(day))
      .filter(dayOfWeek => !isNaN(dayOfWeek))
      .sort((lhs, rhs) => lhs - rhs)
      .map(dayOfWeek => {
        const businessHourSlots = ((dto.businessDays as any)[dayOfWeek] as BusinessSlotsDto[]) ?? [];
        return {
          dayOfWeek: dayOfWeek,
          businessHourSlots: businessHourSlots.map(slotDto => ({
            start: {
              hour: slotDto.startHour,
              minute: slotDto.startMinute,
            },
            end: {
              hour: slotDto.endHour,
              minute: slotDto.endMinute,
            },
            customersLimit: slotDto.customersLimit ?? Constants.maxBookingsPerSlot
          }))
        }
      })
      .filter(slot => slot.businessHourSlots.length > 0)
  }
}

export const mapToBusinessDaysScheduleDto = (schedule: BusinessDaysSchedule) => ({
  description: schedule.description,
  startDate: toFloatingDateString(schedule.startDate),
  endDate: toFloatingDateString(schedule.endDate),
  businessDays: schedule.businessDays.reduce((acc, day): BusinessDaysDto => {
    return {
      ...acc,
      [day.dayOfWeek]: day.businessHourSlots.map(mapToBusinessSlotsDto),
    };
  }, { 1: null, 2: null, 3: null, 4: null, 5: null, 6: null, 7: null } as BusinessDaysDto),
});

export const mapToBusinessSlotsDto = (slot: BusinessHoursSlot): BusinessSlotsDto => {
  return {
    startHour: slot.start.hour,
    startMinute: slot.start.minute,
    endHour: slot.end.hour,
    endMinute: slot.end.minute,
    customersLimit : slot.customersLimit
  }
}