import { Box, Button, Card, CardActions, CardContent, createStyles, makeStyles } from '@material-ui/core';
import React, { ReactElement } from 'react';
import token from '../../Resources/token.svg';
import strings from '../Utils/LocalizedStrings';

interface PackageCardProps {
  /** The name of the package */
  readonly title: string;
  /** The start date from which the package begins, in a user-friendly representation */
  readonly displayableDateFrom: string;
  /** The end date from which the package ends, in a user-friendly representation */
  readonly displayableDateTo: string | null;
  /** The image that represents the package */
  readonly image: string;
  /** The total amount of tokens that are left */
  readonly tokenCount?: string;
  /** The weekly limit of tokens that the client can use */
  readonly weeklyTokenLimit?: number;
  /** The image used for displaying the status of the package */
  readonly statusImage?: ReactElement;
  /** The color of the status */
  readonly statusColor?: string;
  /** The text that describes the status */
  readonly statusText?: string;
  /** Whether the edit package button is visible or not. */
  readonly isEditPackageEnabled?: boolean;
  /** Called when the user pressed on the Edit button. */
  readonly onEditClicked?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  textContainer: {
    display: "flex",
    flexDirection: "column"
  },
  horizontalContainer: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  title: {
    ...theme.typography.h5
  },
  subtitle: {
    ...theme.typography.h6
  },
  tokenImage: {
    width: theme.spacing(4),
    height: theme.spacing(4)
  },
  packageImage: {
    marginLeft: theme.spacing(2),
    width: "104px",
    height: "104px",
    objectFit: "cover"
  }
}));

/**
 * A card used for display package info.
 * 
 * @param props the props that contain th data to display.
 */
const PackageCard = (props: PackageCardProps & { className?: string }) => {
  const classes = useStyles(props.image);
  return (
    <Card className={props.className}>
      <CardContent>
        <Box className={classes.horizontalContainer}>
          <Box className={classes.textContainer}>
            <span className={classes.title}>{props.title}</span>
            <Box className={classes.horizontalContainer}>
              <span>{strings.commons.from}&nbsp;</span>
              <b>{props.displayableDateFrom}</b>
              {
                props.displayableDateTo
                  ? <span>&nbsp;{strings.commons.to}&nbsp;<b>{props.displayableDateTo}</b></span>
                  : <span />
              }
            </Box>
            {
              props.weeklyTokenLimit
                ? <Box className={classes.horizontalContainer}>
                  <b>{props.weeklyTokenLimit}</b>
                  <span>&nbsp;{strings.admin.clients.detail.tokensPerWeek}</span>
                </Box>
                : null
            }
            {
              props.tokenCount
                ? <Box className={classes.horizontalContainer}>
                  <img className={classes.tokenImage} alt={strings.alt.token} src={token} />
                  <b>{props.tokenCount}</b>
                </Box>
                : null
            }
            {
              props.statusText
                ? <Box className={classes.horizontalContainer}>
                  {props.statusImage ?? null}
                  <span>&nbsp;</span>
                  <span style={{ color: props.statusColor ?? "#000" }}>{props.statusText}</span>
                </Box>
                : null
            }
          </Box>
          <img src={props.image} alt={strings.alt.package} className={classes.packageImage} />
        </Box>
      </CardContent>
      <CardActions>
        {
          props.isEditPackageEnabled
            ? <Button
              size="small"
              onClick={() => props.onEditClicked?.()}>{strings.commons.edit}</Button>
            : null
        }
      </CardActions>
    </Card>
  )
}

export default PackageCard;