import { Observable } from "rxjs";
import Appointment from "./Appointment";
import AppointmentsRepository from "./AppointmentsRepository";

export default class GetAppointmentUseCase {

  private readonly appointmentsRepository: AppointmentsRepository;

  constructor(
    appointmentsRepository: AppointmentsRepository
  ) {
    this.appointmentsRepository = appointmentsRepository;
  }

  execute = (appointmentId: string): Observable<Appointment> =>
    this.appointmentsRepository.getAppointment(appointmentId);
}