import { empty, Observable, Subscription } from "rxjs";
import { shareReplay } from "rxjs/operators";
import Appointment from "../../../../Domain/Appointments/Appointment";
import GetAppointmentUseCase from "../../../../Domain/Appointments/GetAppointmentUseCase";
import Logger from "../../../../Logger/Logger";
import { format } from "../../../../Utils/DateUtils";
import { UiAppointment } from "../UiAppointment";
import EventDetailView from "./EventDetailView";

export default class EventDetailPresenter {
  private view?: EventDetailView;
  private appointmentId: string;
  private getAppointmentUseCase: GetAppointmentUseCase;
  private subscriptions = new Subscription();

  private appointment: Observable<Appointment> = empty();

  constructor(
    appointmentId: string,
    getAppointmentUseCase: GetAppointmentUseCase,
  ) {
    this.appointmentId = appointmentId;
    this.getAppointmentUseCase = getAppointmentUseCase;
  }

  attachView = (v: EventDetailView) => {
    this.view = v;
    this.setupObservables();

    this.subscriptions.add(
      this.appointment
        .subscribe(
          appointment => this.showAppointmentDetail(appointment),
          error => this.handleError(error)
        )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscriptions.unsubscribe();
  }

  // Private methods

  private setupObservables = () => {
    this.appointment = this.getAppointmentUseCase
      .execute(this.appointmentId)
      .pipe(shareReplay(1));
  }

  private showAppointmentDetail = (appointment: Appointment) => {
    const view = this.view;
    if (!view) return;

    const event: UiAppointment = {
      identifier: appointment.identifier,
      displayableDate: format(appointment.startDate, "EEEE, dd MMMM yyyy"),
      displayableTime: format(appointment.startDate, "HH:mm") + " - " + format(appointment.endDate, "HH:mm"),
      participants: appointment.bookings.map(b => ({
        userId: b.userInfo.identifier,
        displayName: b.userInfo.name + " " + b.userInfo.surname
      }))
    }
    view.showEventDetail(event);
  }

  private handleError = (error: Error) => {
    Logger.e(error);
  }
}