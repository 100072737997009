import { addDays, format } from "date-fns";
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject, Subject, Subscription } from "rxjs";
import { catchError, map, shareReplay, startWith, switchMap, take } from "rxjs/operators";
import GetCustomerUseCase from "../../../../Domain/Customers/GetCustomerUseCase";
import Customer from "../../../../Domain/Entities/Customer";
import Package from "../../../../Domain/Entities/Package";
import { PackageType } from "../../../../Domain/Entities/PackageTypes";
import AssignPackageUseCase from "../../../../Domain/Packages/AssignPackageUseCase";
import EditPackageUseCase from "../../../../Domain/Packages/EditPackageUseCase";
import EstimatePackageEndDateUseCase from "../../../../Domain/Packages/EstimatePackageEndDateUseCase";
import GetAvailablePackagesUseCase from "../../../../Domain/Packages/GetAvailablePackagesUseCase";
import Logger from "../../../../Logger/Logger";
import { Constants } from "../../../../Utils/Constants";
import { checkExists } from "../../../../Utils/Preconditions";
import { Failure, Loading, Success } from "../../../../Utils/Result";
import strings from "../../../Utils/LocalizedStrings";
import ViewModel from "../../../Utils/ViewModel";
import { getPackageName } from "../../Packages/PackagesMappers";
import PackageAssignmentErrors from "./PackageAssignmentErrors";
import PackageAssignmentInfo from "./PackageAssignmentInfo";

export type ShowConfirmationDialog = { values: [string, string][], type: "show" };
export type HideConfirmationDialog = { type: "hide" };
export type ConfirmationDialogVisibility = ShowConfirmationDialog | HideConfirmationDialog;

interface PackageAssignmentViewModel extends ViewModel {
  readonly displayName: Observable<string>;
  readonly packagesWithName: Observable<[PackageType, string][]>;
  readonly packageAssignmentInfo: Observable<PackageAssignmentInfo>;
  readonly isLoading: Observable<boolean>;
  readonly errors: Observable<PackageAssignmentErrors>;
  readonly confirmationDialogVisibility: Observable<ConfirmationDialogVisibility>;

  assignmentInfoChanged: (info: PackageAssignmentInfo) => void;
  packageTypeChanged: (packageType: PackageType | null) => void;
  buyDateChanged: (buyDate: Date | null) => void;
  startDateChanged: (startDate: Date | null) => void;
  endDateChanged: (endDate: Date | null) => void;
  assignPackage: () => void;
  tokenLimitChanged: (tokenCount: any | null) => void;
  leftTokenCountChanged: (tokenCount: any | null) => void;
  confirmPackageAssignment: () => void;
  cancelPackageAssignment: () => void;
}

export class PackageAssignmentViewModelImpl implements PackageAssignmentViewModel {

  private readonly subscription = new Subscription();
  private readonly assignmentInfoChanges = new ReplaySubject<PackageAssignmentInfo>(1);
  private readonly customer: Observable<Customer>;
  private readonly packages: Observable<PackageType[]>;

  readonly displayName: Observable<string>;
  readonly packageAssignmentInfo: Observable<PackageAssignmentInfo>;
  readonly isEndDateMandatory: Observable<boolean>;
  readonly packagesWithName: Observable<[PackageType, string][]>;
  readonly isLoading = new Subject<boolean>();
  readonly errors = new BehaviorSubject<PackageAssignmentErrors>({});
  readonly confirmationDialogVisibility = new Subject<ConfirmationDialogVisibility>()

  constructor(
    customerId: string,
    packageId: string | null,
    getCustomerUseCase: GetCustomerUseCase,
    getAvailablePackagesUseCase: GetAvailablePackagesUseCase,
    private readonly estimatePackageEndDate: EstimatePackageEndDateUseCase,
    private readonly assignPackageUseCaseProvider: () => AssignPackageUseCase,
    private readonly editPackageUseCaseProvider: () => EditPackageUseCase,
    private readonly onPackageAssigned: (customerId: string) => void,
  ) {
    this.customer = getCustomerUseCase.execute(customerId).pipe(shareReplay(1));
    this.packages = getAvailablePackagesUseCase.execute().pipe(shareReplay(1));

    this.subscription.add(
      this.customer
        .pipe(
          take(1),
          map((customer): PackageAssignmentInfo => {
            const pack = customer.packages.find(pack => pack.identifier === packageId);
            if (pack) {
              // We need to edit the package.
              return this.mapToPackageAssignmentInfo(pack, customer.packages);
            } else {
              // We need to create a new package.
              return emptyPackageAssignmentInfo(customer.packages);
            }
          })
        )
        .subscribe(info => this.assignmentInfoChanges.next(info), Logger.e)
    );

    this.displayName = this.customer
      .pipe(map(customer => `${customer.name} ${customer.surname}`));

    this.packageAssignmentInfo = this.assignmentInfoChanges;

    this.isEndDateMandatory = this.assignmentInfoChanges
      .pipe(map(info => !(!info.packageEndDate)), shareReplay(1));

    this.packagesWithName = this.packages
      .pipe(map(types => types.map<[PackageType, string]>(type => [type, getPackageName(type)])))
  }

  onCleared = () => {
    this.subscription.unsubscribe();
  }

  assignmentInfoChanged = (info: PackageAssignmentInfo) => {
    this.assignmentInfoChanges.next(info);
  }

  updateAssignmentInfoChanges = (updateBlock: (info: PackageAssignmentInfo) => void) => {
    this.subscription.add(
      this.assignmentInfoChanges
        .pipe(take(1))
        .subscribe(updateBlock)
    );
  }

  packageTypeChanged = (packageType: PackageType | null) => {
    this.updateAssignmentInfoChanges((info) => {
      if (info.packageType === packageType) return;

      this.assignmentInfoChanges.next({ ...info, packageType: packageType });

      // The package type changed, so we need to recalculate the end date.
      this.updateEndDate();
    });
  }

  buyDateChanged = (buyDate: Date | null) => {
    this.updateAssignmentInfoChanges((info) => {
      if (info.packageBuyDate === buyDate) return;

      this.assignmentInfoChanges.next({ ...info, packageBuyDate: buyDate });
    });
  }

  startDateChanged = (startDate: Date | null) => {
    this.updateAssignmentInfoChanges((info) => {
      if (info.packageStartDate.current === startDate) return;

      this.assignmentInfoChanges.next({
        ...info,
        packageStartDate: {
          ...info.packageStartDate,
          current: startDate
        }
      });

      // The start date of the package changed, so we need to recalculate the end date.
      this.updateEndDate();
    });
  }

  endDateChanged = (endDate: Date | null) => {
    this.updateAssignmentInfoChanges((info) => {
      if (info.packageEndDate.current === endDate) return;

      this.assignmentInfoChanges.next({
        ...info,
        packageEndDate: {
          ...info.packageEndDate,
          current: endDate
        }
      });
    });
  }

  tokenLimitChanged = (tokenLimit: any | null) => {
    this.updateAssignmentInfoChanges((info) => {
      if (info.weeklyTokensLimit === tokenLimit) return;

      const isValid = this.isTokenCountNumberOrEmpty(tokenLimit);
      if (isValid) {
        const newLimit = tokenLimit === "" ? 0 : Number.parseInt(tokenLimit);
        this.assignmentInfoChanges.next({ ...info, weeklyTokensLimit: newLimit });
      }
    });
  }

  leftTokenCountChanged = (tokenCount: any | null) => {
    this.updateAssignmentInfoChanges((info) => {
      if (info.leftTokenCount === tokenCount) return;

      const isValid = this.isTokenCountNumberOrEmpty(tokenCount);
      if (isValid) {
        const newCount = tokenCount === "" ? 0 : Number.parseInt(tokenCount);
        this.assignmentInfoChanges.next({ ...info, leftTokenCount: newCount });
      }
    });
  }

  private isTokenCountNumberOrEmpty = (tokenCount: any | null) =>
    tokenCount === "" || (tokenCount && !isNaN(Number.parseInt(tokenCount)));

  assignPackage = () => {
    this.subscription.add(
      combineLatest([this.assignmentInfoChanges, this.isEndDateMandatory])
        .pipe(take(1))
        .subscribe(
          ([info, isMandatory]) => this.onAssignPackageRequested(info, isMandatory)
        )
    );
  }

  confirmPackageAssignment = () => {
    this.confirmationDialogVisibility.next({ type: "hide" });
    this.subscription.add(
      combineLatest([this.customer.pipe(take(1)), this.assignmentInfoChanges.pipe(take(1))])
        .pipe(
          switchMap(([customer, info]) => {
            if (info.packageId) {
              return this.doEditPackage(info.packageId, customer, info);
            } else {
              return this.doAssignPackage(customer, info);
            }
          }),
          switchMap(() => this.customer),
          map(customer => Success(customer)),
          catchError(error => of(Failure<Customer>(error))),
          startWith(Loading<Customer>()),
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.isLoading.next(true);
            } else {
              this.isLoading.next(false);
              result.fold(
                customer => this.onPackageAssigned(customer.identifier),
                Logger.e,
              );
            }
          },
          Logger.e
        )
    );
  }

  private doEditPackage = (packageId: string, customer: Customer, info: PackageAssignmentInfo) =>
    this.editPackageUseCaseProvider().execute(
      customer.identifier,
      packageId,
      checkExists(info.packageType, () => "Package type is absent"),
      checkExists(info.packageBuyDate, () => "Buy date is absent"),
      checkExists(info.packageStartDate.current, () => "Start date is absent"),
      info.packageEndDate.current ?? null,
      checkExists(info.leftTokenCount, () => "Left token count are absent"),
      info.weeklyTokensLimit
    )

  private doAssignPackage = (customer: Customer, info: PackageAssignmentInfo) =>
    this.assignPackageUseCaseProvider().execute(
      customer.identifier,
      checkExists(info.packageType, () => "Package type is absent"),
      checkExists(info.packageBuyDate, () => "Buy date is absent"),
      checkExists(info.packageStartDate.current, () => "Start date is absent"),
      info.packageEndDate.current ?? null,
      info.weeklyTokensLimit
    );

  cancelPackageAssignment = () => this.confirmationDialogVisibility.next({ type: "hide" });

  // Private methods

  private updateEndDate = () => {
    this.updateAssignmentInfoChanges((info) => {
      const startDate = info.packageStartDate;
      const packageType = info.packageType;
      if (packageType && startDate.current) {
        this.subscription.add(
          combineLatest([
            this.assignmentInfoChanges,
            this.estimatePackageEndDate.execute(packageType, startDate.current)]
          ).pipe(
            take(1),
            map(([info, endDate]): PackageAssignmentInfo => ({
              ...info,
              packageEndDate: {
                ...info.packageEndDate,
                current: endDate
              }
            }))
          ).subscribe(
            updatedInfo => this.assignmentInfoChanges.next(updatedInfo),
            Logger.e
          )
        );
      }
    });
  }

  private onAssignPackageRequested = (info: PackageAssignmentInfo, isEndDateMandatory: boolean) => {
    let errors: PackageAssignmentErrors = {};
    let hasErrors = false;

    if (!info.packageType || info.packageType.length === 0) {
      errors = { ...errors, packageTypeError: strings.admin.clients.packageAssignment.errorMissingType };
      hasErrors = true;
    }

    if (!info.packageBuyDate) {
      errors = { ...errors, packageBuyDateError: strings.admin.clients.packageAssignment.errorMissingBuyDate };
      hasErrors = true;
    }

    if (!info.packageStartDate) {
      errors = { ...errors, packageStartDateError: strings.admin.clients.packageAssignment.errorMissingStartDate };
      hasErrors = true;
    }

    if (isEndDateMandatory) {
      if (!info.packageEndDate.current) {
        errors = { ...errors, packageEndDateError: strings.admin.clients.packageAssignment.errorMissingEndDate };
        hasErrors = true;
      } else if (info.packageEndDate.current.getTime() <= (info.packageStartDate.current?.getTime() ?? 0)) {
        errors = { ...errors, packageEndDateError: strings.admin.clients.packageAssignment.errorFutureEndDate };
        hasErrors = true;
      }
    }

    if (hasErrors) {
      this.errors.next(errors);
    } else {
      this.errors.next({});
      this.performPackageAssignment(info);
    }
  }

  private performPackageAssignment = (info: PackageAssignmentInfo) => {
    this.subscription.add(
      this.customer
        .pipe(take(1))
        .subscribe(
          customer => {
            this.confirmationDialogVisibility.next({
              type: "show",
              values: createConfirmationDialogValues(customer, info)
            });
          }
        )
    );
  }

  private mapToPackageAssignmentInfo = (packageToUpdate: Package, customerPackages: Package[]): PackageAssignmentInfo => {
    if (customerPackages.length === 0) {
      throw new Error("Customer packages are empty!");
    }

    const { identifier, type } = packageToUpdate;
    const packageType = type as PackageType;

    // Sort package by start date.
    const sortedPackages = [...customerPackages].sort((lhs, rhs) => lhs.startDate.getTime() - rhs.startDate.getTime());
    const indexOfPackageToUpdate = sortedPackages.findIndex((customerPackage) => customerPackage === packageToUpdate);
    if (indexOfPackageToUpdate === -1) {
      throw new Error("Package to update is not present in customer's packages list");
    }
    const nextPackageIndex = indexOfPackageToUpdate + 1 < sortedPackages.length
      ? indexOfPackageToUpdate + 1
      : null;
    const prevPackageIndex = indexOfPackageToUpdate - 1 >= 0
      ? indexOfPackageToUpdate - 1
      : null;
    const previousPackage = prevPackageIndex ? customerPackages[prevPackageIndex] : null;
    const nextPackage = nextPackageIndex ? customerPackages[nextPackageIndex] : null;

    const startMinDate = (previousPackage?.expirationDate && addDays(previousPackage.expirationDate, 1)) || null;
    const endMaxDate = (nextPackage?.startDate && addDays(nextPackage.startDate, -1)) || null;
    return {
      packageId: identifier,
      packageType: packageType,
      packageBuyDate: packageToUpdate.buyDate,
      packageStartDate: {
        min: startMinDate,
        max: packageToUpdate.expirationDate,
        current: packageToUpdate.startDate
      },
      packageEndDate: {
        min: packageToUpdate.startDate,
        max: endMaxDate,
        current: packageToUpdate.expirationDate
      },
      leftTokenCount: packageToUpdate.leftTokenCount,
      weeklyTokensLimit: packageToUpdate.maxWeeklyBookingsCount
    };
  }
}

const emptyPackageAssignmentInfo = (customerPackages: Package[]): PackageAssignmentInfo => {
  const sortedPackages = [...customerPackages].sort((lhs, rhs) => lhs.startDate.getTime() - rhs.startDate.getTime());
  const lastPackage = sortedPackages.length > 0 ? sortedPackages[sortedPackages.length - 1] : null;
  const minDate = lastPackage?.expirationDate ? addDays(lastPackage.expirationDate, 1) : null;
  return {
    packageId: null,
    packageType: null,
    packageBuyDate: null,
    packageStartDate: { min: minDate, max: null, current: null },
    packageEndDate: { min: minDate, max: null, current: null },
    leftTokenCount: null,
    weeklyTokensLimit: Constants.defaultWeeklyBookingsLimit
  };
}

const createConfirmationDialogValues = (
  customer: Customer,
  info: PackageAssignmentInfo
): [string, string][] => {
  const str = strings.admin.clients.packageAssignment;
  const formatDate = (date: Date) => format(date, "dd / MM / yyyy");
  const nd = "–";
  const { packageType, packageBuyDate, packageStartDate, packageEndDate, weeklyTokensLimit } = info;
  return [
    [str.client, `${customer.name} ${customer.surname}`],
    [str.package, (packageType && getPackageName(packageType)) || nd],
    [str.buyDateShort, (packageBuyDate && formatDate(packageBuyDate)) || nd],
    [str.startDateShort, (packageStartDate.current && formatDate(packageStartDate.current)) || nd],
    [str.endDateShort, (packageEndDate.current && formatDate(packageEndDate.current)) || nd],
    [str.tokensLimitShort, weeklyTokensLimit.toString()],
  ];
}

export default PackageAssignmentViewModel;