
import { Subscription } from 'rxjs';
import { mapTo, startWith, take } from 'rxjs/operators';
import LogoutUseCase from '../../Domain/LogoutUseCase';
import LogoutView from './LogoutView';
import Logger from '../../Logger/Logger';

export default class LogoutPresenter {

  private readonly subscription = new Subscription();
  private readonly logoutUseCase: LogoutUseCase;
  private view?: LogoutView;

  constructor(logoutUseCase: LogoutUseCase) {
    this.logoutUseCase = logoutUseCase;
  }

  attachView = (v: LogoutView) => {
    this.view = v;
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  logout = () => {
    this.subscription.add(
      this.logoutUseCase
        .execute()
        .pipe(take(1), mapTo(false), startWith(true))
        .subscribe(
          isLoading => {
            if (isLoading) {
              this.view?.showLogoutProgress()
            } else {
              this.view?.hideLogoutProgress();
              this.view?.navigateToLogin();
            }
          },
          error => {
            Logger.e(error);
            this.view?.hideLogoutProgress();
          }
        )
    )
  }

  // Private methods

};

