import { format, getDay, parse, startOfWeek } from 'date-fns';
import { it } from 'date-fns/locale';
import { dateFnsLocalizer } from 'react-big-calendar';

const locales = {
  'it-IT': it
};

const CalendarLocalizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export default CalendarLocalizer;
