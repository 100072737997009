import { Observable } from "rxjs";
import CustomersRepository from "./CustomersRepository";

/**
 * Saves the given customer ID for populating the list of searched customers
 * returned by `GetRecentSearchedCustomerUseCase`.
 */
export default class SaveRecentSearchedCustomerUseCase {

  private readonly customersRepository: CustomersRepository;

  constructor(
    customersRepository: CustomersRepository
  ) {
    this.customersRepository = customersRepository;
  }

  execute: (customerId: string) => Observable<void> = (customerId) =>
    this.customersRepository.saveRecentSearchedCustomer(customerId, 5);
}