
import { Subscription } from 'rxjs';
import AdminHomeView from './AdminHomeView';

export default class AdminHomePresenter {

  private readonly subscription = new Subscription();
  private view?: AdminHomeView;

  attachView = (v: AdminHomeView) => {
    this.view = v;
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  // Private methods

};

