import React, { useCallback, useEffect, useState } from 'react';
import strings from '../Utils/LocalizedStrings';
import AdminLoginPresenter from './AdminLoginPresenter';
import AdminLoginScreen from './AdminLoginScreen';
import AdminLoginView from './AdminLoginView';

interface AdminLoginViewControllerProps {
  readonly presenter: AdminLoginPresenter;
  readonly onUserLoggedIn: () => void;
}

const AdminLoginViewController = (props: AdminLoginViewControllerProps) => {
  const { presenter, onUserLoggedIn } = props;
  const [state, setState] = useState({
    emailErrorMessage: "",
    passwordErrorMessage: "",
    isLoading: false
  });

  const view: () => AdminLoginView = useCallback(() => ({
    hideEmailError: () => setState(state => ({ ...state, emailErrorMessage: "" })),
    hidePasswordError: () => setState(state => ({ ...state, passwordErrorMessage: "" })),
    showEmailError: () => setState(state => ({ ...state, emailErrorMessage: strings.login.invalidEmail })),
    showPasswordError: () => setState(state => ({ ...state, passwordErrorMessage: strings.login.invalidPassword })),
    navigateToAdminHome: () => onUserLoggedIn(),
    showLoading: () => setState(state => ({ ...state, isLoading: true })),
    hideLoading: () => setState(state => ({ ...state, isLoading: false })),
  }), [onUserLoggedIn]);

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter, view]);

  return (
    <AdminLoginScreen
      onEmailChange={presenter.setEmail}
      onPasswordChange={presenter.setPassword}
      onLoginClicked={presenter.login}
      emailErrorMessage={state.emailErrorMessage}
      passwordErrorMessage={state.passwordErrorMessage}
      isLoading={state.isLoading}
    />
  );
}

export default AdminLoginViewController;