import { ILogger } from "./Logger";
import { Bugfender } from "@bugfender/sdk";
import Version from "../version";

class BugfenderLogger implements ILogger {

  constructor() {
    Bugfender.init({
      appKey: "KiseXCine66FANikMoa4s4fGhKX2hSSu",
      version: Version.name,
      overrideConsoleMethods: false,
      printToConsole: false,
      logUIEvents: true
    });
  }

  d = (message: string) => Bugfender.trace(message);
  i = (message: string) => Bugfender.info(message);
  w = (message: string) => Bugfender.warn(message);
  e = (message: string) => Bugfender.error(message);
};

export default BugfenderLogger;