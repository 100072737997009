import { defer, Observable } from "rxjs";
import { countWeeks, dateTruncatingTime } from "../../Utils/DateUtils";
import { PackageType, perUsagePackage, sixMonthsPackage, threeMonthsPackage, twelveMonthsPackage } from "../Entities/PackageTypes";
import PackagesRepository from "./PackagesRepository";

export default class AssignPackageUseCase {

  private readonly packagesRepository: PackagesRepository;

  constructor(
    packagesRepository: PackagesRepository
  ) {
    this.packagesRepository = packagesRepository;
  }

  execute = (
    customerId: string,
    packageType: PackageType,
    buyDate: Date,
    startDate: Date,
    endDate: Date | null,
    maxWeeklyBookingsCount: number
  ): Observable<string> => {
    return defer(() => {
      const tokenCount = calculateTokenCount(packageType, maxWeeklyBookingsCount, startDate, endDate);
      return this.packagesRepository.assignPackageToCustomer(
        customerId, packageType,
        dateTruncatingTime(buyDate),
        dateTruncatingTime(startDate),
        endDate || null,
        tokenCount,
        maxWeeklyBookingsCount
      );
    })
  }
}

const calculateTokenCount = (
  packageType: string,
  maxWeeklyBookings: number,
  start: Date,
  end: Date | null
) => {
  switch (packageType) {
    case threeMonthsPackage:
    case sixMonthsPackage:
    case twelveMonthsPackage:
      const numberOfWeeks = countWeeks(start, end!);
      return maxWeeklyBookings * numberOfWeeks;
    case perUsagePackage:
      return 10;
    default:
      throw new Error(`Invalid package type: ${packageType}`);
  }
}