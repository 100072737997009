import { differenceInDays } from "date-fns";
import { Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, mapTo, scan, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import CompanyClosure, { isCompanyClosureEquals } from '../../../Domain/CompanyClosures/CompanyClosure';
import { CompanyClosureFactory } from '../../../Domain/CompanyClosures/CompanyClosureFactory';
import GetCompanyClosurePeriodsUseCase from '../../../Domain/CompanyClosures/GetCompanyClosurePeriodsUseCase';
import { isAllDayLong } from '../../../Domain/CompanyClosures/IsAllDayLong';
import SaveCompanyClosurePeriodsUseCase from '../../../Domain/CompanyClosures/SaveCompanyClosurePeriodsUseCase';
import Logger from '../../../Logger/Logger';
import { format } from '../../../Utils/DateUtils';
import { Failure, Success } from '../../../Utils/Result';
import strings from '../../Utils/LocalizedStrings';
import CompanyClosuresView from './CompanyClosuresView';

interface ClosurePeriodsEditingState {
  readonly closurePeriods: CompanyClosure[];
  readonly isEdited: boolean;
}

const isStateEqual = (lhs: ClosurePeriodsEditingState, rhs: ClosurePeriodsEditingState): boolean => {
  return lhs.closurePeriods.length === rhs.closurePeriods.length
    && lhs.closurePeriods.every((period, index) => isCompanyClosureEquals(period, rhs.closurePeriods[index]));
}

// Operations on calendar events.

interface ClosurePeriodOperation {
  readonly type: "add" | "edit" | "delete";
}

interface ClosurePeriodAdd extends ClosurePeriodOperation {
  readonly from: Date;
  readonly to: Date;
}

const createClosurePeriodAdd = (from: Date, to: Date): ClosurePeriodAdd => ({
  type: "add",
  from: from,
  to: to,
})

interface ClosurePeriodEdit extends ClosurePeriodAdd {
  readonly identifier: string;
}

const createClosurePeriodEdit = (identifier: string, from: Date, to: Date): ClosurePeriodEdit => ({
  type: "edit",
  identifier: identifier,
  from: from,
  to: to,
});

interface ClosurePeriodDelete extends ClosurePeriodOperation {
  readonly identifier: string;
}

const createClosurePeriodDelete = (identifier: string): ClosurePeriodDelete => ({
  type: "delete",
  identifier: identifier,
});

// Presenter

export default class CompanyClosuresPresenter {

  private readonly subscription = new Subscription();
  private readonly closurePeriods: Observable<CompanyClosure[]>;
  private readonly periodsChanges = new Subject<ClosurePeriodOperation>()
  private readonly periodsEditingState: Observable<ClosurePeriodsEditingState>;

  private readonly saveCompanyClosurePeriodsUseCase: SaveCompanyClosurePeriodsUseCase;
  private readonly companyClosureFactory: CompanyClosureFactory;

  private view?: CompanyClosuresView;

  constructor(
    getCompanyClosurePeriodsUseCase: GetCompanyClosurePeriodsUseCase,
    saveCompanyClosurePeriodsUseCase: SaveCompanyClosurePeriodsUseCase,
    companyClosureFactory: CompanyClosureFactory,
  ) {
    this.saveCompanyClosurePeriodsUseCase = saveCompanyClosurePeriodsUseCase;
    this.companyClosureFactory = companyClosureFactory;

    this.closurePeriods = getCompanyClosurePeriodsUseCase.execute().pipe(shareReplay(1));
    this.periodsEditingState = this.closurePeriods
      .pipe(
        switchMap(periods =>
          this.periodsChanges
            .pipe(
              scan((state: ClosurePeriodsEditingState, operation: ClosurePeriodOperation) => {
                const { closurePeriods } = state;
                switch (operation.type) {
                  case "add":
                    const add = operation as ClosurePeriodAdd;
                    return {
                      ...state,
                      closurePeriods: closurePeriods.concat(
                        this.createNewClosurePeriod(add.from, add.to)
                      )
                    };
                  case "edit":
                    const edit = operation as ClosurePeriodEdit;
                    return {
                      ...state,
                      closurePeriods: closurePeriods.map(closurePeriod =>
                        closurePeriod.identifier === edit.identifier
                          ? {
                            ...closurePeriod,
                            from: edit.from,
                            to: edit.to,
                            isAllDayLong: isAllDayLong(edit.from, edit.to)
                          }
                          : closurePeriod
                      )
                    };
                  case "delete":
                    const del = operation as ClosurePeriodDelete;
                    return {
                      ...state,
                      closurePeriods: closurePeriods
                        .filter(closurePeriod => closurePeriod.identifier !== del.identifier),
                    };
                  default: return state;
                }
              }, { closurePeriods: periods, isEdited: false }),
              startWith({ closurePeriods: periods, isEdited: false }),
            )
        ),
        shareReplay(1)
      );
  }

  attachView = (v: CompanyClosuresView) => {
    this.view = v;

    this.subscription.add(
      this.periodsEditingState
        .subscribe(
          state => this.onClosurePeriodsStateChange(state),
          error => Logger.e(error)
        )
    );

    this.subscription.add(
      this.periodsEditingState
        .pipe(
          distinctUntilChanged(isStateEqual),
          switchMap(({ closurePeriods }) =>
            this.saveCompanyClosurePeriodsUseCase.execute(closurePeriods)
              .pipe(mapTo(Success({})), catchError(error => of(Failure(error))))
          )
        )
        .subscribe(
          result => result.fold(
            success => Logger.d("saved"),
            error => Logger.e(error)
          ),
          error => Logger.e(error)
        )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  eventRangeCreated = (from: Date, to: Date) => {
    // When a new event is created, check that another one does not exits in the same
    // time frame, a smaller time frame or a bigger one.
    this.subscription.add(
      this.periodsEditingState
        .pipe(take(1), map(({ closurePeriods }) =>
          closurePeriods.find(period =>
            this.periodCollidesWithTimeFrame(period, from, to)
          ) === undefined
        ))
        .subscribe(
          isTimeFrameFree => {
            if (isTimeFrameFree) {
              this.periodsChanges.next(createClosurePeriodAdd(from, to));
            }
          },
          error => Logger.e(error)
        )
    );
  }

  eventRangeUpdated = (identifier: string, from: Date, to: Date) => {
    // When an event is updated, check that another one does not exits in the same
    // time frame, a smaller time frame or a bigger one.
    this.subscription.add(
      this.periodsEditingState
        .pipe(take(1), map(({ closurePeriods }) =>
          closurePeriods.find(period =>
            period.identifier !== identifier && this.periodCollidesWithTimeFrame(period, from, to)
          ) === undefined
        ))
        .subscribe(
          isTimeFrameFree => {
            if (isTimeFrameFree) {
              this.periodsChanges.next(createClosurePeriodEdit(identifier, from, to));
            }
          },
          error => Logger.e(error)
        )
    );
  }

  eventSelected = (id: string) => {
    this.subscription.add(
      this.periodsEditingState
        .pipe(
          take(1),
          map(({ closurePeriods }) => closurePeriods.find(p => p.identifier === id)),
          map((period): [string, string] | null => {
            if (!period) return null;

            let text = "";
            const dateFormat = "EEEE dd MMMM yyyy";
            const daysDiff = differenceInDays(period.to, period.from);
            const str = strings.admin.companyClosures;

            if (period.isAllDayLong && daysDiff === 0) {
              const date = format(period.from, dateFormat);
              text = strings.formatString(str.formatAllDay, date).toString();
            } else if (period.isAllDayLong) {
              const dateFrom = format(period.from, dateFormat);
              const dateTo = format(period.to, dateFormat);
              text = strings.formatString(str.formatFromTo, dateFrom, dateTo).toString();
            } else {
              const start = format(period.from, "HH:mm");
              const end = format(period.to, "HH:mm");
              const date = format(period.from, dateFormat);
              text = strings.formatString(str.formatHoursFromTo, date, start, end).toString();
            }
            text = `${text[0].toUpperCase()}${text.slice(1)}`;
            return [period.identifier, text];
          })
        )
        .subscribe(idAndText => {
          if (idAndText) {
            const [identifier, text] = idAndText;
            this.view?.showDetailDialog(identifier, text);
          }
        }, error => Logger.e(error))
    );
  }

  deleteEvent = (eventId: string | null) => {
    if (!eventId) return;
    this.periodsChanges.next(createClosurePeriodDelete(eventId));
  }

  // Private methods

  private onClosurePeriodsStateChange = (state: ClosurePeriodsEditingState) => {
    if (state.isEdited) {
      this.view?.enableSaveButton();
    } else {
      this.view?.disableSaveButton();
    }

    this.view?.showCompanyClosurePeriods(
      state.closurePeriods.map(period => ({
        id: period.identifier,
        title: period.description ?? strings.admin.companyClosures.closed,
        start: period.from,
        end: period.to,
        isAllDay: period.isAllDayLong
      }))
    );
  }

  private createNewClosurePeriod = (from: Date, to: Date): CompanyClosure =>
    this.companyClosureFactory(null, from, to);

  private periodCollidesWithTimeFrame = (period: CompanyClosure, from: Date, to: Date): boolean =>
    from.getTime() < period.to.getTime() && to.getTime() > period.from.getTime();
};
