import React from 'react';
import { Box, CircularProgress, makeStyles, createStyles } from '@material-ui/core';
import logo from '../../logo.svg'

const useStyles = makeStyles((theme) => createStyles({
  padding: {
    margin: theme.spacing(2)
  }
}));

const SplashScreen = () => {
  const classes = useStyles();
  return (
    <Box
      height="100vh"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center">

      <img src={logo} width="300" height="200" alt="PerformHealth logo" className={classes.padding} />

      <CircularProgress className={classes.padding} />

    </Box>
  );
};

export default SplashScreen;