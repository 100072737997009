export function lazy<T>(factory: () => T): () => T {
  let instance: T | null = null;
  return () => {
    if (instance) {
      return instance;
    } else {
      const value = factory();
      instance = value;
      return value;
    }
  }
};