export interface Result<T> {
  readonly fold: (onSuccess: (obj: T) => void, onError: (error: Error) => void) => void;
  readonly isLoading: boolean;
}

class ResultImp<T> implements Result<T> {

  private obj?: T
  private error?: Error
  readonly isLoading: boolean;

  constructor(obj?: T, error?: Error, isLoading: boolean = false) {
    this.obj = obj;
    this.error = error;
    this.isLoading = isLoading;
  }

  fold: (onSuccess: (obj: T) => void, onError: (error: Error) => void) => void = (onSuccess, onError) => {
    if (this.obj) {
      onSuccess(this.obj);
    } else if (this.error) {
      onError(this.error);
    }
  }

}

export function Success<T>(obj: T): Result<T> {
  return new ResultImp<T>(obj, undefined, false);
}

export function Failure<T>(error: Error): Result<T> {
  return new ResultImp<T>(undefined, error, false);
}

export function Loading<T>(): Result<T> {
  return new ResultImp<T>(undefined, undefined, true);
}