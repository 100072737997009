import { of, throwError, zip } from "rxjs";
import { flatMap, take } from "rxjs/operators";
import UserRepository from "../UserRepository";
import GetUserUseCase from "./GetUserUseCase";

export class PasswordTooShortError extends Error {
  readonly minLength: number;
  constructor(minLength: number) {
    super();
    this.minLength = minLength;
  }
}

/**
 * Updates the password of the current logged user.
 * 
 * @throws {PasswordTooShortError} if the provided password does not match the minimum length.
 */
export default class UpdateUserPasswordUseCase {

  static passwordMinLength = 6;

  private readonly getUserUseCase: GetUserUseCase;
  private readonly userRepository: UserRepository;

  constructor(
    getUserUseCase: GetUserUseCase,
    userRepository: UserRepository
  ) {
    this.getUserUseCase = getUserUseCase;
    this.userRepository = userRepository;
  }

  execute = (password: string) =>
    of(password)
      .pipe(
        flatMap(password => {
          if (password.length < UpdateUserPasswordUseCase.passwordMinLength) {
            return throwError(new PasswordTooShortError(UpdateUserPasswordUseCase.passwordMinLength));
          } else {
            return of(password);
          }
        }),
        flatMap(password => zip(of(password), this.getUserUseCase.execute().pipe(take(1)))),
        flatMap(([password, user]) => this.userRepository.updatePassword(user.identifier, password)),
      )
}