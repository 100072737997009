export const ROUTE_SPLASHSCREEN = "/";

export const ROUTE_LOGIN = "/login";

export const ROUTE_USER = "/app";
export const ROUTE_USER_OVERVIEW = "/app/overview";
export const ROUTE_USER_BOOKING = "/app/booking";
export const ROUTE_USER_PROFILE = "/app/profile";

export const ROUTE_ADMIN = "/admin";
export const ROUTE_ADMIN_CALENDAR = ROUTE_ADMIN + "/calendar";
export const ROUTE_ADMIN_CALENDAR_DETAIL = ROUTE_ADMIN + "/calendar/:id";
export const ROUTE_ADMIN_CLIENTS = ROUTE_ADMIN + "/clients";
export const ROUTE_ADMIN_CLIENTS_DETAIL = ROUTE_ADMIN + "/clients/:id";
export const ROUTE_ADMIN_CLIENTS_NEW = ROUTE_ADMIN + "/clients/new";
export const ROUTE_ADMIN_CLIENTS_ASSIGN_PACKAGE = ROUTE_ADMIN + "/clients/:id/assign-package";
export const ROUTE_ADMIN_CLIENTS_EDIT_PACKAGE = ROUTE_ADMIN + "/clients/:customerId/edit-package/:packageId";
export const ROUTE_ADMIN_CLOSURES = ROUTE_ADMIN + "/company-closures";
export const ROUTE_ADMIN_SETTINGS = ROUTE_ADMIN + "/settings";

export const addSegment = (route: string, segment: string) => route + "/" + segment;