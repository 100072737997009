import { concat, Observable, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import AppointmentDuration from "../Domain/Settings/AppointmentDuration";
import BusinessDaysSchedule, { BusinessDay } from "../Domain/Settings/BusinessDay";
import SettingsRepository from "../Domain/Settings/SettingsRepository";
import { asObservable } from "../Utils/PromiseUtils";
import { observe, observeCollection } from "./FirestoreUtils";
import BusinessDayScheduleDto, { mapToBusinessDaysSchedule, mapToBusinessDaysScheduleDto, mapToBusinessSlotsDto } from "./Settings/BusinessDayScheduleDto";

const businessDaysCollection = "businessDays";
const appointmentDurationsCollection = "appointmentDurations";

export default class SettingsRepoImp implements SettingsRepository {

  constructor(
    private readonly firestore: () => firebase.firestore.Firestore
  ) { }

  getBusinessDaysSchedules = (): Observable<BusinessDaysSchedule[]> =>
    observeCollection(this.firestore().collection(businessDaysCollection))
      .pipe(
        map(collection => collection.docs.map(doc => ({ ...doc.data(), identifier: doc.id }) as BusinessDayScheduleDto)),
        map(dtos => dtos.filter(dto => dto.identifier.length > 1)), // <-- TODO: remove
        map(dtos => dtos.map(mapToBusinessDaysSchedule)),
      );

  getBusinessDaysSchedule = (scheduleId: string): Observable<BusinessDaysSchedule> =>
    observe(this.firestore().collection(businessDaysCollection).doc(scheduleId))
      .pipe(map(doc => mapToBusinessDaysSchedule(({ ...doc.data(), identifier: doc.id }) as BusinessDayScheduleDto)))

  getAppointmentDurations = (): Observable<AppointmentDuration[]> =>
    asObservable(
      this.firestore()
        .collection(appointmentDurationsCollection)
        .get()
    ).pipe(map(result => docsToAppointmentDurations(result.docs)))

  getSelectedAppointmentDuration = (): Observable<AppointmentDuration> =>
    asObservable(
      this.firestore()
        .collection(appointmentDurationsCollection)
        .where("isSelected", "==", true)
        .limit(1)
        .get()
    ).pipe(switchMap(result => {
      if (result.empty) {
        return this.getAppointmentDurations().pipe(map(([duration]) => duration));
      } else {
        return docsToAppointmentDurations([result.docs[0]]);
      }
    }))

  saveBusinessDaySchedule = (schedule: BusinessDaysSchedule): Observable<BusinessDaysSchedule> => {
    return of(schedule)
      .pipe(
        switchMap((schedule): Observable<BusinessDaysSchedule> => {
          const dto = mapToBusinessDaysScheduleDto(schedule);
          return asObservable(
            this.firestore()
              .collection(businessDaysCollection)
              .doc(schedule.identifier)
              .set(dto)
          ).pipe(take(1), map(() => schedule));
        }),
      )
  }

  deleteBusinessDaySchedule = (scheduleId: string): Observable<void> => {
    return of(scheduleId)
      .pipe(
        switchMap((id): Observable<void> => {
          return asObservable(
            this.firestore()
              .collection(businessDaysCollection)
              .doc(id)
              .delete()
          );
        }),
        take(1),
      )
  }

  saveSelectedBusinessDays = (scheduleId: string, businessDays: BusinessDay[]): Observable<void> => {
    return of(businessDays)
      .pipe(
        switchMap((days) => {
          const updatedBusinessDays = days.reduce((acc, day) => ({
            ...acc,
            [day.dayOfWeek]: day.businessHourSlots.map(mapToBusinessSlotsDto)
          }), {});
          return asObservable(
            this.firestore()
              .collection(businessDaysCollection)
              .doc(scheduleId)
              .update({ businessDays: updatedBusinessDays })
          );
        }),
        take(1),
      )
  }

  saveSelectedAppointmentDuration = (durationId: string): Observable<void> =>
    of(durationId)
      .pipe(
        switchMap(targetDurationId =>
          asObservable(this.firestore().collection(appointmentDurationsCollection).get())
            .pipe(
              map(result => result.docs.map(doc => doc.id)),
              switchMap(ids => concat(
                ids.map(id =>
                  asObservable(
                    this.firestore()
                      .collection(appointmentDurationsCollection)
                      .doc(id)
                      .update({ isSelected: id === targetDurationId })
                  )
                )
              )),
              take(1),
              map(() => { })
            )
        )
      )
}

const docsToAppointmentDurations = (
  docs: firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]
): AppointmentDuration[] => docs.map(doc => ({
  identifier: doc.id,
  minutes: doc.get("minutes")
}));