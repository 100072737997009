import { Observable, throwError } from "rxjs";
import { catchError, mergeMap } from "rxjs/operators";
import UserErrors from "../Domain/UserErrors";
import UserRepository from "../Domain/UserRepository";
import { RestError } from "./Rest/RestErrors";
import UserApi from "./Rest/UserApi";

const firebaseAuthUserNotFoundError = "auth/user-not-found"
const firebaseAuthWrongPasswordError = "auth/wrong-password"
const firebaseAuthTooManyReqError = "auth/too-many-requests"

export default class UserRepoImp implements UserRepository {

  constructor(
    private readonly firebaseAuth: () => firebase.auth.Auth,
    private readonly userApi: UserApi
  ) {
  }

  isUserLogged = () => new Observable<boolean>((emitter) => {
    const cancellation = this.firebaseAuth().onAuthStateChanged((user) => {
      emitter.next(user != null);
    });
    return cancellation;
  });

  login = (email: string, password: string) =>
    this.doLogin(email, password)
      .pipe(
        mergeMap(() => this.userApi.getUser()),
        catchError(err => {
          if (err instanceof RestError) {
            throw mapRestError(err);
          } else {
            const code = err.code;
            if (!code) throw err;
            if (code === firebaseAuthUserNotFoundError) {
              throw new Error(UserErrors.wrongEmail);
            } else if (code === firebaseAuthWrongPasswordError) {
              throw new Error(UserErrors.wrongPassword);
            } else if (code === firebaseAuthTooManyReqError) {
              throw new Error(UserErrors.tooManyFailedLoginAttempts);
            } else {
              throw err;
            }
          }
        })
      );

  getUser = () => this.userApi.getUser().pipe(
    catchError(err => {
      if (err instanceof RestError) {
        return throwError(mapRestError(err));
      } else {
        return throwError(err);
      }
    })
  );

  logout = () => new Observable<void>(emitter => {
    this.firebaseAuth().signOut()
      .then(() => emitter.next())
      .catch(error => emitter.error(error));
  });

  updatePhoneNumber = (userId: string, phoneNumber: string) =>
    this.userApi.updatePhoneNumber(userId, phoneNumber);

  updatePassword = (userId: string, newPassword: string) =>
    this.userApi.updatePassword(userId, newPassword);

  resetPassword = (userId: string) =>
    this.userApi.resetPassword(userId);

  // Private methods

  private doLogin = (email: string, password: string) => new Observable<void>(emitter => {
    this.firebaseAuth()
      .signInWithEmailAndPassword(email, password)
      .then(credentials => {
        const user = credentials.user;
        if (user == null) {
          emitter.error("User is not present!")
        } else {
          emitter.next();
        }
      })
      .catch(error => emitter.error(error));
  })
}

const mapRestError = (error: RestError): Error => {
  switch (error.type) {
    case "network-unavailable":
      return new Error(UserErrors.networkUnavailable);
    case "other":
      return error;
  }
}