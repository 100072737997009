import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import DateTimeProvider from '../../Utils/DateTimeProvider';
import GetCustomerUseCase from '../Customers/GetCustomerUseCase';

/**
 * Returns `true` if the user that is identified by the given userId can currently make other
 * bookings in the system. 
 */
export default class IsBookingAvailableUseCase {

  constructor(
    private readonly getCustomerUseCase: GetCustomerUseCase,
    private readonly dateTimeProvider: DateTimeProvider,
  ) { }

  execute: (userId: string) => Observable<boolean> = (userId) => {
    return this.getCustomerUseCase
      .execute(userId)
      .pipe(
        take(1),
        map((customer) => {
          const currentDate = this.dateTimeProvider.currentTimeMillis();
          return customer.packages
            .findIndex(pack =>
              // Package does not expire or package is not expired
              ((!pack.expirationDate) || (pack.expirationDate && pack.expirationDate.valueOf() > currentDate))
              // Package is started
              && pack.buyDate.valueOf() <= currentDate
              // There are available tokens for booking
              && pack.leftTokenCount > 0
            ) !== -1
        })
      )
  }
}