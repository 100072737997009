import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import React, { FunctionComponent } from 'react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    childrenContainer: {
      margin: theme.spacing(2)
    }
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface FullScreenDialogProps {
  readonly isVisible: boolean;
  readonly title: string;
  readonly confirmText: string;
  readonly onConfirmButtonClicked: () => void;
  readonly onClosed: () => void;
}

const FullScreenDialog: FunctionComponent<FullScreenDialogProps> = (props) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(props.isVisible);

  const handleClose = () => {
    setOpen(false);
    props.onClosed();
  };

  const handleConfirmation = () => {
    props.onConfirmButtonClicked();
  }

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {props.title}
          </Typography>
          <Button autoFocus color="inherit" onClick={handleConfirmation}>
            {props.confirmText}
          </Button>
        </Toolbar>
      </AppBar>
      <div className={classes.childrenContainer}>
        {props.children}
      </div>
    </Dialog>
  );
}

export default FullScreenDialog;