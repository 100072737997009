import MuiSnackbar from "@material-ui/core/Snackbar";
import Alert, { Color } from '@material-ui/lab/Alert';
import React, { useEffect, useState } from "react";

export type SnackbarVariant = Color;

interface SnackbarProps {
  /** Wether the snackbar should be displayed. */
  open?: boolean,
  /** The message that the snackbar should show */
  message: string,
  /** The variant of the snackbar ('success', 'info', 'warning', 'error'). */
  variant: SnackbarVariant,
  /** Callback invoked when the snackbar has been dismissed. */
  onClosed?: () => void;
}

/**
 * Displays a snackbar on the screen showing a custom message.
 * 
 * @param props the Snackbar props
 */
const Snackbar = (props: SnackbarProps) => {
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.open === true);
  }, [props.open])

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
    props.onClosed?.();
  };

  return (
    <MuiSnackbar open={isOpen} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={props.variant}>{props.message}</Alert>
    </MuiSnackbar>
  );
}

export default Snackbar;