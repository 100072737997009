
import { combineLatest, NEVER, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, mapTo, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import BookAppointmentUseCase, { TimeSlotLimitReachedError } from '../../../Domain/Bookings/BookAppointmentUseCase';
import { DayState } from '../../../Domain/Bookings/DayState';
import GetAvailableMonthDaysUseCase from '../../../Domain/Bookings/GetAvailableMonthDaysUseCase';
import GetAvailableTimeSlotsUseCase from '../../../Domain/Bookings/GetAvailableTimeSlotsUseCase';
import IsBookingAvailableUseCase from '../../../Domain/Bookings/IsBookingAvailableUseCase';
import TimeSlot, { TimeSlotId } from '../../../Domain/Entities/TimeSlot';
import User from '../../../Domain/User';
import GetUserUseCase from '../../../Domain/UserAuth/GetUserUseCase';
import Logger from '../../../Logger/Logger';
import { format } from '../../../Utils/DateUtils';
import { Failure, Loading, Success } from '../../../Utils/Result';
import UserBookingView from './UserBookingView';

export type Hour = {
  readonly timeSlotId: TimeSlotId;
  readonly label: string;
};

export type SelectableHour = Hour & {
  readonly isSelected: boolean;
};

export default class UserBookingPresenter {

  private readonly user: Observable<User>;
  private readonly isBookingAvailable: Observable<boolean>;
  private readonly lastSelectedHour: Observable<Hour | null>;
  private readonly subscription = new Subscription();
  private readonly selectedDate = new ReplaySubject<Date | null>(1);
  private readonly selectedHour = new ReplaySubject<Hour | null>(1);
  private readonly monthAppointmentLoadRequests = new Subject<Date>();

  private view?: UserBookingView;

  constructor(
    getUserUseCase: GetUserUseCase,
    isBookingAvailableUseCase: IsBookingAvailableUseCase,
    private readonly bookAppointmentUseCase: BookAppointmentUseCase,
    private readonly getAvailableMonthDaysUseCase: GetAvailableMonthDaysUseCase,
    private readonly getAvailableTimeSlotsUseCase: GetAvailableTimeSlotsUseCase,
  ) {
    this.user = getUserUseCase.execute().pipe(shareReplay(1));
    this.isBookingAvailable = this.user.pipe(
      switchMap(user => isBookingAvailableUseCase.execute(user.identifier)),
      shareReplay(1),
    );

    // Keeps the last selected hour, resetting it to `null` if date changes.
    this.lastSelectedHour = this.isBookingAvailable.pipe(
      filter((isBookingAvailable) => isBookingAvailable),
      switchMap(() => {
        return this.selectedDate.pipe(
          startWith(null),
          switchMap(() => this.selectedHour),
        )
      }),
      distinctUntilChanged(),
      shareReplay(1)
    );
  }

  attachView = (v: UserBookingView) => {
    this.view = v;

    this.subscription.add(
      this.isBookingAvailable.subscribe(
        isAvailable => {
          if (isAvailable) {
            this.onBookingAvailable();
          } else {
            this.onBookingUnavailable();
          }
        },
        Logger.e
      )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  setDate = (date: Date) => {
    this.selectedDate.next(date);
    this.selectedHour.next(null);
  }

  setHour = (hour: Hour) => {
    this.selectedHour.next(hour);
  }

  loadMonthAppointments = (month: Date) => {
    // Reset previous date and time section.
    this.selectedDate.next(null);
    this.selectedHour.next(null);
    this.view?.showAvailableDaysOfMonth(new Map());
    this.view?.showAvailableHours([]);

    // Proceed with the month appointments loading.
    this.monthAppointmentLoadRequests.next(month);
  }

  bookAppointment = () => {
    this.subscription.add(
      combineLatest([this.selectedDate, this.selectedHour])
        .pipe(take(1))
        .subscribe(
          ([date, hour]) => {
            if (date && hour) {
              this.view?.showConfirmationDialog(
                format(date, "EEEE dd MMMM yyyy"), hour.label
              );
            }
          },
          Logger.e
        )
    );
  }

  confirmBooking = () => {
    this.subscription.add(
      combineLatest([this.selectedDate, this.selectedHour])
        .pipe(take(1), switchMap(([date, hour]) => (date && hour) ? this.doBooking(date, hour) : NEVER))
        .pipe(mapTo(false), startWith(true))
        .subscribe(
          isLoading => {
            if (isLoading) {
              this.view?.showBookingLoading();
            } else {
              this.view?.hideBookingLoading();
              this.view?.showBookingCompletedMessage();
              this.reloadMonth();
            }
          },
          error => {
            Logger.e(error);
            this.view?.hideBookingLoading();
            if (error.type === TimeSlotLimitReachedError.type) {
              this.view?.showTimeSlotsUnavailableMessage();
            } else {
              this.view?.showBookingFailedMessage();
            }
          }
        )
    );
  }

  private reloadMonth = () => {
    this.subscription.add(
      this.selectedDate
        .pipe(take(1))
        .subscribe(
          date => date && this.loadMonthAppointments(date),
          error => Logger.e(error)
        )
    );
  }

  // Private methods

  private onBookingAvailable = () => {
    this.subscription.add(
      this.selectedDate
        .pipe(
          filter(date => date ? true : false),
          switchMap(date =>
            combineLatest([this.getAvailableHours(date!), this.lastSelectedHour])
              .pipe(
                map(([hours, lastSelectedHour]) => {
                  return hours.map(hour => ({
                    ...hour,
                    isSelected: hour.timeSlotId === lastSelectedHour?.timeSlotId
                  }))
                }),
                map(hours => Success(hours)),
                startWith(Loading<SelectableHour[]>()),
                catchError(error => of(Failure<SelectableHour[]>(error)))
              )
          )
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showAvailableHoursLoading();
            } else {
              this.view?.hideAvailableHoursLoading();
              result.fold(
                availableHours => this.view?.showAvailableHours(availableHours),
                Logger.e
              )
            }
          }
        )
    );

    this.subscription.add(
      this.monthAppointmentLoadRequests
        .pipe(
          switchMap(month =>
            this.getAvailableMonthDays(month)
              .pipe(
                map(availableDays => Success(availableDays)),
                startWith(Loading<Map<number, DayState>>()),
                catchError(error => of(Failure<Map<number, DayState>>(error))),
              )
          )
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showAvailableDaysLoading();
            } else {
              this.view?.hideAvailableDaysLoading();
              result.fold(
                dayStates => this.view?.showAvailableDaysOfMonth(dayStates),
                error => Logger.e(error)
              )
            }
          },
          Logger.e
        )
    );

    this.subscription.add(
      combineLatest([this.selectedDate, this.selectedHour])
        .pipe(map(([date, hour]) => date && hour), startWith(false))
        .subscribe(
          canSubmit => {
            if (canSubmit) {
              this.view?.enableBookingSubmit();
            } else {
              this.view?.disableBookingSubmit();
            }
          },
          Logger.e
        )
    );

    this.view?.showBookingAvailableState();
    this.loadMonthAppointments(new Date());
  }

  private onBookingUnavailable = () => {
    this.view?.showBookingUnavailableState()
  }

  private getAvailableHours: (date: Date) => Observable<Hour[]> = (date) => {
    return this.getAvailableTimeSlotsUseCase
      .execute(date)
      .pipe(map(slots => slots.map(slotToDisplayableSlot())));
  }

  private getAvailableMonthDays: (date: Date) => Observable<Map<number, DayState>> = (date) =>
    this.getAvailableMonthDaysUseCase
      .execute(date.getMonth(), date.getFullYear())
      .pipe(map(results => {
        const map = new Map<number, DayState>();
        results.forEach((value, day) => {
          map.set(new Date(date.getFullYear(), date.getMonth(), day).getTime(), value);
        });
        return map;
      }));

  private doBooking: (date: Date, hour: Hour) => Observable<string> = (date, hour) => {
    return this.user
      .pipe(
        map<User, [User, TimeSlotId]>(user => [user, hour.timeSlotId]),
        switchMap(([user, timeSlotId]) =>
          this.bookAppointmentUseCase.execute(user.identifier, date, timeSlotId)
        )
      );
  }
};

const slotToDisplayableSlot = () => (slot: TimeSlot): Hour => {
  const helperDate = new Date(0);
  const pattern = "HH:mm";

  helperDate.setHours(slot.startHour);
  helperDate.setMinutes(slot.startMinute);
  const start = format(helperDate, pattern);

  helperDate.setHours(slot.endHour);
  helperDate.setMinutes(slot.endMinute);
  const end = format(helperDate, pattern);

  return {
    timeSlotId: slot.identifier,
    label: `${start} – ${end}`
  };
}
