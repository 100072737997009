import { Observable } from "rxjs";
import { dateTruncatingTime } from "../../Utils/DateUtils";
import { PackageType } from "../Entities/PackageTypes";
import PackagesRepository from "./PackagesRepository";

export default class EditPackageUseCase {

  constructor(
    private readonly packagesRepository: PackagesRepository
  ) {
  }

  execute = (
    customerId: string,
    packageId: string,
    packageType: PackageType,
    buyDate: Date,
    startDate: Date,
    endDate: Date | null,
    tokenCount: number,
    maxWeeklyBookingsCount: number
  ): Observable<string> => this.packagesRepository.updateCustomerPackage(
    customerId, packageId, packageType,
    dateTruncatingTime(buyDate),
    dateTruncatingTime(startDate),
    (endDate && dateTruncatingTime(endDate)) || null,
    tokenCount,
    maxWeeklyBookingsCount
  );
}