import Package, { PackageState } from "../../../../Domain/Entities/Package";
import { getPackageImage, getPackageName, getPackageStatusText } from "../../Packages/PackagesMappers";
import { PackageType } from "../../../../Domain/Entities/PackageTypes";

export default interface UiSimplePackage {
  readonly identifier: string;
  readonly name: string;
  readonly type: PackageType,
  readonly weeklyTokensLimit: number;
  readonly displayableDateFrom: string;
  readonly displayableDateTo: string | null;
  readonly packageImageUrl: string;
  readonly status: PackageState;
  readonly statusText: string;
}

export const mapPackageToUiSimplePackage = (
  pack: Package,
  packageState: PackageState,
  formatDate: (date: Date) => string
): UiSimplePackage => {
  return {
    identifier: pack.identifier,
    name: getPackageName(pack.type),
    type: pack.type,
    weeklyTokensLimit: pack.maxWeeklyBookingsCount,
    displayableDateFrom: formatDate(pack.startDate),
    displayableDateTo: (pack.expirationDate && formatDate(pack.expirationDate)) || null,
    packageImageUrl: getPackageImage(pack.type),
    status: packageState,
    statusText: getPackageStatusText(packageState),
  };
}