import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import { AccessTime, People } from '@material-ui/icons';
import React, { useState } from "react";
import strings from "../Utils/LocalizedStrings";

const useStyles = makeStyles((theme) => createStyles({
  dialogRow: {
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row"
  },
  dialogRowText: {
    marginLeft: theme.spacing(1),
    alignSelf: "center"
  },
  dialogEntryList: {
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  dialogEntryIcon: {
    alignSelf: "center",
    marginRight: theme.spacing(1)
  },
  dialogClickableEntry: {
    textDecoration: "underline",
    color: theme.palette.primary.main,
    cursor: "pointer"
  }
}));

export interface EventDialogEntry {
  readonly identifier: string;
  readonly text: string;
}

interface EventDialogProps {
  /** The title of the dialog */
  readonly title: string;
  /** The time in which the event will occur */
  readonly displayableTime: string;
  /** The list of entries that are clickable */
  readonly clickableEntries: EventDialogEntry[];
  /** Callback invoked when an entry is clicked */
  readonly onEntryClicked: (id: string) => void;
  readonly onClosed: () => void;
}

/**
 * A dialog which is used for displaying data about an event.
 * Since an event can refer to one or more participants, a list of entries is provided.
 */
const EventDialog = (props: EventDialogProps) => {
  const [isOpen, setOpen] = useState(true);
  const close = () => {
    setOpen(false);
    props.onClosed();
  }
  const classes = useStyles();

  return (
    <Dialog open={isOpen} onClose={() => close()}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Box className={classes.dialogRow}>
            <AccessTime />
            <label className={classes.dialogRowText}>{props.displayableTime}</label>
          </Box>
          <Box className={classes.dialogRow}>
            <Box className={classes.dialogEntryIcon}><People /></Box>
            <Box className={classes.dialogEntryList}>
              {
                props.clickableEntries.map(entry =>
                  <span key={entry.identifier}
                    className={classes.dialogClickableEntry}
                    onClick={() => props.onEntryClicked(entry.identifier)}>
                    {entry.text}
                  </span>
                )
              }
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="default" onClick={() => close()}>
          {strings.commons.close}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;