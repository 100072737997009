import firebase from "firebase";
import { InsufficientTokenCountError, MissingPackageError, TimeSlotLimitReachedError } from "../../Domain/Bookings/BookAppointmentUseCase";
import BookingsApi, { AvailableMonthDaysResponse, AvailableTimeSlotsResponse, UserAppointmentResponse } from "./BookingsApi";

const TimeSlotLimitReachedErrorMessage = "TimeSlotLimitReachedError";
const InsufficientTokenCountErrorMessage = "InsufficientTokenCountError";
const MissingPackageErrorMessage = "MissingPackageError";

const FirebaseBookingsApi = (
  functions: () => firebase.functions.Functions
): BookingsApi => ({
  bookAppointment: async request => {
    let bookingId: string | null;
    try {
      const result = await functions().httpsCallable("bookAppointment")(request);
      bookingId = result.data.bookingId;
    } catch (e) {
      bookingId = null;
      if (e instanceof Error)
        switch (e.message) {
          case TimeSlotLimitReachedErrorMessage:
            throw new TimeSlotLimitReachedError();
          case InsufficientTokenCountErrorMessage:
            throw new InsufficientTokenCountError();
          case MissingPackageErrorMessage:
            throw new MissingPackageError();
          default:
            throw e;
        }
    }
    if (bookingId) {
      return bookingId;
    } else {
      throw new Error("Function returned invalid bookingId");
    }
  },
  getUserAppointments: async request => {
    const result = await functions().httpsCallable("userAppointments")(request);
    return result.data as UserAppointmentResponse[];
  },
  getAvailableMonthDays: async request => {
    const result = await functions().httpsCallable('availableMonthDays')(request);
    return result.data as AvailableMonthDaysResponse[];
  },
  getAvailableTimeSlots: async request => {
    const result = await functions().httpsCallable('availableTimeSlots')(request);
    return result.data as AvailableTimeSlotsResponse[];
  }
});

export default FirebaseBookingsApi;