import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import BusinessDaysSchedule from './BusinessDay';
import SettingsRepository from './SettingsRepository';

export default class UpdateBusinessDayScheduleUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
  ) {
  }

  execute = (
    scheduleId: string,
    description: string,
    startDate: Date,
    endDate: Date,
  ): Observable<BusinessDaysSchedule> => {
    return this.settingsRepository
      .getBusinessDaysSchedule(scheduleId)
      .pipe(
        take(1),
        switchMap(schedule => {
          const updatedSchedule: BusinessDaysSchedule = {
            ...schedule,
            description: description,
            startDate: startDate,
            endDate: endDate,
          };
          return this.settingsRepository.saveBusinessDaySchedule(updatedSchedule);
        })
      )
  }
}