import firebase from 'firebase/app';
import 'firebase/firestore';
import { Observable } from 'rxjs';
import { map, mapTo } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import Package from '../Domain/Entities/Package';
import PackagesRepository from "../Domain/Packages/PackagesRepository";
import { Constants } from '../Utils/Constants';
import DateTimeProvider from '../Utils/DateTimeProvider';
import { fromFloatingDateString, fromFloatingDateStringOrNull, toFloatingDateString } from '../Utils/DateUtils';
import { asObservable } from '../Utils/PromiseUtils';
import { PackageType } from "../Domain/Entities/PackageTypes";

export type PackageDto = {
  readonly id: string;
  readonly type: PackageType;
  readonly buyDate: string;
  readonly initialTokenCount: number;
  readonly leftTokenCount: number;
  readonly weeklyMax: number | null;
  readonly startDate: string;
  readonly expirationDate: string | null;
  readonly updateDate: firebase.firestore.Timestamp
}

export const mapPackageDtoToPackage = (packDoc: PackageDto): Package => ({
  identifier: packDoc.id,
  type: packDoc.type,
  buyDate: fromFloatingDateString(packDoc.buyDate),
  initialTokenCount: packDoc.initialTokenCount,
  leftTokenCount: packDoc.leftTokenCount,
  maxWeeklyBookingsCount: packDoc.weeklyMax ?? Constants.defaultWeeklyBookingsLimit,
  startDate: fromFloatingDateString(packDoc.startDate),
  expirationDate: fromFloatingDateStringOrNull(packDoc.expirationDate) ?? null,
  updateDate: packDoc.updateDate.toDate(),
});

export default class PackagesRepoImp implements PackagesRepository {

  constructor(
    private readonly firestore: () => firebase.firestore.Firestore,
    private readonly dateTimeProvider: DateTimeProvider,
  ) {
  }

  getCustomerPackageOnDate = (
    customerId: string,
    date: Date
  ): Observable<Package | null> => {
    return asObservable(
      this.firestore()
        .collection("users")
        .doc(customerId)
        .collection("packages")
        .where("expirationDate", ">=", toFloatingDateString(date))
        .orderBy("expirationDate", "asc")
        .limit(1)
        .get()
    ).pipe(
      map(snapshot =>
        snapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() } as PackageDto))
      ),
      map(dtos => dtos.length === 0 ? null : mapPackageDtoToPackage(dtos[0]))
    )
  }

  assignPackageToCustomer = (
    customerId: string,
    packageType: PackageType,
    buyDate: Date,
    startDate: Date,
    endDate: Date | null,
    tokenCount: number,
    maxWeeklyBookingsCount: number
  ): Observable<string> => {
    const packageId = uuid();
    return asObservable(
      this.firestore().collection("users")
        .doc(customerId)
        .collection("packages")
        .doc(packageId)
        .set({
          type: packageType,
          buyDate: toFloatingDateString(buyDate),
          startDate: toFloatingDateString(startDate),
          expirationDate: (endDate && toFloatingDateString(endDate)) || null,
          initialTokenCount: tokenCount,
          leftTokenCount: tokenCount,
          updateDate: new Date(this.dateTimeProvider.currentTimeMillis()),
          weeklyMax: maxWeeklyBookingsCount
        })
    ).pipe(mapTo(packageId));
  };

  updateCustomerPackage = (
    customerId: string,
    packageId: string,
    packageType: string,
    buyDate: Date,
    startDate: Date,
    endDate: Date | null,
    tokenCount: number,
    maxWeeklyBookingsCount: number,
  ): Observable<string> => {
    return asObservable(
      this.firestore().collection("users")
        .doc(customerId)
        .collection("packages")
        .doc(packageId)
        .update({
          type: packageType,
          buyDate: toFloatingDateString(buyDate),
          startDate: toFloatingDateString(startDate),
          expirationDate: (endDate && toFloatingDateString(endDate)) || null,
          leftTokenCount: tokenCount,
          updateDate: new Date(this.dateTimeProvider.currentTimeMillis()),
          weeklyMax: maxWeeklyBookingsCount
        })
    ).pipe(mapTo(packageId));
  }
}