
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import CompanyClosure from './CompanyClosure';
import CompanyRepository from './CompanyRepository';
import { isAllDayLong } from './IsAllDayLong';

export default class GetCompanyClosurePeriodsUseCase {

  constructor(
    private readonly companyRepository: CompanyRepository,
  ) {
  }

  execute = (): Observable<CompanyClosure[]> =>
    this.companyRepository
      .getCompanyClosurePeriods()
      .pipe(
        map(closures =>
          closures.map(closure => ({
            ...closure,
            isAllDayLong: isAllDayLong(closure.from, closure.to),
          }))
        )
      );
};
