import { Observable } from "rxjs";
import SettingsRepository from "./SettingsRepository";

export default class SaveAppointmentDurationUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
  ) { }

  execute = (durationId: string): Observable<void> =>
    this.settingsRepository.saveSelectedAppointmentDuration(durationId);
}