import { map } from "rxjs/operators";
import CustomersRepository from "./CustomersRepository";

export default class GetCustomerUseCase {

  private customersRepo: CustomersRepository;

  constructor(customersRepository: CustomersRepository) {
    this.customersRepo = customersRepository;
  }

  execute = (customerId: string) => this.customersRepo
    .getCustomer(customerId)
    .pipe(map(customer => ({
      ...customer,
      packages: customer.packages
        .sort((lhs, rhs) => lhs.startDate.getTime() - rhs.startDate.getTime())
    })));
};