import { from, Observable } from "rxjs";
import { map, mapTo, mergeMap, take } from "rxjs/operators";
import Booking from "../Domain/Bookings/Booking";
import BookingsRepository from "../Domain/Bookings/BookingsRepository";
import { AvailableMonthDays } from "../Domain/Bookings/GetAvailableMonthDaysUseCase";
import TimeSlot from "../Domain/Entities/TimeSlot";
import User from "../Domain/User";
import UserRepository from "../Domain/UserRepository";
import { fromFloatingDateString, toFloatingDateString } from "../Utils/DateUtils";
import { asObservable } from "../Utils/PromiseUtils";
import { asTimestamp } from "./FirestoreUtils";
import BookingsApi from "./Rest/BookingsApi";

export type BookingDto = {
  id: string,
  userId: string,
  history: any[],
  createdTimestamp: firebase.firestore.Timestamp,
  startTimestamp: string,
  endTimestamp: string
};

export default class BookingsRepoImp implements BookingsRepository {

  constructor(
    private readonly userRepository: UserRepository,
    private readonly bookingsApi: BookingsApi,
    private readonly firestore: () => firebase.firestore.Firestore,
  ) {
  }

  bookAppointment = (
    userId: string,
    day: Date,
    timeSlotId: string,
  ) => from(
    this.bookingsApi.bookAppointment({
      day: toFloatingDateString(day),
      timeSlotId: timeSlotId
    })
  )

  getUserBookings = (user: User, from: Date, to: Date | null) =>
    this.userBookings(user.identifier, from, to).pipe(map(docs =>
      docs.map((doc): Booking => ({
        identifier: doc.id,
        userId: doc.userId,
        startDate: fromFloatingDateString(doc.startTimestamp),
        endDate: fromFloatingDateString(doc.endTimestamp),
        createdDate: asTimestamp(doc.createdTimestamp).toDate(),
        isCancelable: true,
      }))
    ))

  getBookingsCount = (userId: string, from: Date, to: Date) =>
    this.userBookings(userId, from, to).pipe(map(docs => docs.length))

  getAvailableMonthDays = (month: number, year: number) => new Observable<AvailableMonthDays>(emitter => {
    this.bookingsApi.getAvailableMonthDays({ month: month, year: year })
      .then(array => new Map(array))
      .then(result => {
        emitter.next(result);
        emitter.complete();
      })
      .catch(error => emitter.error(error));
  });

  getAvailableTimeSlots = (day: number, month: number, year: number) => new Observable<TimeSlot[]>(emitter => {
    this.bookingsApi.getAvailableTimeSlots({ day: day, month: month, year: year })
      .then(slots => emitter.next(slots))
      .catch(error => emitter.error(error));
  });

  getBooking = (bookingId: string): Observable<Booking> =>
    asObservable(
      this.firestore()
        .collection("bookings")
        .doc(bookingId)
        .get()
    ).pipe(
      map((doc) => ({ id: doc.id, ...doc.data() }) as BookingDto),
      map(doc => ({
        identifier: doc.id,
        userId: doc.userId,
        startDate: fromFloatingDateString(doc.startTimestamp),
        endDate: fromFloatingDateString(doc.endTimestamp),
        createdDate: asTimestamp(doc.createdTimestamp).toDate(),
        isCancelable: true,
      }))
    )

  deleteBooking = (bookingId: string): Observable<string> =>
    this.userRepository.getUser()
      .pipe(
        map(user => user.role),
        // Set as cancellation cause the role 
        // of the user that canceled the booking.
        mergeMap(role => asObservable(
          this.firestore()
            .collection("bookings")
            .doc(bookingId)
            .update({ cancellationCause: role })
        )),
        mergeMap(() => asObservable(
          this.firestore()
            .collection("bookings")
            .doc(bookingId)
            .get()
        )),
        mergeMap(doc => {
          const userId = doc.get("userId");
          return asObservable(doc.ref.delete()).pipe(mapTo(userId));
        }),
        take(1)
      )

  // Private methods

  private userBookings = (userId: string, from: Date, to: Date | null) => new Observable<BookingDto[]>(emitter => {
    // This query requires an index on the following fields:
    // - userId asc
    // - startTimestamp asc

    return this.firestore()
      .collection("bookings")
      .where("userId", "==", userId)
      .where("startTimestamp", ">=", toFloatingDateString(from))
      .orderBy("startTimestamp")
      .onSnapshot(
        (result) => {
          let bookings: BookingDto[]
          if (to) {
            const dateTo = toFloatingDateString(to);
            bookings = result.docs
              .map(doc => ({ id: doc.id, ...doc.data() }) as BookingDto)
              .filter(doc => doc.endTimestamp < dateTo);
          } else {
            bookings = result.docs
              .map(doc => ({ id: doc.id, ...doc.data() }) as BookingDto);
          }
          emitter.next(bookings);
        },
        error => emitter.error(error)
      );
  });
}