import { differenceInDays, endOfDay, startOfDay, subDays, parseISO, setMinutes, setHours } from "date-fns";
import { Event, stringOrDate } from "react-big-calendar";
import { dateTruncatingTime } from "../../Utils/DateUtils";

export const calendarMinTimeInColumnView = setMinutes(setHours(dateTruncatingTime(new Date()), 5), 30);
export const calendarMaxTimeInColumnView = setMinutes(setHours(dateTruncatingTime(new Date()), 23), 0);
export const calendarSteps = 10;
export const calendarTimeSlots = 3;

export interface CalendarEventInfo {
  readonly start: Date;
  readonly end: Date;
  readonly isAllDay: boolean;
}

interface HasId {
  readonly id: string;
}

interface HasTitle {
  readonly title: string;
}

export interface CalendarEvent extends CalendarEventInfo, HasId, HasTitle {
}

const isEventAllDay = (from: Date, to: Date) =>
  startOfDay(from).valueOf() === from.valueOf()
  && endOfDay(to).valueOf() === to.valueOf();

export const handleEventMove = (event: InteractionEvent, events: CalendarEvent[]): CalendarEvent | null => {
  const didDragToAllDayCell = event.isAllDay === true;
  const start = stringOrDateToDate(event.start);
  const end = stringOrDateToDate(event.end);

  let targetEvent: CalendarEvent | null = null;
  if (didDragToAllDayCell) {
    const newStart = startOfDay(start);
    const newEnd = endOfDay(end);
    targetEvent = {
      ...event.event.resource,
      start: newStart,
      end: newEnd,
      isAllDay: isEventAllDay(newStart, newEnd),
    }
  } else if (event.event.resource.isAllDay) {
    targetEvent = {
      ...event.event.resource,
      start: startOfDay(start),
      end: endOfDay(end),
    };
  } else {
    targetEvent = {
      ...event.event.resource,
      start: start,
      end: end,
    }
  }
  return targetEvent;
}

export const handleEventResize = (event: InteractionEvent, events: CalendarEvent[]): CalendarEvent | null => {
  const index = events.findIndex(e => e.id === event.event.resource.id);
  if (index === -1) return null;

  const start = stringOrDateToDate(event.start);
  const end = stringOrDateToDate(event.end);

  const oldStart = event.event.start as Date;
  const oldEnd = event.event.end as Date;

  const dayDifference = differenceInDays(end, start);
  const didDragRight = start.valueOf() === oldStart.valueOf();
  const didDragLeft = end.valueOf() === oldEnd.valueOf();
  let targetEvent: CalendarEvent | null = null;

  if (dayDifference > 0) {
    if (didDragRight) {
      // The user postponed the event end, but the calendar library gives as end
      // date the midnight of the day after. 
      // es: `23/11 00:00` instead of `22/11 23:59`.
      const newEnd = endOfDay(subDays(end, 1));
      targetEvent = {
        ...event.event.resource,
        start: start,
        end: newEnd,
        isAllDay: isEventAllDay(start, newEnd),
      };
    } else if (didDragLeft) {
      // The user brought forward the event start, and the calendar library gives
      // the correct time value.
      targetEvent = {
        ...event.event.resource,
        start: start,
        end: start,
        isAllDay: isEventAllDay(start, end),
      };
    }
  } else {
    targetEvent = {
      ...event.event.resource,
      start: start,
      end: end,
      isAllDay: isEventAllDay(start, end),
    }
  }
  return targetEvent;
}

export const stringOrDateToDate = (stringOrDate: stringOrDate): Date =>
  (typeof (stringOrDate) === "string")
    ? parseISO(stringOrDate)
    : stringOrDate as Date;

// react-big-calendar stuff

export interface InteractionEvent { event: Event, start: stringOrDate, end: stringOrDate, isAllDay?: boolean };

export type SlotInfo = {
  start: stringOrDate;
  end: stringOrDate;
  slots: Date[] | string[];
  action: 'select' | 'click' | 'doubleClick';
};