import { add } from 'date-fns';
import { Observable } from 'rxjs';
import DateTimeProvider from '../../Utils/DateTimeProvider';
import Appointment from './Appointment';
import AppointmentsRepository from './AppointmentsRepository';

export default class GetCustomersAppointmentsUseCase {

  private readonly appointmentsRepository: AppointmentsRepository;
  private readonly dateTimeProvider: DateTimeProvider;

  constructor(
    appointmentsRepository: AppointmentsRepository,
    dateTimeProvider: DateTimeProvider
  ) {
    this.appointmentsRepository = appointmentsRepository;
    this.dateTimeProvider = dateTimeProvider;
  }

  execute: (customerId: string) => Observable<Appointment[]> = (customerId) => {
    const currentDate = new Date(this.dateTimeProvider.currentTimeMillis());
    return this.appointmentsRepository.getUserAppointments(
      customerId, currentDate, add(currentDate, { years: 1 })
    );
  }
}