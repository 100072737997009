import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import BugfenderLogger from "./Logger/BugfenderLogger";
import SentryLogger from "./Logger/SentryLogger";
import { setupLoggers } from "./Logger/Logger";

const setupApplication = () => {
  Sentry.init({
    dsn: "https://af13447ebe7b4562b27d6da587cf49be@o451048.ingest.sentry.io/5436372",
    integrations: [
      new Integrations.BrowserTracing(),
    ],
    tracesSampleRate: 1.0,
  });

  setupLoggers([
    new BugfenderLogger(),
    new SentryLogger()
  ]);
}

export default setupApplication;