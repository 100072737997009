import { eachDayOfInterval } from "date-fns";
import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router";
import AppFactory from "../../../DI/AppFactory";
import Logger from "../../../Logger/Logger";
import { addSegment, ROUTE_ADMIN_CALENDAR, ROUTE_ADMIN_CALENDAR_DETAIL, ROUTE_ADMIN_CLIENTS } from '../../../Routing/RoutingConstants';
import CalendarPresenter from "./CalendarPresenter";
import CalendarScreen, { CalendarViewType } from "./CalendarScreen";
import EventDetailViewController from "./EventDetail/EventDetailViewController";
import { UiAppointment } from "./UiAppointment";
import UiSimpleEvent from "./UiSimpleEvent";

interface CalendarViewControllerProps {
  readonly factory: AppFactory;
  readonly presenter: CalendarPresenter;
}

interface CalendarState {
  readonly startDate: Date;
  readonly viewType: CalendarViewType;
  readonly events: UiSimpleEvent[];
  readonly detail?: UiAppointment;
}

const CalendarViewController = (props: CalendarViewControllerProps) => {
  const history = useHistory();
  const [presenter] = useState(props.presenter);
  const [state, setState] = useState<CalendarState>({
    startDate: new Date(),
    viewType: "month",
    events: [],
  });

  const view = useCallback(() => ({
    showEvents(events: UiSimpleEvent[]) {
      setState(state => ({
        ...state,
        events: events
      }));
    },
    showEventDetail(identifier: string) {
      history.push(addSegment(ROUTE_ADMIN_CALENDAR, identifier));
    }
  }), [history]);

  const handleViewChange = (view: CalendarViewType) => {
    setState(state => ({ ...state, viewType: view }));
    switch (view) {
      case 'day':
        presenter.dayViewSelected();
        break;
      case 'week':
        presenter.weekViewSelected();
        break;
      case 'month':
        presenter.monthViewSelected();
        break;
    }
  };

  const handleDateRangeChanged = (start: Date, end: Date) => {
    const startDate = getDayOnWhichCalendarShouldFocus(start, end);
    if (startDate) {
      setState(state => ({ ...state, startDate: startDate }));
    } else {
      Logger.d("Not able to determine the start day of the displayed time period.");
    }
    presenter.dateRangeChanged(start, end);
  };

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter, view]);

  const calendarScreen = () =>
    <CalendarScreen
      events={state.events}
      startDate={state.startDate}
      defaultView={state.viewType}
      onEventSelected={(start, end) => presenter.eventClicked(start, end)}
      onDateRangeChanged={handleDateRangeChanged}
      onViewChanged={handleViewChange} />;

  return (
    <Switch>
      <Route exact
        path={ROUTE_ADMIN_CALENDAR_DETAIL}
        render={(route) => {
          const eventId = route.match.params.id;
          if (!eventId) {
            Logger.e(`eventId is ${eventId} when navigating to event detail`);
            history.goBack();
            return;
          }
          return <div>
            {calendarScreen()}
            <EventDetailViewController
              presenter={props.factory.eventDetailPresenter(eventId)}
              onClose={() => history.push(ROUTE_ADMIN_CALENDAR)}
              onCustomerClicked={id => history.push(addSegment(ROUTE_ADMIN_CLIENTS, id))} />
          </div>
        }} />
      <Route>
        {calendarScreen()}
      </Route>
    </Switch>
  );
}

const getDayOnWhichCalendarShouldFocus = (firstDisplayedDate: Date, lastDisplayedDate: Date): Date | null => {
  let months: number[] = [];
  let years: number[] = [];
  let numberOfDisplayedDays: number[] = [];

  eachDayOfInterval({ start: firstDisplayedDate, end: lastDisplayedDate }).forEach(day => {
    if (months.length === 0) {
      months.push(day.getMonth());
      years.push(day.getFullYear());
      numberOfDisplayedDays.push(1);
    } else {
      const lastMonth = months[months.length - 1];
      if (lastMonth === day.getMonth()) {
        const i = numberOfDisplayedDays.length - 1;
        numberOfDisplayedDays[i] = numberOfDisplayedDays[i] + 1;
      } else {
        months.push(day.getMonth());
        years.push(day.getFullYear());
        numberOfDisplayedDays.push(1);
      }
    }
  });
  let indexOfDayWithGreatestCountOfDisplayedDays = -1;
  let max = -1;
  numberOfDisplayedDays.forEach((number, index) => {
    if (number > max) {
      max = number;
      indexOfDayWithGreatestCountOfDisplayedDays = index;
    }
  });
  if (indexOfDayWithGreatestCountOfDisplayedDays >= 0) {
    const month = months[indexOfDayWithGreatestCountOfDisplayedDays];
    const year = years[indexOfDayWithGreatestCountOfDisplayedDays];
    let date: Date;
    if (indexOfDayWithGreatestCountOfDisplayedDays === 0) {
      date = new Date(year, month, firstDisplayedDate.getDate());
    } else {
      date = new Date(year, month, 1);
    }
    return date;
  } else {
    return null;
  }
}

export default CalendarViewController;