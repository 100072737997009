import { Box, Button, Card, CardActions, createStyles, makeStyles } from '@material-ui/core';
import { CalendarToday, DeleteOutline, WatchLaterOutlined } from '@material-ui/icons';
import React from 'react';
import strings from '../Utils/LocalizedStrings';

interface BookingCardProps extends React.HTMLAttributes<HTMLElement> {
  /** The date in which the appointment will happen */
  readonly displayableDate: string;
  /** The time in the date in which the appointment will start */
  readonly displayableTimeStart: string;
  /** The time in the date in which the appointment will end */
  readonly displayableTimeEnd: string;
  /** Whether the cancel button should be displayed ot not. */
  readonly displayCancelButton?: boolean;
  /** Callback invoked when the cancel button is clicked. */
  readonly onCancelButtonClicked?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(4),
  },
  entry: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
  subtitleLabel: {
    color: theme.palette.text.hint
  },
  paddedItem: {
    marginLeft: theme.spacing(1)
  },
  cancel: {
    color: theme.palette.error.light
  }
}));

/**
 * A card used in the user page for showing date and time of a booking.
 */
const BookingCard = (props: BookingCardProps) => {
  const classes = useStyles();
  return (
    <Card className={props.className}>
      <Box className={classes.root}>
        <Box className={classes.entry}>
          <CalendarToday />
          <span className={classes.paddedItem}>{props.displayableDate}</span>
        </Box>
        <Box className={classes.entry}>
          <WatchLaterOutlined />
          <span className={classes.paddedItem}>
            {props.displayableTimeStart}&nbsp;-&nbsp;{props.displayableTimeEnd}
          </span>
        </Box>
      </Box>
      {!props.displayCancelButton ? null : <CardActions>
        <Button
          className={classes.cancel}
          variant="text"
          onClick={() => props.onCancelButtonClicked?.()}>
          <DeleteOutline className={classes.cancel} />&nbsp;{strings.commons.cancel}
        </Button>
      </CardActions>
      }
    </Card>
  )
}

export default BookingCard;