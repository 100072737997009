import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import CompanyClosure, { SimpleCompanyClosure } from '../Domain/CompanyClosures/CompanyClosure';
import CompanyRepository from '../Domain/CompanyClosures/CompanyRepository';
import { flatMap, windowed } from '../Utils/ArrayUtils';
import { fromFloatingDateString, toFloatingDateString } from '../Utils/DateUtils';
import { asObservable } from '../Utils/PromiseUtils';

export default class CompanyRepoImp implements CompanyRepository {

  private readonly firestore: () => firebase.firestore.Firestore;

  constructor(
    firestore: () => firebase.firestore.Firestore
  ) {
    this.firestore = firestore;
  }

  getCompanyClosurePeriods = (): Observable<SimpleCompanyClosure[]> =>
    this.companyClosuresDocuments()
      .pipe(
        map(docs =>
          docs.map(doc => ({
            identifier: doc.id,
            description: doc.get("description") ?? null,
            from: fromFloatingDateString(doc.get("from")),
            to: fromFloatingDateString(doc.get("to"))
          }))
        )
      );

  createPeriods = (periods: CompanyClosure[]) => this.operationOnPeriods(
    this.firestore(), periods, (collection, period) =>
    collection.doc(period.identifier).set({
      description: period.description,
      from: toFloatingDateString(period.from),
      to: toFloatingDateString(period.to),
    })
  )

  updatePeriods = (periods: CompanyClosure[]) => this.operationOnPeriods(
    this.firestore(), periods, (collection, period) =>
    collection.doc(period.identifier).update({
      description: period.description,
      from: toFloatingDateString(period.from),
      to: toFloatingDateString(period.to),
    })
  )

  deletePeriods = (periodIds: string[]) => this.operationOnPeriods(
    this.firestore(), periodIds, (collection, periodId) => collection.doc(periodId).delete()
  )

  private companyClosuresDocuments = () => new Observable<firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>[]>(emitter =>
    this.firestore()
      .collection("companyClosures")
      .onSnapshot(
        data => emitter.next(data.docs),
        error => emitter.error(error),
      )
  );

  private operationOnPeriods = function <T extends CompanyClosure | string>(
    firestore: firebase.firestore.Firestore,
    periods: T[],
    operation: (collection: firebase.firestore.CollectionReference, period: T) => Promise<void>
  ): Observable<void> {
    return asObservable(
      firestore.runTransaction(() => {
        const reference = firestore.collection("companyClosures");
        const addOperations = flatMap(
          windowed(periods, 500),
          periodsWindow => periodsWindow.map(period => operation(reference, period))
        );
        return Promise.all(addOperations);
      })
    ).pipe(map(() => { }));
  }
}