import { Observable, zip } from "rxjs";
import { flatMap, map, take } from "rxjs/operators";
import CompanyClosure, { isCompanyClosureEquals } from "./CompanyClosure";
import CompanyRepository from "./CompanyRepository";
import GetCompanyClosurePeriodsUseCase from "./GetCompanyClosurePeriodsUseCase";

export default class SaveCompanyClosurePeriodsUseCase {

  private readonly getCompanyClosurePeriods: GetCompanyClosurePeriodsUseCase;
  private readonly companyRepository: CompanyRepository;

  constructor(
    getCompanyClosurePeriodsUseCase: GetCompanyClosurePeriodsUseCase,
    companyRepository: CompanyRepository,
  ) {
    this.getCompanyClosurePeriods = getCompanyClosurePeriodsUseCase;
    this.companyRepository = companyRepository;
  }

  execute = (closurePeriods: CompanyClosure[]): Observable<void> =>
    this.getCompanyClosurePeriods
      .execute()
      .pipe(
        take(1),
        flatMap(oldPeriods => {
          const newPeriodsIds = closurePeriods.map(p => p.identifier);
          const currentPeriodsIds = oldPeriods.map(p => p.identifier);
          const periodsToAdd = closurePeriods.filter(period => !currentPeriodsIds.includes(period.identifier));
          const periodsToUpdate = closurePeriods.filter(period => {
            const oldPeriod = oldPeriods.find(p => p.identifier === period.identifier);
            return oldPeriod && !isCompanyClosureEquals(oldPeriod, period);
          });
          const periodsIdsToDelete = currentPeriodsIds.filter(periodId => !newPeriodsIds.includes(periodId));
          return zip([
            this.companyRepository.deletePeriods(periodsIdsToDelete),
            this.companyRepository.updatePeriods(periodsToUpdate),
            this.companyRepository.createPeriods(periodsToAdd),
          ]).pipe(take(1), map(() => { }));
        }))

}