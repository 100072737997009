import { Observable, of, throwError, zip } from "rxjs";
import { flatMap, map, take } from "rxjs/operators";
import { Constants } from "../../Utils/Constants";
import UserRepository from "../UserRepository";
import GetUserUseCase from "./GetUserUseCase";

export class InvalidPhoneNumberError extends Error {
}

/**
 * Updates the phone number of the current logged user.
 * 
 * @throws {InvalidPhoneNumberError} if the provided phone number is not valid.
 */
export default class UpdateUserPhoneNumberUseCase {

  private readonly getUserUseCase: GetUserUseCase;
  private readonly userRepository: UserRepository;

  constructor(
    getUserUseCase: GetUserUseCase,
    userRepository: UserRepository,
  ) {
    this.getUserUseCase = getUserUseCase;
    this.userRepository = userRepository;
  }

  execute = (phoneNumber: string): Observable<void> =>
    of(phoneNumber)
      .pipe(
        map(number => number.trim()),
        flatMap(number => {
          if (number === "" || number.match(Constants.phoneNumberRegex)) {
            return of(number);
          } else {
            return throwError(new InvalidPhoneNumberError());
          }
        }),
        flatMap(number => zip(of(number), this.getUserUseCase.execute().pipe(take(1)))),
        flatMap(([number, user]) => this.userRepository.updatePhoneNumber(user.identifier, number))
      );
}