import UserRepository from "./UserRepository";

export default class IsUserLoggedUseCase {

  private userRepo: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepo = userRepository;
  }

  execute = () => this.userRepo.isUserLogged();
};