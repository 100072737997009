import { Box, Button, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { DayState, dayStateNotAvailable } from '../../../Domain/Bookings/DayState';
import { dateTruncatingTime } from '../../../Utils/DateUtils';
import Select from '../../Commons/Select';
import strings from '../../Utils/LocalizedStrings';
import ReactDayPickerLocaleUtils from '../../Utils/ReactDayPickerLocaleUtils';
import './calendarCustomStyle.css';
import { Hour, SelectableHour } from './UserBookingPresenter';

interface UserBookingViewProps {
  readonly date?: Date;
  readonly daysAndState: Map<number, DayState> | null;
  readonly availableHours: SelectableHour[];
  readonly isBookButtonEnabled: boolean;
  readonly isLoadingAvailableDays: boolean;
  readonly isLoadingAvailableHours: boolean;

  readonly onDateSelected: (date: Date) => void;
  readonly onHourSelected: (hour: Hour) => void;
  readonly onMonthDisplayed: (month: Date) => void;
  readonly onSubmitButtonClicked: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(2),
  },
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  supportsOverlay: {
    position: "relative",
  },
  transparent: {
    opacity: 0.25,
  },
  fullWidth: {
    flexGrow: 1,
  },
  overlayProgress: {
    position: "absolute",
    top: "40%",
    left: "15%",
    padding: "4px"
  },
  progress: {
    marginLeft: theme.spacing(),
    padding: "4px"
  }
}));

const UserBookingScreen = (props: UserBookingViewProps) => {
  const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
  const classes = useStyles();

  const onDayClicked = (day: Date) => {
    const date = dateTruncatingTime(day);
    if (props.daysAndState && props.daysAndState.get(date.getTime()) !== dayStateNotAvailable) {
      props.onDateSelected(date);
    }
  }

  const str = strings.user.booking;
  return (
    <Grid container>
      <Grid item xs={12} sm={10} md={6} lg={4}>
        <Box className={classes.root}>

          {/* Top text */}
          <span>{str.descriptionTop}</span>
          <span>{str.descriptionBottom}</span>
          <br />

          {/* Date picker */}
          <Box className={classes.row}>
            <Box className={classes.supportsOverlay}>
              <DayPicker
                className={
                  props.isLoadingAvailableDays
                    ? `${classes.transparent}`
                    : undefined
                }
                initialMonth={currentMonth}
                localeUtils={ReactDayPickerLocaleUtils}
                locale={navigator.language?.indexOf("it") === -1 ? "en" : "it"}
                selectedDays={props.date}
                onDayClick={onDayClicked}
                enableOutsideDaysClick
                onMonthChange={(month: Date) => {
                  if (month.getTime() !== currentMonth.getTime()) {
                    setCurrentMonth(month);
                  }
                  props.onMonthDisplayed(month)
                }}
                fromMonth={new Date()}
                disabledDays={(props.daysAndState && props.daysAndState.size > 0)
                  ? Array.from(props.daysAndState.entries())
                    .filter(([_, state]) => state === dayStateNotAvailable)
                    .map(([date, _]) => new Date(date))
                  : () => true
                }
                renderDay={renderDay()}
              />
              {props.isLoadingAvailableDays
                ? <Box
                  className={`${classes.column} ${classes.overlayProgress}`}>
                  <CircularProgress />
                  <span>{strings.user.booking.availableDaysLoading}</span>
                </Box>
                : null}
            </Box>
          </Box>

          {/* Hour picker */}
          <Box className={classes.row}>
            <Select
              label={str.selectTimeSlot}
              className={classes.fullWidth}
              entries={props.availableHours.map((hour) => ({ key: hour.timeSlotId, value: hour.label }))}
              enabled={props.availableHours.length > 0}
              value={props.availableHours.find((hour) => hour.isSelected)?.timeSlotId ?? ""}
              onSelect={selectedKey => {
                const index = props.availableHours.findIndex((hour) => hour.timeSlotId === selectedKey);
                if (index >= 0) {
                  props.onHourSelected(props.availableHours[index]);
                }
              }} />
            {props.isLoadingAvailableHours ? <CircularProgress className={classes.progress} /> : null}
          </Box>

          {/* Confirm button */}
          <Button
            className={classes.fullWidth}
            color="primary"
            variant="contained"
            disabled={!props.isBookButtonEnabled}
            onClick={() => props.onSubmitButtonClicked()}
            disableElevation>{str.book}</Button>
        </Box>
      </Grid>
    </Grid>
  )
};

const renderDay = () => ((day: Date) => {
  const date = dateTruncatingTime(day);
  return <span>{date.getDate()}</span>;
});

export default UserBookingScreen;

