import React, { useEffect, useState } from 'react';
import { DayState } from '../../../Domain/Bookings/DayState';
import KeyValueDialog from '../../Commons/KeyValueDialog';
import LoadingDialog from '../../Commons/LoadingDialog';
import SimpleDialog from '../../Commons/SimpleDialog';
import strings from '../../Utils/LocalizedStrings';
import UserBookingPresenter, { Hour, SelectableHour } from './UserBookingPresenter';
import UserBookingScreen from './UserBookingScreen';
import UserBookingView from './UserBookingView';

interface UserBookingViewControllerProps {
  readonly presenter: UserBookingPresenter;
  readonly onBookingCompleted: () => void;
  readonly onBookingFailed: () => void;
}

interface UserBookingState {
  readonly selectedDate?: Date;
  readonly availableHours: SelectableHour[];
  readonly availableDaysOfMonth: Map<number, DayState>;
  readonly isLoadingAvailableDays: boolean;
  readonly isLoadingAvailableHours: boolean;
  readonly isSubmitEnabled: boolean;
  readonly confirmationData?: [string, string];
  readonly isLoading: boolean;
  readonly isBookingAvailable: boolean | null;
  readonly bookingFailedMessage: string | null;
}

const UserBookingViewController = (props: UserBookingViewControllerProps) => {
  const [presenter] = useState(props.presenter);
  const [state, setState] = useState<UserBookingState>({
    selectedDate: undefined,
    availableHours: [],
    availableDaysOfMonth: new Map(),
    isLoadingAvailableDays: false,
    isLoadingAvailableHours: false,
    isSubmitEnabled: false,
    confirmationData: undefined,
    isLoading: false,
    isBookingAvailable: null,
    bookingFailedMessage: null
  });

  const [view] = useState<UserBookingView>({
    showAvailableHours: (hours) => setState(state => ({ ...state, availableHours: hours })),
    showAvailableDaysOfMonth: (states) => setState(state => ({ ...state, selectedDate: undefined, availableDaysOfMonth: states })),
    showAvailableDaysLoading: () => setState(state => ({ ...state, isLoadingAvailableDays: true })),
    hideAvailableDaysLoading: () => setState(state => ({ ...state, isLoadingAvailableDays: false })),
    showAvailableHoursLoading: () => setState(state => ({ ...state, isLoadingAvailableHours: true })),
    hideAvailableHoursLoading: () => setState(state => ({ ...state, isLoadingAvailableHours: false })),
    enableBookingSubmit: () => setState(state => ({ ...state, isSubmitEnabled: true })),
    disableBookingSubmit: () => setState(state => ({ ...state, isSubmitEnabled: false })),
    showConfirmationDialog: (date, hour) => openConfirmationDialog(date, hour),
    showBookingLoading: () => setState(state => ({ ...state, isLoading: true })),
    hideBookingLoading: () => setState(state => ({ ...state, isLoading: false })),
    showBookingAvailableState: () => setState(state => ({ ...state, isBookingAvailable: true })),
    showBookingUnavailableState: () => setState(state => ({ ...state, isBookingAvailable: false })),
    showTimeSlotsUnavailableMessage: () => setState(state => ({ ...state, bookingFailedMessage: strings.user.booking.bookingFailedTimeSlotsUnavailableMessage })),
    showBookingCompletedMessage: () => props.onBookingCompleted(),
    showBookingFailedMessage: () => setState(state => ({ ...state, bookingFailedMessage: strings.user.booking.bookingFailedGenericMessage })),
  });

  const handleDateSelected = (date: Date) => {
    setState(state => ({ ...state, selectedDate: date }));
    presenter.setDate(date);
  };

  const handleHourSelected = (hour: Hour) => {
    presenter.setHour(hour);
  };

  const openConfirmationDialog = (date: string, hour: string) =>
    setState(state => ({ ...state, confirmationData: [date, hour] }));

  const closeConfirmationDialog = () =>
    setState(state => ({ ...state, confirmationData: undefined }));

  const closeErrorDialog = () =>
    setState(state => ({ ...state, bookingFailedMessage: null }));

  useEffect(() => {
    presenter.attachView(view);
    return () => presenter.detachView();
  }, [presenter, view]);

  const str = strings.user.booking;
  return (
    <div>
      {
        state.isBookingAvailable === null
          ? <div />
          : state.isBookingAvailable
            ? <UserBookingScreen
              date={state.selectedDate}
              daysAndState={state.isLoadingAvailableDays ? null : state.availableDaysOfMonth}
              availableHours={state.availableHours}
              isBookButtonEnabled={state.isSubmitEnabled}
              onDateSelected={handleDateSelected}
              onHourSelected={handleHourSelected}
              onMonthDisplayed={presenter.loadMonthAppointments}
              onSubmitButtonClicked={presenter.bookAppointment}
              isLoadingAvailableDays={state.isLoadingAvailableDays}
              isLoadingAvailableHours={state.isLoadingAvailableHours}
            />
            : <span>{str.errorNoMoreBookings}</span>
      }

      {/* Confirmation dialog */}
      {state.confirmationData ?
        <KeyValueDialog
          isVisible
          title={str.confirmationTitle}
          content={(() => {
            const [date, hour] = state.confirmationData;
            return [
              { key: str.date, value: date },
              { key: str.timeSlot, value: hour }
            ]
          })()
          }
          confirmButtonText={str.book}
          onConfirmButtonClicked={() => {
            closeConfirmationDialog();
            presenter.confirmBooking();
          }}
          onCancelButtonClicked={closeConfirmationDialog} />
        : <div />
      }

      {/* Error dialog */}
      {state.bookingFailedMessage ?
        <SimpleDialog
          isVisible
          title={str.bookingFailed}
          content={state.bookingFailedMessage}
          onConfirmButtonClicked={() => {
            closeErrorDialog();
            props.onBookingFailed();
          }}
          hideCancelButton
        />
        : null
      }

      {/* Loading dialog */}
      {state.isLoading ? <LoadingDialog /> : <div />}
    </div>
  );
}

export default UserBookingViewController;

