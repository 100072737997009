import { NEVER, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, map, mapTo, scan, shareReplay, skip, startWith, switchMap, take } from 'rxjs/operators';
import AppointmentDuration from '../../../Domain/Settings/AppointmentDuration';
import { BusinessHoursSlot, Hour, hourToComparable } from '../../../Domain/Settings/BusinessDay';
import CreateBusinessDayScheduleUseCase from '../../../Domain/Settings/CreateBusinessDayScheduleUseCase';
import DeleteBusinessDayScheduleUseCase from '../../../Domain/Settings/DeleteBusinessDayScheduleUseCase';
import GetAppointmentDurationUseCase from '../../../Domain/Settings/GetAppointmentDurationUseCase';
import GetAvailableAppointmentDurationsUseCase from '../../../Domain/Settings/GetAvailableAppointmentDurationsUseCase';
import GetBusinessDaysUseCase, { BusinessDaysSchedule } from '../../../Domain/Settings/GetBusinessDaysUseCase';
import SaveAppointmentDurationUseCase from '../../../Domain/Settings/SaveAppointmentDurationUseCase';
import SaveBusinessDaysUseCase from '../../../Domain/Settings/SaveBusinessDaysUseCase';
import UpdateBusinessDayScheduleUseCase from '../../../Domain/Settings/UpdateBusinessDayScheduleUseCase';
import UpdateUserPasswordUseCase, { PasswordTooShortError } from '../../../Domain/UserAuth/UpdateUserPasswordUseCase';
import Logger from '../../../Logger/Logger';
import { weekdaysShort } from '../../../Utils/DateUtils';
import { Failure, Loading, Success } from '../../../Utils/Result';
import strings from '../../Utils/LocalizedStrings';
import SettingsView from './SettingsView';
import { maxBy } from "../../../Utils/ArrayUtils";
import { Constants } from "../../../Utils/Constants";

const defaultStartHour: Hour = { hour: 9, minute: 0 };

const defaultBusinessHourSlots = [
  { start: defaultStartHour, end: { hour: 10, minute: 0 }, customersLimit: Constants.maxBookingsPerSlot },
]

export default class SettingsPresenter {

  private readonly subscription = new Subscription();

  private readonly appointmentDuration: Observable<AppointmentDuration>;
  private readonly availableAppointmentDurations: Observable<AppointmentDuration[]>;
  private readonly businessDaysSchedules: Observable<BusinessDaysSchedule[]>;
  private readonly selectedSchedule: Observable<BusinessDaysSchedule>;

  private readonly displayedScheduleChanges = new Subject<string | null>();
  private readonly businessDaysChanges = new Subject<UiBusinessDay>()
  private readonly appointmentDurationChanges = new Subject<string>();

  private passwordEditingState: PasswordEditingState = {
    password: "",
    passwordConfirmation: "",
    passwordError: null,
  };

  private view?: SettingsView;

  constructor(
    getAppointmentDurationUseCase: GetAppointmentDurationUseCase,
    getAvailableAppointmentDurationsUseCase: GetAvailableAppointmentDurationsUseCase,
    getBusinessDaysUseCase: GetBusinessDaysUseCase,
    private readonly createBusinessDaysScheduleUseCaseFactory: () => CreateBusinessDayScheduleUseCase,
    private readonly updateBusinessDayScheduleUseCaseFactory: () => UpdateBusinessDayScheduleUseCase,
    private readonly deleteBusinessDayScheduleUseCaseFactory: () => DeleteBusinessDayScheduleUseCase,
    private readonly saveBusinessDaysUseCaseFactory: () => SaveBusinessDaysUseCase,
    private readonly saveAppointmentDurationUseCaseFactory: () => SaveAppointmentDurationUseCase,
    private readonly updatePasswordUseCaseFactory: () => UpdateUserPasswordUseCase,
  ) {
    this.appointmentDuration = getAppointmentDurationUseCase.execute().pipe(shareReplay(1));
    this.availableAppointmentDurations = getAvailableAppointmentDurationsUseCase.execute().pipe(shareReplay(1));
    this.businessDaysSchedules = getBusinessDaysUseCase.execute().pipe(shareReplay(1));
    this.selectedSchedule = this.displayedScheduleChanges
      .pipe(
        startWith(null),
        switchMap(scheduleId => {
          if (scheduleId) {
            return this.businessDaysSchedules.pipe(
              map(schedules => schedules.find(schedule => schedule.identifier === scheduleId)),
            )
          } else {
            return this.businessDaysSchedules.pipe(
              map(schedules => {
                const activeSchedule = schedules.find(schedule => schedule.isActive);
                if (activeSchedule) {
                  return activeSchedule;
                } else if (schedules.length > 0) {
                  return schedules[0];
                } else {
                  return null;
                }
              })
            );
          }
        }),
        distinctUntilChanged((lhs, rhs) => lhs?.identifier === rhs?.identifier),
        switchMap(schedule => (schedule && of(schedule)) || NEVER),
        shareReplay(1),
      );
  }

  attachView = (v: SettingsView) => {
    this.view = v;

    this.subscription.add(
      this.businessDaysSchedules
        .pipe(
          switchMap((schedules): Observable<[BusinessDaysSchedule, BusinessDaysSchedule[]]> => {

            return this.selectedSchedule.pipe(map(schedule => [schedule, schedules]));
          }),
          map(([selectedSchedule, schedules]): UiSchedule[] => schedules.map(schedule => ({
            ...schedule,
            isSelected: selectedSchedule.identifier === schedule.identifier
          }))),
          distinctUntilChanged(),
        )
        .subscribe(
          schedules => {
            this.view?.showSchedules(schedules)
          },
          Logger.e
        )
    );

    const displayableBusinessDays = this.selectedSchedule
      .pipe(
        map(schedule => schedule.businessDays),
        map((businessDays): UiBusinessDay[] =>
          Array
            .from(Array(7).keys())
            // Since 0 is Sunday, we should go from 1 to 7 and then back to 0.
            .map(day => (day + 1) % 7)
            .map(dayOfWeek => {
              const businessDay = businessDays.find(day => day.dayOfWeek === dayOfWeek);
              return {
                name: weekdaysShort()[dayOfWeek],
                dayOfWeek: dayOfWeek,
                isSelected: businessDay !== undefined,
                hours: businessDay?.businessHourSlots ?? defaultBusinessHourSlots,
              };
            })
        ),
      );

    const displayedBusinessDays: Observable<UiBusinessDay[]> = displayableBusinessDays
      .pipe(
        switchMap(businessDays =>
          this.businessDaysChanges
            .pipe(
              startWith(null),
              scan<UiBusinessDay | null, UiBusinessDay[]>((previousBusinessDays, changedDay) => {
                return previousBusinessDays.map(day =>
                  day.name === changedDay?.name ? changedDay : day
                )
              }, businessDays),
            )
        ),
        shareReplay(1),
      );

    this.subscription.add(
      displayedBusinessDays
        .subscribe(
          days => this.onBusinessDays(days),
          error => Logger.e(error)
        )
    );

    // Appointment durations

    const displayableAppointmentDurations = this.availableAppointmentDurations
      .pipe(
        switchMap((durations): Observable<UiAppointmentDuration[]> =>
          this.appointmentDuration
            .pipe(
              map(currentDuration =>
                durations.map((duration): UiAppointmentDuration => ({
                  identifier: duration.identifier,
                  description: this.formatDuration(duration.minutes),
                  isSelected: duration.identifier === currentDuration.identifier
                }))
              )
            )
        )
      );

    const updatingAppointmentDurations = displayableAppointmentDurations
      .pipe(
        switchMap(durations =>
          this.appointmentDurationChanges
            .pipe(
              scan<string, UiAppointmentDuration[]>((previousDurations, newDurationId) => {
                return previousDurations.map(duration =>
                  ({ ...duration, isSelected: duration.identifier === newDurationId })
                )
              }, durations),
              startWith(durations),
            )
        ),
        shareReplay(1),
      );

    this.subscription.add(
      updatingAppointmentDurations
        .subscribe(
          this.onAppointmentDurations,
          Logger.e
        )
    );

    // Save business days

    this.subscription.add(
      displayedBusinessDays
        .pipe(
          skip(1),
          map(days => days.filter(day => day.isSelected)),
          switchMap(days =>
            this.selectedSchedule.pipe(take(1), switchMap(schedule =>
              this.saveBusinessDaysUseCaseFactory().execute(
                schedule.identifier,
                days.map(day => ({
                  dayOfWeek: day.dayOfWeek,
                  businessHourSlots: day.hours
                }))
              )
            ))
          )
        )
        .subscribe(
          result => {
          },
          Logger.e
        )
    );

    // Save appointment durations

    this.subscription.add(
      this.appointmentDurationChanges
        .pipe(switchMap(durations => this.saveAppointmentDurationUseCaseFactory().execute(durations)))
        .subscribe(
          result => {
          },
          Logger.e
        )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  // Schedules

  createNewSchedule = () => {
    this.subscription.add(
      this.selectedSchedule
        .pipe(
          take(1),
          switchMap(schedule =>
            this.createBusinessDaysScheduleUseCaseFactory().execute(
              schedule.identifier,
              strings.admin.settings.newHours
            )
          )
        )
        .subscribe(
          schedule => {
            this.selectSchedule(schedule.identifier);
          },
          Logger.e
        )
    );
  }

  deleteSchedule = () => {
    this.subscription.add(
      this.selectedSchedule
        .pipe(
          take(1),
          switchMap(({ identifier }) => this.deleteBusinessDayScheduleUseCaseFactory().execute(identifier)),
        )
        .subscribe(
          () => {
            // Once deleted, reset the currently displayed schedule.
            this.displayedScheduleChanges.next(null);
          },
          Logger.e
        )
    )
  }

  selectSchedule = (scheduleId: string) => {
    this.displayedScheduleChanges.next(scheduleId);
  }

  saveScheduleDetails = (description: string | null, startDate: Date | null, endDate: Date | null) => {
    if (description && startDate && endDate) {
      this.selectedSchedule
        .pipe(
          take(1),
          switchMap(schedule => {
            return this.updateBusinessDayScheduleUseCaseFactory()
              .execute(schedule.identifier, description, startDate, endDate);
          })
        )
        .subscribe(() => {
        }, Logger.e);
    } else {
      Logger.w(`Received invalid parameters ${description} ${startDate} ${endDate}`)
    }
  }

  // Business days management

  businessDaySelectionChanged = (businessDay: UiBusinessDay, isSelected: boolean) => {
    this.businessDaysChanges.next({
      ...businessDay,
      isSelected: isSelected
    });
  }

  businessDayStartSlotChanged = (businessDay: UiBusinessDay, slotIndex: number, newStart: Date) => {
    this.businessDaysChanges.next({
      ...businessDay,
      hours: businessDay.hours.map((oldSlot, index) =>
        index === slotIndex
          ? {
            ...oldSlot,
            start: {
              hour: newStart.getHours(),
              minute: newStart.getMinutes()
            }
          }
          : oldSlot
      )
    });
  }

  businessDayEndSlotChanged = (businessDay: UiBusinessDay, slotIndex: number, newStart: Date) => {
    this.businessDaysChanges.next({
      ...businessDay,
      hours: businessDay.hours.map((oldSlot, index) =>
        index === slotIndex
          ? {
            ...oldSlot,
            end: {
              hour: newStart.getHours(),
              minute: newStart.getMinutes()
            }
          }
          : oldSlot
      )
    });
  }

  businessDayCustomersLimitSlotChanged = (businessDay: UiBusinessDay, slotIndex: number, newLimit: number) => {
    console.log(this.businessDaysChanges)
    this.businessDaysChanges.next({
      ...businessDay,
      hours: businessDay.hours.map((oldSlot, index) =>
        index === slotIndex
          ? {
            ...oldSlot,
            customersLimit: newLimit
          }
          : oldSlot
      )
    });
  }

  businessDaySlotRemoved = (businessDay: UiBusinessDay, slotIndex: number) => {
    this.businessDaysChanges.next({
      ...businessDay,
      hours: businessDay.hours
        .map((hour, index) => index === slotIndex ? null : hour)
        .filter(hour => hour !== null)
        .map(hour => hour as BusinessHoursSlot)
    });
  }

  businessDaySlotAdded = (businessDay: UiBusinessDay) => {
    const slotsInterval: Hour = this.calculateSlotsInterval(businessDay.hours);
    const lastHour = maxBy(businessDay.hours, ({ end }) => hourToComparable(end))?.end;
    const nextSlotStartHour: Hour = lastHour ?? defaultStartHour;
    const nextSlotEndHour: Hour = {
      hour: nextSlotStartHour.hour + slotsInterval.hour,
      minute: nextSlotStartHour.minute + slotsInterval.minute
    }
    const nextSlotCustomerLimit = this.getLastCustomerLimit(businessDay.hours);
    this.businessDaysChanges.next({
      ...businessDay,
      hours: businessDay.hours.concat([
        { start: nextSlotStartHour, end: nextSlotEndHour, customersLimit: nextSlotCustomerLimit }
      ])
    });
  }

  private calculateSlotsInterval(
    businessHoursSlots: BusinessHoursSlot[],
    defaultInterval: Hour = { hour: 1, minute: 0 }
  ): Hour {
    const hoursCount = businessHoursSlots.length
    return hoursCount >= 2
      ? this.calculateHoursInterval(businessHoursSlots[hoursCount - 2].end, businessHoursSlots[hoursCount - 1].end)
      : defaultInterval;
  }

  private getLastCustomerLimit(
    businessHoursSlots: BusinessHoursSlot[],
  ) {
    return businessHoursSlots[businessHoursSlots.length - 1].customersLimit;
  }

  private calculateHoursInterval(firstHour: Hour, lastHour: Hour): Hour {
    const hourInterval = lastHour.hour - firstHour.hour;
    const minuteInterval = lastHour.minute - firstHour.minute;
    return { hour: hourInterval, minute: minuteInterval };
  }

  // Appointments duration management

  userChangedDuration = (durationId: string) => {
    this.appointmentDurationChanges.next(durationId);
  }

  // Password management

  passwordChanged = (password: string) => {
    if (this.passwordEditingState.password === password) return;
    this.passwordEditingState = {
      ...this.passwordEditingState,
      password: password,
      passwordError: null,
    };
    this.view?.showPasswordData(this.passwordEditingState);
    this.checkUpdateButtonEnabledState();
  }

  passwordConfirmationChanged = (password: string) => {
    if (this.passwordEditingState.passwordConfirmation === password) return;
    this.passwordEditingState = {
      ...this.passwordEditingState,
      passwordConfirmation: password,
      passwordError: null,
    };
    this.view?.showPasswordData(this.passwordEditingState);
    this.checkUpdateButtonEnabledState();
  }

  updatePassword = () => {
    const { password, passwordConfirmation } = this.passwordEditingState;
    if (password !== passwordConfirmation) {
      this.passwordEditingState = {
        ...this.passwordEditingState,
        passwordError: strings.passwordChange.errorPasswordMismatch,
      };
      this.view?.showPasswordData(this.passwordEditingState);
    } else {
      this.subscription.add(
        this.updatePasswordUseCaseFactory()
          .execute(this.passwordEditingState.password)
          .pipe(
            mapTo(Success({})),
            startWith(Loading()),
            catchError(error => of(Failure(error)))
          )
          .subscribe(
            result => {
              if (result.isLoading) {
                this.view?.showPasswordLoading();
              } else {
                this.view?.hidePasswordLoading();
                result.fold(
                  () => {
                    this.passwordEditingState = {
                      password: "",
                      passwordConfirmation: "",
                      passwordError: null
                    };
                  },
                  error => {
                    if (error instanceof PasswordTooShortError) {
                      this.passwordEditingState = {
                        ...this.passwordEditingState,
                        passwordError: strings.formatString(
                          strings.passwordChange.errorPasswordLength,
                          error.minLength
                        ).toString()
                      };
                    } else {
                      Logger.e(error);
                    }
                  }
                );
                this.view?.showPasswordData(this.passwordEditingState);
              }
            },
            Logger.e
          )
      );
    }
  }

  // Private methods

  private onBusinessDays = (businessDays: UiBusinessDay[]) => {
    this.view?.showBusinessDays(businessDays);
  }

  private onAppointmentDurations = (durations: UiAppointmentDuration[]) => {
    this.view?.showAppointmentDurations(durations);
  }

  private checkUpdateButtonEnabledState = () => {
    const canBeEnabled = this.passwordEditingState.password.length > 0
      && this.passwordEditingState.passwordConfirmation.length > 0
      && this.passwordEditingState.password === this.passwordEditingState.passwordConfirmation;

    if (canBeEnabled) {
      this.view?.enablePasswordUpdateButton();
    } else {
      this.view?.disablePasswordUpdateButton();
    }
  }

  private formatDuration = (durationMinutes: number): string => {
    const hours = durationMinutes < 60 ? 0 : Math.round(durationMinutes / 60);
    const minutes = Math.round(durationMinutes % 60);
    const minutesString = minutes > 10 ? `${minutes}` : `0${minutes}`;
    return `${hours}:${minutesString}`;
  }

};

export interface UiSchedule {
  readonly identifier: string;
  readonly description: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly isSelected: boolean;
}

export interface UiBusinessDay {
  readonly name: string;
  readonly dayOfWeek: number;
  readonly isSelected: boolean;
  readonly hours: BusinessHoursSlot[];
}

export interface UiAppointmentDuration {
  readonly identifier: string;
  readonly description: string;
  readonly isSelected: boolean;
}

export interface PasswordEditingState {
  readonly password: string;
  readonly passwordConfirmation: string;
  readonly passwordError: string | null;
}