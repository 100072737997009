import CustomersRepository from "./CustomersRepository";
import { Observable } from "rxjs";
import Customer from "../Entities/Customer";

export default class CreateCustomerUseCase {

  constructor(
    private readonly customersRepository: CustomersRepository
  ) {
  }

  execute = (
    name: string,
    surname: string,
    email: string,
    phoneNumber: string | null
  ): Observable<Customer> => this.customersRepository.createCustomer(name, surname, email, phoneNumber)
}