import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import DateTimeProvider from '../../Utils/DateTimeProvider';
import SimpleBusinessDaysSchedule from './BusinessDay';
import SettingsRepository from './SettingsRepository';

interface Activeable {
  readonly isActive: boolean;
}

export type BusinessDaysSchedule = SimpleBusinessDaysSchedule & Activeable;

/**
 * Returns the business days that have been set by the user.
 * The array length will be equals at most of the numbers of days in a week.
 */
export default class GetBusinessDaysUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
    private readonly dateTimeProvider: DateTimeProvider,
  ) { }

  execute = (): Observable<BusinessDaysSchedule[]> => this.settingsRepository
    .getBusinessDaysSchedules()
    .pipe(
      map(schedules => {
        const currentDate = this.dateTimeProvider.currentTimeMillis();
        return schedules
          .map(schedule => ({
            ...schedule,
            isActive: currentDate >= schedule.startDate.valueOf() && currentDate <= schedule.endDate.valueOf()
          }))
          .sort((lhs, rhs) => lhs.startDate.valueOf() - rhs.startDate.valueOf());
      })
    );
}