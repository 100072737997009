import { Box, Button, CircularProgress, createStyles, FormControl, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import { AccountCircle, EmailOutlined } from '@material-ui/icons';
import React from 'react';
import PasswordChangeForm from '../../Commons/PasswordChangeForm';
import { Constants } from '../../../Utils/Constants';
import strings from '../../Utils/LocalizedStrings';

interface UserProfileViewProps {
  readonly displayName: string;
  readonly email: string;
  readonly phoneNumber: string | null;
  readonly password: string | null;
  readonly passwordConfirmation: string | null;
  readonly phoneNumberError: string | null;
  readonly passwordError: string | null;
  readonly enableSaveButton: boolean;
  readonly showLoading: boolean;

  readonly onPhoneNumberChanged: (number: string) => void;
  readonly onPasswordChanged: (password: string) => void;
  readonly onPasswordConfirmationChanged: (password: string) => void;
  readonly onSavePressed: () => void;
  readonly onResetPressed: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  columnContainer: {
    display: "flex",
    flexDirection: "column"
  },
  rowContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  title: {
    ...theme.typography.h4
  },
  simpleText: {
    ...theme.typography.body2,
    color: theme.palette.grey[600]
  },
  startPadding: {
    marginLeft: theme.spacing(2)
  },
  topPadding: {
    paddingTop: theme.spacing(2)
  },
  bottomPadding: {
    paddingBottom: theme.spacing(2)
  },
  userInfoText: {
    ...theme.typography.body1,
    fontSize: "18px",
  },
  textField: {
    width: 300
  },
  link: {
    ...theme.typography.body2,
    color: theme.palette.grey[600],
    fontWeight: "bold",
  }
}));

const UserProfileScreen = (props: UserProfileViewProps) => {
  // const { } = props;

  const classes = useStyles();

  const str = strings.user.profile;
  return (
    <Box className={classes.columnContainer}>
      <span className={classes.title}>{str.title}</span>
      <span className={classes.simpleText}>
        {str.subtitle}
      </span>
      <br />
      <Box className={classes.rowContainer}>
        <AccountCircle />
        <span className={`${classes.startPadding} ${classes.userInfoText}`}>{props.displayName}</span>
      </Box>
      <Box className={`${classes.rowContainer} ${classes.bottomPadding}`}>
        <EmailOutlined />
        <span className={`${classes.startPadding} ${classes.userInfoText}`}>{props.email}</span>
      </Box>
      <span className={classes.simpleText}>
        {str.notifyVariation}&nbsp;
        <a className={classes.link} href={`mailto:${Constants.resetPasswordEmailAddress}`}>
          {str.clickHere}
        </a>.
      </span>
      <br />
      <br />
      <FormControl
        variant="outlined"
        error={props.phoneNumberError ? true : false}
        className={classes.bottomPadding}>
        <TextField
          className={classes.textField}
          disabled={props.showLoading}
          variant="outlined"
          label={str.phoneNumber}
          type="phone"
          value={props.phoneNumber ?? ""}
          onChange={e => props.onPhoneNumberChanged(e.target?.value ?? "")}
        />
        <FormHelperText id="phonenumber-error-field">{props.phoneNumberError ?? ""}</FormHelperText>
      </FormControl>
      <br />
      <PasswordChangeForm
        password={props.password ?? ""}
        passwordConfirmation={props.passwordConfirmation ?? ""}
        passwordError={props.passwordError ?? ""}
        onPasswordChanged={props.onPasswordChanged}
        onPasswordConfirmationChanged={props.onPasswordConfirmationChanged}
      />
      {
        props.showLoading
          ? <CircularProgress />
          : <Box className={`${classes.rowContainer} ${classes.topPadding}`}>
            <Button
              disabled={!props.enableSaveButton}
              variant="contained"
              disableElevation
              color="primary"
              onClick={() => props.onSavePressed()}>{strings.commons.save}</Button>
            <Button
              className={`${classes.startPadding}`}
              variant="text"
              color="primary"
              onClick={() => props.onResetPressed()}>{str.cancelModifications}</Button>
          </Box>
      }
    </Box>
  )
};

export default UserProfileScreen;