import React, { useEffect, useState } from 'react';
import { of } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import IsUserLoggedUseCase from '../../Domain/IsUserLoggedUseCase';
import User from '../../Domain/User';
import GetUserUseCase from '../../Domain/UserAuth/GetUserUseCase';
import Logger from '../../Logger/Logger';

interface LoginMiddlewareProps {
  readonly isUserLogged: IsUserLoggedUseCase;
  readonly getUser: GetUserUseCase;

  /** Returns the ReactElement that is rendered if the user is logged. */
  readonly renderIfLogged?: (user: User) => React.ReactElement;

  /** Performs a side effect when the user is logged. */
  readonly onUserLogged?: (user: User) => void;

  /** Returns the ReactElement that is rendered if the user is not logged. */
  readonly renderIfNotLogged?: () => React.ReactElement;

  /** Returns the ReactElement that is rendered if an error occurs. */
  readonly renderOnError?: (error: string) => React.ReactElement;

  /** Performs a side effect when the user is not logged. */
  readonly onUserNotLogged?: () => void;
}

/**
 * Component that acts as a middleware for checking 
 * if the user is currently logged into the app.
 * @param props 
 */
const LoginMiddleware: (props: LoginMiddlewareProps) => React.ReactElement | null = (props: LoginMiddlewareProps) => {
  const [user, setUser] = useState<User | null>();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    const subscription = props.isUserLogged
      .execute()
      .pipe(
        take(1),
        switchMap(isLogged => {
          if (isLogged) {
            return props.getUser.execute();
          } else {
            return of(null);
          }
        }))
      .subscribe(
        user => {
          setUser(user);
          if (user == null) {
            props.onUserNotLogged && props.onUserNotLogged();
          } else {
            props.onUserLogged && props.onUserLogged(user);
          }
        },
        error => {
          if (error.message) {
            setErrorMessage(error.message);
          }
          Logger.e(error);
        }
      );
    return () => subscription.unsubscribe();
  }, [props]);

  if (errorMessage) {
    return props.renderOnError?.(errorMessage) ?? <div />;
  } else if (user === undefined) {
    return null;
  } else if (user === null) {
    return (props.renderIfNotLogged && props.renderIfNotLogged()) || <div />;
  } else {
    return (props.renderIfLogged && props.renderIfLogged(user)) || <div />;
  }
};

export default LoginMiddleware;