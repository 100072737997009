import UserRepository from './UserRepository';

export default class LogoutUseCase {
  
  private readonly userRepository: UserRepository;
  
  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  execute = () => this.userRepository.logout();
}