import { eachMonthOfInterval, endOfYear, formatISO, parse, parseISO, startOfYear } from "date-fns";
import { LocaleUtils } from "react-day-picker/types/LocaleUtils";
import { format, locale, weekdaysLong, weekdaysShort } from "../../Utils/DateUtils";

type MonthNames = [
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string,
  string
];

const ReactDayPickerLocaleUtils: LocaleUtils = {
  formatDate(date: Date, stringOrArray?: string | string[], _locale?: string): string {
    const pattern: string | undefined = stringOrArray && (
      (typeof (stringOrArray) === "string")
        ? stringOrArray
        : (stringOrArray[0] ?? "")
    );
    if (pattern) {
      return format(date, pattern);
    } else {
      return formatISO(date);
    }
  },
  formatDay(day: Date, _locale?: string): string {
    return format(day, "dd");
  },
  formatMonthTitle(month: Date, _locale?: string): string {
    return format(month, "MMMM yyyy");
  },
  formatWeekdayLong(weekday: number, _locale?: string): string {
    return weekdaysLong()[weekday];
  },
  formatWeekdayShort(weekday: number, _locale?: string): string {
    return weekdaysShort()[weekday];
  },
  getFirstDayOfWeek(_locale?: string): number {
    return locale().options?.weekStartsOn ?? 1;
  },
  getMonths(
    locale?: string
  ): MonthNames {
    return eachMonthOfInterval({ start: startOfYear(Date.now()), end: endOfYear(Date.now()) })
      .map(month => format(month, "MMMM")) as MonthNames;
  },
  parseDate(str: string, format?: string, _locale?: string): Date {
    if (format) {
      return parse(str, format, Date.now(), { locale: locale() });
    } else {
      return parseISO(str);
    }
  }
};

export default ReactDayPickerLocaleUtils;