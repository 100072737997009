import UserRepository from "../UserRepository";

export default class ResetUserPasswordUseCase {

  constructor(
    private readonly userRepository: UserRepository,
  ) {
  }

  execute = (userId: string) => this.userRepository.resetPassword(userId);
}