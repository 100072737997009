import { Cancel, CheckCircle, RemoveCircle } from "@material-ui/icons";
import React from "react";
import { PackageState, packageStatusActive, packageStatusExpired, packageStatusInactive } from "../../../Domain/Entities/Package";
import { getPackageColor } from "./PackagesMappers";

export const getPackageStatusIcon = (status: PackageState) => {
  switch (status) {
    case packageStatusActive: return <CheckCircle htmlColor={getPackageColor(status)} />;
    case packageStatusInactive: return <RemoveCircle htmlColor={getPackageColor(status)} />;
    case packageStatusExpired: return <Cancel htmlColor={getPackageColor(status)} />;
  }
}