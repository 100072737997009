import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../Commons/SimpleDialog';
import strings from '../../Utils/LocalizedStrings';
import CompanyClosuresPresenter from './CompanyClosuresPresenter';
import CompanyClosuresScreen from './CompanyClosuresScreen';
import CompanyClosuresView from './CompanyClosuresView';
import UiCompanyClosure from './UiCompanyClosure';

interface CompanyClosuresViewControllerProps {
  readonly presenter: CompanyClosuresPresenter;
}

interface CompanyClosuresState {
  readonly presenter: CompanyClosuresPresenter;
  readonly closurePeriods: UiCompanyClosure[];
  readonly isSaveButtonEnabled: boolean;
  readonly detailDialog: DetailDialogModel | null;
}

interface DetailDialogModel {
  readonly eventId: string;
  readonly text: string;
}

const CompanyClosuresViewController = (props: CompanyClosuresViewControllerProps) => {
  const [state, setState] = useState<CompanyClosuresState>({
    presenter: props.presenter,
    closurePeriods: [],
    isSaveButtonEnabled: false,
    detailDialog: null,
  });
  const { presenter } = state;

  const view: () => CompanyClosuresView = () => ({
    showCompanyClosurePeriods: periods => setState(state => ({ ...state, closurePeriods: periods })),
    enableSaveButton: () => setState(state => ({ ...state, isSaveButtonEnabled: true })),
    disableSaveButton: () => setState(state => ({ ...state, isSaveButtonEnabled: false })),
    showDetailDialog: (eventId: string, text: string) => setState(state => ({
      ...state,
      detailDialog: {
        eventId: eventId,
        text: text
      },
    })),
  });

  const closeDialog = () => setState(state => ({ ...state, detailDialog: null }));
  const onDeleteButtonClicked = () => {
    presenter.deleteEvent(state.detailDialog?.eventId ?? null);
    closeDialog();
  }

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  return (
    <div>
      <CompanyClosuresScreen
        events={state.closurePeriods}
        onEventCreated={event => presenter.eventRangeCreated(event.start, event.end)}
        onEventUpdated={event => presenter.eventRangeUpdated(event.id, event.start, event.end)}
        onEventClicked={event => presenter.eventSelected(event.id)}
      />
      <SimpleDialog
        isVisible={state.detailDialog !== null}
        title={strings.admin.companyClosures.detailTitle}
        content={state.detailDialog?.text}
        negativeButtonText={strings.commons.delete}
        onConfirmButtonClicked={closeDialog}
        onNegativeButtonClicked={onDeleteButtonClicked}
        onCancelButtonClicked={closeDialog}
        showNegativeButton
        hideCancelButton
      />
    </div>
  );
}

export default CompanyClosuresViewController;

