import { combineLatest, empty, merge, Observable, of, ReplaySubject, Subject, Subscription, throwError } from 'rxjs';
import { catchError, distinctUntilChanged, flatMap, map, mapTo, shareReplay, startWith, switchMap, take, toArray } from 'rxjs/operators';
import User from '../../../Domain/User';
import GetUserUseCase from '../../../Domain/UserAuth/GetUserUseCase';
import UpdateUserPasswordUseCase, { PasswordTooShortError } from '../../../Domain/UserAuth/UpdateUserPasswordUseCase';
import UpdateUserPhoneNumberUseCase, { InvalidPhoneNumberError } from '../../../Domain/UserAuth/UpdateUserPhoneNumberUseCase';
import strings from '../../Utils/LocalizedStrings';
import { Failure, Loading, Success } from '../../../Utils/Result';
import UiUserProfile from './UiUserProfile';
import UserProfileView from './UserProfileView';
import Logger from '../../../Logger/Logger';

export interface UserProfileEditingState {
  readonly phoneNumber: string | null;
  readonly password: string | null;
  readonly passwordConfirmation: string | null;
}

const emptyEditingState = {
  phoneNumber: null,
  password: null,
  passwordConfirmation: null
};

class PasswordNotMatchingError extends Error {
}

export default class UserProfilePresenter {

  private readonly subscription = new Subscription();

  private readonly user: Observable<User>;
  private readonly emptyEditingState: Observable<UserProfileEditingState>;
  private readonly editingStateChanges = new ReplaySubject<UserProfileEditingState>(1);
  private readonly reloadSubject = new Subject();

  private readonly updateUserPhoneNumberFactory: () => UpdateUserPhoneNumberUseCase;
  private readonly updateUserPasswordFactory: () => UpdateUserPasswordUseCase;

  private view?: UserProfileView;

  constructor(
    getUser: GetUserUseCase,
    updateUserPhoneNumberFactory: () => UpdateUserPhoneNumberUseCase,
    updateUserPasswordFactory: () => UpdateUserPasswordUseCase,
  ) {
    this.updateUserPhoneNumberFactory = updateUserPhoneNumberFactory;
    this.updateUserPasswordFactory = updateUserPasswordFactory;
    this.user = getUser.execute().pipe(shareReplay({ bufferSize: 1, refCount: false }));
    this.emptyEditingState = this.user.pipe(map(user =>
      ({ ...emptyEditingState, phoneNumber: user.phone })
    ), shareReplay(1));
  }

  attachView = (v: UserProfileView) => {
    this.view = v;

    const reloadingUser = this.reloadSubject.pipe(
      startWith(() => { }),
      switchMap(() => this.user)
    );

    this.subscription.add(
      reloadingUser
        .pipe(
          map<User, UiUserProfile>(user => ({
            displayName: `${user.name} ${user.surname}`,
            email: user.email,
            phoneNumber: user.phone,
          }))
        )
        .subscribe(
          profile => this.view?.showUserProfile(profile),
          error => Logger.e(error)
        )
    );

    this.subscription.add(
      this.emptyEditingState.pipe(take(1)).subscribe(state =>
        this.editingStateChanges.next(state)
      )
    );

    this.subscription.add(
      this.editingStateChanges
        .subscribe(state => {
          this.view?.updateEditingState(state)
        })
    );

    // Determine wether to enable or not the save button.

    this.subscription.add(
      combineLatest(this.editingStateChanges, reloadingUser)
        .pipe(
          map(([state, user]) =>
            state !== emptyEditingState && (
              (state.phoneNumber ?? "") !== (user.phone ?? "")
              || (state.password && state.password.length > 0 && state.password === state.passwordConfirmation)
            )
          ),
        )
        .subscribe(
          enableSaveButton => {
            if (enableSaveButton) {
              this.view?.enableSaveButton();
            } else {
              this.view?.disableSaveButton();
            }
          }
        )
    );

    // Observe editing state changes for removing errors.

    this.subscription.add(
      this.editingStateChanges
        .pipe(map(s => s.phoneNumber), distinctUntilChanged())
        .subscribe(() => this.view?.hidePhoneNumberError())
    );

    this.subscription.add(
      this.editingStateChanges
        .pipe(map(s => `${s.password}${s.passwordConfirmation}`), distinctUntilChanged())
        .subscribe(() => this.view?.hidePasswordError())
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  phoneNumberChanged = (phoneNumber: string) => {
    this.subscription.add(
      this.editingStateChanges.pipe(take(1)).subscribe(prev =>
        this.editingStateChanges.next({
          ...prev,
          phoneNumber: phoneNumber
        })
      )
    );
  }

  passwordChanged = (password: string) => {
    this.subscription.add(
      this.editingStateChanges.pipe(take(1)).subscribe(prev =>
        this.editingStateChanges.next({
          ...prev,
          password: password
        })
      )
    );
  }

  passwordConfirmationChanged = (password: string) => {
    this.subscription.add(
      this.editingStateChanges.pipe(take(1)).subscribe(prev =>
        this.editingStateChanges.next({
          ...prev,
          passwordConfirmation: password
        })
      )
    );
  }

  saveProfileData = () => {
    this.subscription.add(
      combineLatest(this.user, this.editingStateChanges)
        .pipe(
          take(1),
          flatMap(([user, state]) => {
            if (state.password !== state.passwordConfirmation) {
              return throwError(new PasswordNotMatchingError());
            } else {
              const observables: Observable<void>[] = [];
              // Update the phone number if not changed.
              if (user.phone !== state.phoneNumber) {
                observables.push(
                  this.updateUserPhoneNumberFactory().execute(state.phoneNumber ?? "")
                );
              }
              // Update the password if not empty.
              if (state.password) {
                observables.push(
                  this.updateUserPasswordFactory().execute(state.password)
                );
              }

              if (observables.length === 0) {
                return empty();
              } else {
                return merge(...observables).pipe(toArray());
              }
            }
          }),
          mapTo(Success({})),
          startWith(Loading()),
          catchError(error => of(Failure(error)))
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showLoading();
              this.view?.hidePhoneNumberError();
              this.view?.hidePasswordError();
            } else {
              this.view?.hideLoading();
              result.fold(
                () => this.resetProfileEditing(),
                error => this.handleProfileUpdateError(error)
              );
            }
          }
        )
    );
  }

  resetProfileEditing = () => {
    this.subscription.add(
      this.emptyEditingState.subscribe(state => {
        this.editingStateChanges.next(state);
        this.reloadSubject.next({});
      })
    );
  }

  // Private methods

  private handleProfileUpdateError = (error: Error) => {
    if (error instanceof PasswordNotMatchingError) {
      this.view?.showPasswordError(strings.passwordChange.errorPasswordMismatch);
    } else if (error instanceof PasswordTooShortError) {
      this.view?.showPasswordError(
        strings.formatString(
          strings.passwordChange.errorPasswordLength,
          error.minLength
        ).toString()
      );
    } else if (error instanceof InvalidPhoneNumberError) {
      this.view?.showPhoneNumberError(strings.user.profile.errorInvalidPhoneNumber);
    }
  }
};

