/** The role of a simple user of the system. */
export const roleUser = "user";

/** The role of an administrator of the system. */
export const roleAdmin = "admin";

/** Type that defines the user role. */
export type Role = "user" | "admin";

/** Error thrown when a role is not handled by the system. */
export class UnknownRoleError extends Error {
  constructor(role: string) {
    super(`A new role "${role}" has been introduced but not handled.`)
  }
}