import { Observable } from 'rxjs';
import AppointmentDuration from './AppointmentDuration';
import SettingsRepository from './SettingsRepository';

/**
 * Returns the currently selected duration of appointments.
 */
export default class GetAppointmentDurationUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
  ) { }

  execute = (): Observable<AppointmentDuration> =>
    this.settingsRepository.getSelectedAppointmentDuration();
}