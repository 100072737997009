import { Observable, of } from 'rxjs'; 
import { threeMonthsPackage, sixMonthsPackage, twelveMonthsPackage, perUsagePackage, PackageType } from '../Entities/PackageTypes';

export default class GetAvailablePackagesUseCase {
  execute: () => Observable<PackageType[]> = () => {
    return of([
      threeMonthsPackage,
      sixMonthsPackage,
      twelveMonthsPackage,
      perUsagePackage
    ])
  }
}