import { Observable } from "rxjs";
import CustomersRepository from "./CustomersRepository";

/**
 * Edits the customer that matches the given `customerId` with the provided info.
 */
export default class EditCustomerUseCase {

  constructor(
    private readonly customersRepository: CustomersRepository
  ) {
  }

  execute = (
    customerId: string,
    name: string,
    surname: string,
    email: string,
    phoneNumber: string | null
  ): Observable<void> => this.customersRepository.editCustomer(customerId, name, surname, email, phoneNumber);
}