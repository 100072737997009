import { Box, Button, CircularProgress, createStyles, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import tokenBlue from '../../../Resources/tokenBlue.svg';
import tokenOrange from '../../../Resources/tokenOrange.svg';
import UiBasicAppointment from '../../Admin/Customers/Detail/UiBasicAppointment';
import UiSimplePackage from '../../Admin/Customers/Detail/UiSimplePackage';
import { getPackageStatusIcon } from '../../Admin/Packages/PackagesIcons';
import { getPackageColor } from '../../Admin/Packages/PackagesMappers';
import BookingCard from '../../Commons/BookingCard';
import PackageCard from '../../Commons/PackageCard';
import strings from '../../Utils/LocalizedStrings';
import { perUsagePackage } from "../../../Domain/Entities/PackageTypes";

interface UserOverviewViewProps {
  readonly displayName: string;
  readonly availableTokenCount: number;
  readonly totalTokenCount: number;
  readonly activePackage: UiSimplePackage | null;
  readonly packages: UiSimplePackage[];
  readonly arePackagesLoading: boolean;
  readonly bookings: UiBasicAppointment[];
  readonly areBookingsLoading: boolean;
  readonly onBookNowClicked: () => void;
  readonly onCancelBookingClicked: (identifier: string) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start"
  },
  horizontalContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  horizontalItem: {
    margin: theme.spacing(2)
  },
  bookingsContainer: {
    display: "flex",
    flexDirection: "column"
  },
  title: {
    ...theme.typography.h4
  },
  subtitle: {
    ...theme.typography.h5,
    color: theme.palette.grey[600]
  },
  section: {
    marginTop: theme.spacing(3)
  },
  sectionTitle: {
    ...theme.typography.h6,
    fontWeight: "bold"
  },
  simpleText: {
    ...theme.typography.body2,
    color: theme.palette.grey[600]
  },
  mediumText: {
    ...theme.typography.body1,
    color: theme.palette.common.black
  },
  tokenIcon: {
    width: "48px",
    height: "48px"
  },
  packageCard: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  }
}));

const UserOverviewScreen = (props: UserOverviewViewProps) => {
  // const { } = props;

  const classes = useStyles();

  const iconClasses = Array(props.totalTokenCount).fill(0)
    .map((_, index) => index < props.availableTokenCount ? tokenBlue : tokenOrange)
    .reverse();

  const str = strings.user.overview;
  return (
    <Box className={classes.columnContainer}>
      <span className={classes.title}>{props.displayName},</span>
      <span className={classes.subtitle}>{str.welcome}</span>
      <Grid container>
        <Grid item xs={12} md={6} className={classes.section}>
          <Box className={classes.columnContainer}>
            {/* Packages */}
            <span className={classes.sectionTitle}>{str.packages}</span>
            {props.arePackagesLoading
              ? <CircularProgress />
              : props.packages.length === 0
                ? <span>{str.noPackages}</span>
                : <Box className={classes.columnContainer}>
                  {props.packages.map(pack =>
                    <div key={pack.identifier} className={classes.packageCard}>
                      <PackageCard
                        key={pack.identifier}
                        title={pack.name}
                        displayableDateFrom={pack.displayableDateFrom}
                        displayableDateTo={pack.displayableDateTo}
                        statusImage={getPackageStatusIcon(pack.status)}
                        statusText={pack.statusText}
                        statusColor={getPackageColor(pack.status)}
                        image={pack.packageImageUrl} />
                    </div>
                  )}
                </Box>
            }
            <br />
            {/* Tokens */}
            {props.activePackage
              ? <div>
                <span className={classes.simpleText}>
                  {props.activePackage.type === perUsagePackage
                    ? str.availableTokensPerPackage
                    : str.availableTokensPerWeek}
                </span>
                <Box className={classes.horizontalContainer}>
                  {props.activePackage.type === perUsagePackage
                    ? <img src={tokenOrange} alt="Icona del gettone" className={classes.tokenIcon}/>
                    : Array(props.totalTokenCount).fill(0).map((_, index) =>
                      <img key={index} src={iconClasses[index]} alt="Icona del gettone" className={classes.tokenIcon}/>
                    )}
                  <span className={`${classes.horizontalItem} ${classes.mediumText}`}>
                    <b>{props.availableTokenCount}</b>
                    &nbsp;{props.availableTokenCount === 1 ? str.token : str.tokens}
                  </span>
                </Box>
              </div>
              : null
            }
          </Box>
        </Grid>
        {props.packages.length === 0
          ? <div />
          : <Grid item xs={12} md={6} className={classes.section}>
            <Box className={classes.columnContainer}>
              {/* Bookings */}
              <span className={classes.sectionTitle}>{str.bookings}</span>
              {props.areBookingsLoading
                ? <CircularProgress />
                : props.bookings.length === 0
                  ? <span>{str.noBookings}</span>
                  : <Box className={classes.bookingsContainer}>
                    <span className={classes.simpleText}>{str.bookingsMessage}</span>
                    {props.bookings.map(booking =>
                      <Box display="flex" flexGrow="0" key={booking.identifier}>
                        <BookingCard
                          className={classes.packageCard}
                          key={booking.identifier}
                          displayableDate={booking.displayableDate}
                          displayableTimeStart={booking.displayableTimeStart}
                          displayableTimeEnd={booking.displayableTimeEnd}
                          displayCancelButton={booking.isCancelable}
                          onCancelButtonClicked={() => props.onCancelBookingClicked(booking.identifier)}
                        />
                      </Box>
                    )}
                  </Box>
              }
              <br />
              <div>
                <Button
                  variant="text"
                  color="primary"
                  onClick={props.onBookNowClicked}>{str.book}</Button>
              </div>
            </Box>
          </Grid>
        }
      </Grid>
    </Box>
  )
};

export default UserOverviewScreen;

