import { Grid, Hidden } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import AppFactory from '../../../DI/AppFactory';
import Logger from '../../../Logger/Logger';
import { addSegment, ROUTE_ADMIN_CLIENTS, ROUTE_ADMIN_CLIENTS_ASSIGN_PACKAGE, ROUTE_ADMIN_CLIENTS_DETAIL, ROUTE_ADMIN_CLIENTS_EDIT_PACKAGE, ROUTE_ADMIN_CLIENTS_NEW } from '../../../Routing/RoutingConstants';
import FullScreenDialog from '../../Commons/FullscreenDialog';
import strings from '../../Utils/LocalizedStrings';
import CustomersPresenter, { UiSimpleCustomer } from './CustomersPresenter';
import CustomersScreen from './CustomersScreen';
import CustomersView from './CustomersView';
import CustomerDetailViewController from './Detail/CustomerDetailViewController';
import NewCustomerViewController from './NewCustomer/NewCustomerViewController';
import PackageAssignmentViewController from './PackageAssignment/PackageAssignmentViewController';

interface CustomersViewControllerProps {
  readonly presenter: CustomersPresenter;
  readonly factory: AppFactory;
}

interface CustomersScreenState {
  readonly recentCustomers: UiSimpleCustomer[],
  readonly customerSearchResults: UiSimpleCustomer[],
  readonly isLoadingRecent: boolean,
  readonly isLoadingResults: boolean
}

const CustomersViewController = (props: CustomersViewControllerProps) => {
  const { presenter } = props;
  const history = useHistory();

  const [state, setState] = useState<CustomersScreenState>({
    recentCustomers: [],
    customerSearchResults: [],
    isLoadingRecent: false,
    isLoadingResults: false
  });

  const view: () => CustomersView = () => ({
    showSearchResults: (results: UiSimpleCustomer[]) => {
      setState(state => ({
        ...state,
        customerSearchResults: results
      }));
    },
    showRecentSearchedCustomers: (customers: UiSimpleCustomer[]) => setState(state => ({
      ...state,
      recentCustomers: customers
    })),
    showSearchLoading: () => {
      setState(state => ({
        ...state,
        isLoadingResults: true
      }));
    },
    hideSearchLoading: () => {
      setState(state => ({
        ...state,
        isLoadingResults: false
      }));
    }
  });

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  const customersScreen =
    <CustomersScreen
      {...state}
      onSearchQueryChanged={query => presenter.searchUsers(query)}
      onCustomerSelected={customer => {
        presenter.customerSelected(customer.identifier);
        history.push(addSegment(ROUTE_ADMIN_CLIENTS, customer.identifier))
      }}
      onAddNewCustomerClicked={() => history.push(ROUTE_ADMIN_CLIENTS_NEW)}
    />

  return (
    <Switch>
      <Route exact
        path={ROUTE_ADMIN_CLIENTS_NEW}
        render={route => {
          const presenter = props.factory.newCustomerPresenter();
          return (
            <FullScreenDialog
              title={strings.admin.clients.creation.title}
              confirmText={strings.commons.save}
              onConfirmButtonClicked={() => presenter.saveCustomer()}
              isVisible={true}
              onClosed={() => route.history.goBack()} >
              <NewCustomerViewController
                presenter={presenter}
                onCustomerCreated={({ identifier }) => {
                  Logger.d("navigating out to " + addSegment(ROUTE_ADMIN_CLIENTS, identifier));
                  history.push(addSegment(ROUTE_ADMIN_CLIENTS, identifier));
                }} />
            </FullScreenDialog>
          );
        }} />
      <Route exact
        path={ROUTE_ADMIN_CLIENTS_ASSIGN_PACKAGE}
        render={route => {
          const customerId = route.match.params.id;
          if (!customerId) {
            Logger.e(`customerId is ${customerId} when navigating to assign package screen`);
            history.goBack();
            return;
          }

          const viewModel = props.factory.packageAssignmentViewModel(
            customerId, null,
            () => route.history.goBack()
          );
          return (<FullScreenDialog
            title={strings.admin.clients.packageAssignment.title}
            confirmText={strings.commons.save}
            onConfirmButtonClicked={() => viewModel.assignPackage()}
            isVisible={true}
            onClosed={() => route.history.goBack()} >
            <PackageAssignmentViewController
              viewModel={viewModel}
              messages={{
                title: strings.admin.clients.packageAssignment.title,
                sectionTitleWithUsername: strings.formatString(
                  strings.admin.clients.packageAssignment.assignTo, "%s"
                ).toString(),
                packageSelectLabel: strings.admin.clients.packageAssignment.packageToAssign,
                confirmationDialogTitle: strings.admin.clients.packageAssignment.confirmAssignment,
                confirmationDialogButtonText: strings.admin.clients.packageAssignment.assignPackage
              }} />
          </FullScreenDialog>
          );
        }} />

      <Route exact
        path={ROUTE_ADMIN_CLIENTS_EDIT_PACKAGE}
        render={route => {
          const customerId = route.match.params.customerId;
          const packageId = route.match.params.packageId;

          if (!customerId || !packageId) {
            Logger.e(`customerId is ${customerId} and packageId is ${packageId} when navigating to edit package screen`);
            history.goBack();
            return;
          }

          const viewModel = props.factory.packageAssignmentViewModel(
            customerId, packageId,
            () => route.history.goBack()
          );

          return (<FullScreenDialog
            title="Modifica pacchetto"
            confirmText="Salva"
            onConfirmButtonClicked={() => viewModel.assignPackage()}
            isVisible={true}
            onClosed={() => route.history.goBack()} >
            <PackageAssignmentViewController
              viewModel={viewModel}
              messages={{
                title: "Modifica pacchetto",
                sectionTitleWithUsername: "Modifica pacchetto di %s",
                packageSelectLabel: "Tipo pacchetto",
                confirmationDialogTitle: "Conferma modifica pacchetto",
                confirmationDialogButtonText: "Modifica pacchetto"
              }} />
          </FullScreenDialog>
          );
        }} />
      <Route exact
        path={ROUTE_ADMIN_CLIENTS_DETAIL}
        render={route => {
          const customerId = route.match.params.id;
          if (!customerId) {
            Logger.e(`customerId is ${customerId} when navigating to client detail`);
            history.goBack();
            return;
          }

          return <Grid container>
            <Hidden smDown>
              <Grid id="search-results-column" item lg={3} md={4} sm={12}>
                {customersScreen}
              </Grid>
            </Hidden>
            <Grid id="customer-detail-column" item lg={9} md={8} sm={12}>
              <CustomerDetailViewController
                key={route.match.params.id}
                presenter={props.factory.customerDetailPresenter(customerId)}
                onAssignPackageClicked={customerId =>
                  history.push(
                    ROUTE_ADMIN_CLIENTS_ASSIGN_PACKAGE
                      .replace(":id", customerId)
                  )
                }
                onEditPackageClicked={(customerId, packageId) =>
                  history.push(
                    ROUTE_ADMIN_CLIENTS_EDIT_PACKAGE
                      .replace(":customerId", customerId)
                      .replace(":packageId", packageId)
                  )
                } />
            </Grid>
          </Grid>
        }}>
      </Route>
      <Route>
        <Grid container>
          <Grid id="search-results-column" item lg={3} md={6} sm={12}>
            {customersScreen}
          </Grid>
        </Grid>
      </Route>
    </Switch>

  );
}

export default CustomersViewController;

