import * as Sentry from "@sentry/browser";
import { ILogger } from "./Logger";

class SentryLogger implements ILogger {

  d = (message: any) => Sentry.captureMessage(message, Sentry.Severity.Debug);
  i = (message: any) => Sentry.captureMessage(message, Sentry.Severity.Info);
  w = (message: any) => Sentry.captureMessage(message, Sentry.Severity.Warning);
  e = (message: any) => Sentry.captureMessage(message, Sentry.Severity.Error);
};

export default SentryLogger;