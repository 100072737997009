
import React, { useEffect, useState } from 'react';
import UserProfilePresenter, { UserProfileEditingState } from './UserProfilePresenter';
import UserProfileScreen from './UserProfileScreen';
import UserProfileView from './UserProfileView';
import UiUserProfile from './UiUserProfile';

interface UserProfileViewControllerProps {
  readonly presenter: UserProfilePresenter;
}

interface UserProfileState {
  readonly presenter: UserProfilePresenter;
  readonly userProfile?: UiUserProfile;
  readonly editingState?: UserProfileEditingState;
  readonly enableSaveButton: boolean;
  readonly phoneNumberError: string | null;
  readonly passwordError: string | null;
  readonly isLoading: boolean;
}

const UserProfileViewController = (props: UserProfileViewControllerProps) => {
  const [state, setState] = useState<UserProfileState>({
    presenter: props.presenter,
    enableSaveButton: false,
    phoneNumberError: null,
    passwordError: null,
    isLoading: false,
  });
  const { presenter } = state;

  const view: () => UserProfileView = () => ({
    showUserProfile: profile => setState(state => ({ ...state, userProfile: profile })),
    enableSaveButton: () => setState(state => ({ ...state, enableSaveButton: true })),
    disableSaveButton: () => setState(state => ({ ...state, enableSaveButton: false })),
    updateEditingState: editingState => setState(state => ({ ...state, editingState: editingState })),
    showPhoneNumberError: error => setState(state => ({ ...state, phoneNumberError: error })),
    hidePhoneNumberError: () => setState(state => ({ ...state, phoneNumberError: null })),
    showPasswordError: error => setState(state => ({ ...state, passwordError: error })),
    hidePasswordError: () => setState(state => ({ ...state, passwordError: null })),
    showLoading: () => setState(state => ({ ...state, isLoading: true })),
    hideLoading: () => setState(state => ({ ...state, isLoading: false })),
  });

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  return (
    <UserProfileScreen
      displayName={state.userProfile?.displayName ?? "..."}
      email={state.userProfile?.email ?? "..."}
      phoneNumber={state.editingState?.phoneNumber ?? state.userProfile?.phoneNumber ?? ""}
      password={state.editingState?.password ?? null}
      passwordConfirmation={state.editingState?.passwordConfirmation ?? null}
      phoneNumberError={state.phoneNumberError}
      passwordError={state.passwordError}
      enableSaveButton={state.enableSaveButton}
      showLoading={state.isLoading}
      onPhoneNumberChanged={number => presenter.phoneNumberChanged(number)}
      onPasswordChanged={password => presenter.passwordChanged(password)}
      onPasswordConfirmationChanged={password => presenter.passwordConfirmationChanged(password)}
      onSavePressed={() => presenter.saveProfileData()}
      onResetPressed={() => presenter.resetProfileEditing()}
    />
  );
}

export default UserProfileViewController;

