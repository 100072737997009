export interface SimpleCompanyClosure {
  readonly identifier: string;
  readonly description: string | null;
  readonly from: Date;
  readonly to: Date;
}

export default interface CompanyClosure extends SimpleCompanyClosure {
  readonly isAllDayLong: boolean;
}

export const isCompanyClosureEquals = (lhs: CompanyClosure, rhs: CompanyClosure): boolean => {
  return lhs.identifier === rhs.identifier
    && lhs.description === rhs.description
    && lhs.from.getTime() === rhs.from.getTime()
    && lhs.to.getTime() === rhs.to.getTime()
    && lhs.isAllDayLong === rhs.isAllDayLong;
}