
import { Box, Button, CircularProgress, createStyles, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { Add } from '@material-ui/icons';
import React from 'react';
import SearchBar from '../../Commons/SearchBar';
import { UiSimpleCustomer } from './CustomersPresenter';
import strings from '../../Utils/LocalizedStrings';

interface CustomersViewProps {
  readonly recentCustomers: UiSimpleCustomer[],
  readonly customerSearchResults: UiSimpleCustomer[],
  readonly isLoadingRecent: boolean,
  readonly isLoadingResults: boolean,
  readonly onSearchQueryChanged: (query: string) => void;
  readonly onCustomerSelected: (customer: UiSimpleCustomer) => void;
  readonly onAddNewCustomerClicked: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  column: {
    display: "flex",
    flexDirection: "column"
  },
  searchBar: {
    marginBottom: theme.spacing(1)
  },
  sectionTitle: {
    ...theme.typography.h6,
    marginTop: theme.spacing(2)
  },
  lowPriorityText: {
    ...theme.typography.body2,
    color: theme.palette.text.hint
  },
  progressBar: {
    margin: theme.spacing(2),
    alignSelf: "center"
  },
  newCustomerButton: {
    marginTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4)
  }
}));

const CustomersScreen = (props: CustomersViewProps) => {
  const classes = useStyles();

  const recentCustomersComponent =
    props.recentCustomers.length === 0
      ? <label className={classes.lowPriorityText}>{strings.admin.clients.noSearchMadeYet}</label>
      : <List>{customersToListItems(props.recentCustomers, props.onCustomerSelected)}</List>

  const renderRow = ({ index, style }: ListChildComponentProps) => {
    const customer = props.customerSearchResults[index];
    return (
      <div key={customer.identifier} style={style}>
        <ListItem key={customer.identifier} onClick={() => props.onCustomerSelected(customer)} button divider>
          <ListItemText primary={customer.displayName} />
        </ListItem>
      </div>
    );
  }

  const searchResultsComponent =
    props.customerSearchResults.length === 0
      ? <label className={classes.lowPriorityText}>{strings.admin.clients.searchResultsPlaceholder}</label>
      : <FixedSizeList itemSize={48} itemCount={props.customerSearchResults.length} width="100%" height={300}>
        {renderRow}
      </FixedSizeList>

  return (
    <Box className={classes.column}>
      <SearchBar
        hint={strings.admin.clients.searchHint}
        onTextChanged={text => props.onSearchQueryChanged(text)} />
      <label className={classes.sectionTitle}>{strings.admin.clients.recents}</label>
      {props.isLoadingRecent ? <CircularProgress className={classes.progressBar} /> : <div />}
      {props.isLoadingRecent ? <div /> : recentCustomersComponent}
      <label className={classes.sectionTitle}>{strings.admin.clients.clients}</label>
      {props.isLoadingResults ? <CircularProgress className={classes.progressBar} /> : <div />}
      {props.isLoadingResults ? <div /> : searchResultsComponent}
      <Button
        variant="contained"
        className={classes.newCustomerButton}
        startIcon={<Add />}
        onClick={props.onAddNewCustomerClicked}
        disableElevation>{strings.admin.clients.newClient}</Button>
    </Box>
  )
};

const customersToListItems = (
  customers: UiSimpleCustomer[],
  onCustomerClicked: (customer: UiSimpleCustomer) => void
) => {
  return customers.map(customer =>
    <ListItem key={customer.identifier} onClick={() => onCustomerClicked(customer)} button divider>
      <ListItemText primary={customer.displayName} />
    </ListItem>
  );
}

export default CustomersScreen;
