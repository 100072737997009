import { Button, createStyles, FormControl, Grid, makeStyles, MenuItem, Select } from '@material-ui/core';
import { ChevronLeft, ChevronRight } from '@material-ui/icons';
import React from 'react';
import { ToolbarProps, View } from 'react-big-calendar';
import strings from '../Utils/LocalizedStrings';

const useStyles = makeStyles((theme) => createStyles({
  headerItem: {
    marginBottom: theme.spacing(1)
  },
  headerText: {
    fontWeight: "bolder",
    fontSize: "12pt",
    margin: 8
  },
  select: {
    minWidth: 120,
    height: theme.spacing(5)
  },
}));

const CustomToolbar = (props: ToolbarProps) => {
  const classes = useStyles();

  const handleSelectChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    // Notify the calendar that the display mode has changed.
    props.onView(event.target.value as View);
  }

  return (
    <Grid container alignItems="center" spacing={1}>
      <Grid item md={1} xs={6}>
        <Button
          variant="outlined"
          onClick={() => props.onNavigate("TODAY")}
          className={classes.headerItem}>
          {strings.admin.calendar.today}
        </Button>
      </Grid>
      <Grid item md={1} xs={3}>
        <Button
          variant="outlined"
          onClick={() => props.onNavigate("PREV")}
          className={classes.headerItem}>
          <ChevronLeft />
        </Button>
      </Grid>
      <Grid item md={1} xs={3}>
        <Button
          variant="outlined"
          onClick={() => props.onNavigate("NEXT")}
          className={classes.headerItem}>
          <ChevronRight />
        </Button>
      </Grid>
      <Grid item md={6} xs={8}>
        <p className={classes.headerText}>{props.label}</p>
      </Grid>
      <Grid item md={2} xs={4}>
        <FormControl variant="outlined" className={classes.headerItem}>
          <Select
            value={props.view}
            onChange={handleSelectChange}
            className={classes.select}>
            <MenuItem value={"day"}>{strings.admin.calendar.day}</MenuItem>
            <MenuItem value={"week"}>{strings.admin.calendar.week}</MenuItem>
            <MenuItem value={"month"}>{strings.admin.calendar.month}</MenuItem>
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  )
};

export default CustomToolbar;