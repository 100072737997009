import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { useAppFactory } from '../DI/AppFactoryContext';
import { roleAdmin, roleUser, UnknownRoleError } from '../Domain/Roles';
import User from '../Domain/User';
import { ROUTE_ADMIN, ROUTE_ADMIN_CALENDAR, ROUTE_LOGIN, ROUTE_USER, ROUTE_USER_OVERVIEW } from '../Routing/RoutingConstants';
import AdminHomeViewController from '../UI/Admin/AdminHomeViewController';
import ErrorScreen from '../UI/Commons/ErrorScreen';
import LoginCheckMiddleware from '../UI/Commons/LoginMiddleware';
import AdminLoginViewController from '../UI/Login/AdminLoginViewController';
import SplashScreenViewController from '../UI/SplashScreen/SplashScreenViewController';
import UserHomeViewController from '../UI/User/UserHomeViewController';

const MainRouter = () => {
  const factory = useAppFactory();
  const history = useHistory();

  // Performs the navigation to a home route based on the given user role.
  const navigateToHomeByUser = (user: User) => {
    switch (user.role) {
      case roleAdmin:
        history.replace(ROUTE_ADMIN_CALENDAR);
        break;
      case roleUser:
        history.replace(ROUTE_USER_OVERVIEW);
        break;
    }
  };

  // Checks whether the role of the given user can allow the navigation to the given pathname.
  // If the destination is not allowed, it navigates to a accepted path.
  const checkIfRouteMatchesWithRole = (user: User, pathname: string) => {
    if (
      (user.role === roleAdmin && !pathname.startsWith(ROUTE_ADMIN))
      || (user.role === roleUser && !pathname.startsWith(ROUTE_USER))
    ) {
      // The logged user role does not match with a desired destination. 
      // Perform a navigation to another path based on the role of the logged user.
      navigateToHomeByUser(user);
    }
  };

  // Returns the component to render based on the user role. 
  // If the given pathname is not allowed to be reached by the specified user,
  // an empty component will be returned.
  const getComponentToRenderWhenUserDidLogIn = (user: User, pathname: string) => {
    switch (user.role) {
      case roleAdmin:
        // The admin is logged in. Check if the destination route is the admin page.
        if (pathname.startsWith(ROUTE_ADMIN)) {
          // The destination is the admin page, so no problem and render the admin home component.
          return (
            <AdminHomeViewController
              logoutPresenter={factory.logoutPresenter()}
              selectedItemPath={pathname} />
          );
        } else {
          return <div />;
        }

      case roleUser:
        // A simple user is logged in. Check if the destination route is the user page.
        if (pathname.startsWith(ROUTE_USER)) {
          // The destination is the user page, so no problem and render the user home component.          
          return (
            <UserHomeViewController
              factory={factory}
              presenter={factory.userHomePresenter()}
              logoutPresenter={factory.logoutPresenter()}
              selectedItemPath={pathname} />
          );
        } else {
          return <div />;
        }

      default:
        throw new UnknownRoleError(user.role);
    }
  }

  return (
    <Switch>
      {/* Login route */}
      <Route
        exact path={ROUTE_LOGIN}
        render={() =>
          <LoginCheckMiddleware
            isUserLogged={factory.isUserLoggedUseCase()}
            getUser={factory.getUserUseCase()}
            onUserLogged={navigateToHomeByUser}
            renderIfNotLogged={() =>
              <AdminLoginViewController
                presenter={factory.loginPresenter()}
                onUserLoggedIn={() => history.replace(ROUTE_ADMIN_CALENDAR)} />
            }
            renderOnError={error => <ErrorScreen error={error} />}
          />
        }
      />
      {/* Admin route */}
      <Route
        path={ROUTE_ADMIN}
        render={(props) =>
          <LoginCheckMiddleware
            isUserLogged={factory.isUserLoggedUseCase()}
            getUser={factory.getUserUseCase()}
            renderIfLogged={user => getComponentToRenderWhenUserDidLogIn(user, props.location.pathname)}
            onUserLogged={user => checkIfRouteMatchesWithRole(user, props.location.pathname)}
            onUserNotLogged={() => history.replace(ROUTE_LOGIN)}
            renderOnError={error => <ErrorScreen error={error} />}
          />
        }
      />
      {/* Simple user route */}
      <Route
        path={ROUTE_USER}
        render={(props) =>
          <LoginCheckMiddleware
            isUserLogged={factory.isUserLoggedUseCase()}
            getUser={factory.getUserUseCase()}
            renderIfLogged={user => getComponentToRenderWhenUserDidLogIn(user, props.location.pathname)}
            onUserLogged={user => checkIfRouteMatchesWithRole(user, props.location.pathname)}
            onUserNotLogged={() => history.replace(ROUTE_LOGIN)}
            renderOnError={error => <ErrorScreen error={error} />}
          />
        }
      />
      {/* Splashscreen and fallback routes */}
      <Route
        render={() =>
          <SplashScreenViewController
            presenter={factory.splashScreenPresenter()}
            onUserLoggedIn={() => history.replace(ROUTE_ADMIN_CALENDAR)}
            onUserNotLoggedIn={() => history.replace(ROUTE_LOGIN)} />
        }
      />
    </Switch>
  );
};

export default MainRouter;