import { add } from 'date-fns';
import { defer, Observable, of, throwError } from 'rxjs';
import { Constants } from '../../Utils/Constants';
import { weekEndDay } from '../../Utils/DateUtils';
import { PackageType, perUsagePackage, sixMonthsPackage, threeMonthsPackage, twelveMonthsPackage } from '../Entities/PackageTypes';

export default class EstimatePackageEndDateUseCase {
  execute: (packageType: PackageType, startDate: Date) => Observable<Date | null> = (packageType, startDate) => {
    return defer(() => {
      switch (packageType) {
        case threeMonthsPackage:
          return of(weekEndDay(add(startDate, { weeks: (3 * Constants.weeksInMonth) - 1 })));
        case sixMonthsPackage:
          return of(weekEndDay(add(startDate, { weeks: (6 * Constants.weeksInMonth) - 1 })));
        case twelveMonthsPackage:
        case perUsagePackage:
          return of(weekEndDay(add(startDate, { weeks: (12 * Constants.weeksInMonth) - 1 })));
        default:
          return throwError(`Illegal package type received: ${packageType}`);
      }
    });
  }
}