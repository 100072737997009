import { Box, Button, Card, CardActions, CardContent, createStyles, makeStyles } from '@material-ui/core';
import { DeleteOutline, People } from '@material-ui/icons';
import React from 'react';
import strings from '../Utils/LocalizedStrings';


interface AppointmentCardProps extends React.HTMLAttributes<HTMLElement> {
  /** The date in which the appointment will happen */
  readonly displayableDate: string;
  /** The time in the date in which the appointment will start */
  readonly displayableTimeStart: string;
  /** The time in the date in which the appointment will end */
  readonly displayableTimeEnd: string;
  /** The number of participants during the appointment */
  readonly participantsCount: string;
  /** Whether the cancel button should be displayed ot not. */
  readonly displayCancelButton?: boolean;
  /** Callback invoked when the cancel button is clicked. */
  readonly onCancelButtonClicked?: () => void;
}

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    marginRight: theme.spacing(1)
  },
  titleLabel: {
    fontSize: "1.1em",
  },
  subtitleLabel: {
    color: theme.palette.text.hint,
    fontSize: theme.typography.button.fontSize,
  },
  paddedItem: {
    marginLeft: theme.spacing(1)
  },
  cancel: {
    color: theme.palette.error.light,
  }
}));

/**
 * A card used for showing basic details about an appointment.
 */
const AppointmentCard = (props: AppointmentCardProps) => {
  const classes = useStyles();
  return (
    <Card className={props.className}>
      <CardContent>
        <Box className={classes.root}>
          <Box className={classes.textContainer}>
            <span className={classes.titleLabel}>
              {props.displayableDate}
            </span>
            <span className={classes.subtitleLabel}>
              {props.displayableTimeStart}&nbsp;-&nbsp;{props.displayableTimeEnd}
            </span>
          </Box>
          <b className={classes.paddedItem}>{props.participantsCount}</b>
          <People className={classes.paddedItem} />
        </Box>
      </CardContent>
      {props.displayCancelButton
        ? <CardActions>
          <Button
            className={classes.cancel}
            variant="text"
            onClick={() => props.onCancelButtonClicked?.()}>
            <DeleteOutline />&nbsp;{strings.commons.cancel}
          </Button>
        </CardActions>
        : null}
    </Card>
  )
}

export default AppointmentCard;