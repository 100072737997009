import { add, eachDayOfInterval, eachWeekOfInterval, endOfWeek, format as dateFnsFormat, parse, startOfDay, startOfWeek } from 'date-fns';
import { enUS, it } from 'date-fns/locale';

export const locale = () => {
  const language = navigator.language.toLowerCase();
  if (language.startsWith("it")) {
    return it;
  } else {
    return enUS;
  }
};

// Date Format

export const format = (date: Date, pattern: string) => {
  return dateFnsFormat(date, pattern, { locale: locale() });
}

// Manipulation

export const weekdaysShort = () => {
  const now = new Date();
  return eachDayOfInterval({ start: startOfWeek(now), end: endOfWeek(now) })
    .map(day => format(day, 'EEE'));
}

export const weekdaysLong = () => {
  const now = new Date();
  return eachDayOfInterval({ start: startOfWeek(now), end: endOfWeek(now) })
    .map(day => format(day, 'EEEE'));
}

export const dateTruncatingTime = (date: Date) =>
  startOfDay(date);

export const weekStartDay = (currentDate: Date) => {
  return startOfWeek(currentDate, { locale: locale(), weekStartsOn: 1 });
}

export const weekEndDay = (currentDate: Date) => {
  return endOfWeek(currentDate, { locale: locale(), weekStartsOn: 1 });
}

export const countWeeks = (start: Date, end: Date) => {
  return eachWeekOfInterval({ start, end }, { locale: locale(), weekStartsOn: 1 }).length;
}

export const iterateDays: (start: Date, end: Date, block: (date: Date) => void) => void = (start, end, block) => {
  eachDayOfInterval({ start, end }).forEach(block);
}

export const addDays = (date: Date, days: number) => add(date, { days: days });
export const addYears = (date: Date, years: number) => add(date, { years: years });

// Floating time

const dateFormat = "yyyy-MM-dd'T'HH:mm:ss";

export const toFloatingDateString = (date: Date): string => format(date, dateFormat);

export const fromFloatingDateString = (string: string): Date => parse(string, dateFormat, Date.now(), { locale: locale() });

export const fromFloatingDateStringOrNull = (dateString: string | null | undefined): Date | null => {
  if (dateString) {
    return fromFloatingDateString(dateString);
  } else {
    return null;
  }
}