import { Observable, of, throwError } from "rxjs";
import { switchMap } from "rxjs/operators";
import { TimeSlotId } from "../Entities/TimeSlot";
import BookingsRepository from "./BookingsRepository";
import IsBookingAvailableUseCase from "./IsBookingAvailableUseCase";
export class TimeSlotLimitReachedError extends Error {
  static readonly type = "TimeSlotLimitReachedError";

  readonly type = TimeSlotLimitReachedError.type;
}

export class InsufficientTokenCountError extends Error {
  static readonly type = "InsufficientTokenCountError";

  readonly type = InsufficientTokenCountError.type;
}

export class MissingPackageError extends Error {
  static readonly type = "MissingPackageError";

  readonly type = MissingPackageError.type;
}
export default class BookAppointmentUseCase {
  constructor(
    private readonly isBookingAvailableUseCase: IsBookingAvailableUseCase,
    private readonly bookingsRepository: BookingsRepository,
  ) { }

  execute: (
    userId: string,
    day: Date,
    timeSlotId: TimeSlotId
  ) => Observable<string> = (userId, day, timeSlotId) =>
      this.isBookingAvailableUseCase
        .execute(userId)
        .pipe(
          switchMap(isAvailable => {
            if (isAvailable) {
              return of({});
            } else {
              return throwError("Booking limit exceeded!");
            }
          }),
          switchMap(() =>
            this.bookingsRepository.bookAppointment(
              userId, day, timeSlotId
            )
          )
        );
}