import BookingsRepository from "./BookingsRepository";
import { DayState } from "./DayState";
import { Observable } from "rxjs";

export type AvailableMonthDays = Map<number, DayState>;

export default class GetAvailableMonthDaysUseCase {

  private readonly bookingRepository: BookingsRepository;

  constructor(
    bookingRepository: BookingsRepository
  ) {
    this.bookingRepository = bookingRepository;
  }

  execute = (month: number, year: number): Observable<AvailableMonthDays> =>
    this.bookingRepository.getAvailableMonthDays(month, year);
}