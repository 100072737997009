import { Observable, of } from 'rxjs';
import { flatMap, map } from 'rxjs/operators';
import UserInfo from '../UserInfo';
import CustomersRepository from './CustomersRepository';

export default class SearchCustomersUseCase {

  private readonly customersRepository: CustomersRepository;

  constructor(
    customersRepository: CustomersRepository
  ) {
    this.customersRepository = customersRepository;
  }

  execute: (query: string) => Observable<UserInfo[]> = (query) => {
    return of(query).pipe(
      map(query => query.trim().toLowerCase()),
      flatMap(query => {
        if (query.length < 3) {
          return of([]);
        } else {
          return this.customersRepository
            .getAllCustomersInfo()
            .pipe(map(customers => customers.filter(customer =>
              customer.name.toLowerCase().includes(query) ||
              customer.surname.toLowerCase().includes(query)
            )));
        }
      })
    )
  }
}