import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import SimpleDialog from '../../Commons/SimpleDialog';
import SettingsPresenter, { PasswordEditingState, UiAppointmentDuration, UiBusinessDay, UiSchedule } from './SettingsPresenter';
import SettingsScreen from './SettingsScreen';
import SettingsView from './SettingsView';

interface SettingsViewControllerProps {
  readonly presenter: SettingsPresenter;
}

interface SettingsState {
  readonly presenter: SettingsPresenter;
  readonly schedules: UiSchedule[];
  readonly selectedSchedule: UiSchedule | null,
  readonly businessDays: UiBusinessDay[];
  readonly appointmentDurations: UiAppointmentDuration[];
  readonly passwordData: PasswordEditingState | null;
  readonly isUpdatePasswordButtonEnabled: boolean;
  readonly showPasswordLoading: boolean;
  readonly showScheduleDeletionDialog: boolean;
}

const SettingsViewController = (props: SettingsViewControllerProps) => {
  const [state, setState] = useState<SettingsState>({
    presenter: props.presenter,
    schedules: [],
    selectedSchedule: null,
    businessDays: [],
    appointmentDurations: [],
    passwordData: null,
    isUpdatePasswordButtonEnabled: false,
    showPasswordLoading: false,
    showScheduleDeletionDialog: false
  });
  const { presenter } = state;

  const view: () => SettingsView = () => ({
    showSchedules: schedules => setState(state => ({ ...state, schedules: schedules })),
    showBusinessDays: businesDays => setState(state => ({ ...state, businessDays: businesDays })),
    showAppointmentDurations: durations => setState(state => ({ ...state, appointmentDurations: durations })),
    showPasswordData: data => setState(state => ({ ...state, passwordData: data })),
    enablePasswordUpdateButton: () => setState(state => ({ ...state, isUpdatePasswordButtonEnabled: true })),
    disablePasswordUpdateButton: () => setState(state => ({ ...state, isUpdatePasswordButtonEnabled: false })),
    showPasswordLoading: () => setState(state => ({ ...state, showPasswordLoading: true })),
    hidePasswordLoading: () => setState(state => ({ ...state, showPasswordLoading: false })),
  });

  const setScheduleDeletionDialogVisible = (visible: boolean) => {
    setState(state => ({ ...state, showScheduleDeletionDialog: visible }));
  }

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  return (
    <Box>
      <SettingsScreen
        schedules={state.schedules}
        selectedSchedule={state.schedules.find(schedule => schedule.isSelected) ?? null}
        businessDays={state.businessDays}
        appointmentDurations={state.appointmentDurations}
        passwordProps={{
          password: state.passwordData?.password,
          passwordConfirmation: state.passwordData?.passwordConfirmation,
          passwordError: state.passwordData?.passwordError ?? undefined,
          onPasswordChanged: presenter.passwordChanged,
          onPasswordConfirmationChanged: presenter.passwordConfirmationChanged,
        }}
        isUpdatePasswordButtonEnabled={state.isUpdatePasswordButtonEnabled}
        showPasswordLoading={state.showPasswordLoading}
        onAddScheduleClicked={presenter.createNewSchedule}
        onDeleteScheduleClicked={() => setScheduleDeletionDialogVisible(true)}
        onScheduleSelected={presenter.selectSchedule}
        onBusinessDaySelectedChanged={presenter.businessDaySelectionChanged}
        onSlotStartChange={presenter.businessDayStartSlotChanged}
        onSlotEndChange={presenter.businessDayEndSlotChanged}
        onSlotCustomersLimitChange={presenter.businessDayCustomersLimitSlotChanged}
        onSlotDelete={presenter.businessDaySlotRemoved}
        onSlotAdd={presenter.businessDaySlotAdded}
        onDurationChanged={presenter.userChangedDuration}
        onChangePasswordClicked={() => presenter.updatePassword()}
        onSaveDetailsClicked={presenter.saveScheduleDetails}
      />
      <SimpleDialog
        isVisible={state.showScheduleDeletionDialog}
        title="Elimina orario"
        content="Confermi l'eliminazione dell'orario selezionato?"
        confirmButtonText="Conferma"
        onConfirmButtonClicked={() => {
          presenter.deleteSchedule();
          setScheduleDeletionDialogVisible(false);
        }}
        onCancelButtonClicked={() => setScheduleDeletionDialogVisible(false)} />
    </Box>
  );
}

export default SettingsViewController;

