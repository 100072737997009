import React, { useEffect, useState, useCallback } from 'react';
import SplashScreen from './SplashScreen';
import SplashScreenPresenter from './SplashScreenPresenter';
import SplashScreenView from './SplashScreenView';

interface SplashScreenViewControllerProps {
  readonly presenter: SplashScreenPresenter;
  readonly onUserLoggedIn: () => void;
  readonly onUserNotLoggedIn: () => void;
}

const SplashScreenViewController = (props: SplashScreenViewControllerProps) => {
  const { onUserLoggedIn, onUserNotLoggedIn } = props;
  const [presenter] = useState(props.presenter);

  const view: () => SplashScreenView = useCallback(() => ({
    navigateToLogin: () => {
      onUserNotLoggedIn();
    },
    navigateToHome: () => {
      onUserLoggedIn();
    }
  }), [onUserNotLoggedIn, onUserLoggedIn]);

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter, view]);

  return (
    <SplashScreen />
  )
};

export default SplashScreenViewController;