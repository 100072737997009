import { Observable } from 'rxjs';
import AppointmentDuration from './AppointmentDuration';
import SettingsRepository from './SettingsRepository';

/**
 * Returns all the kind of AppointmentDuration that can be managed by the system.
 */
export default class GetAvailableAppointmentDurationsUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository
  ) { }

  execute = (): Observable<AppointmentDuration[]> =>
    this.settingsRepository.getAppointmentDurations();
}