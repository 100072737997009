import { v4 as uuid } from "uuid";
import CompanyClosure from "./CompanyClosure";
import { isAllDayLong } from "./IsAllDayLong";

export const createCompanyClosureFactory = (
): CompanyClosureFactory => (description: string | null, from: Date, to: Date) => ({
  identifier: uuid(),
  description: description,
  from: from,
  to: to,
  isAllDayLong: isAllDayLong(from, to),
});

export type CompanyClosureFactory = (description: string | null, from: Date, to: Date) => CompanyClosure;