import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import DateTimeProvider from "../../Utils/DateTimeProvider";
import { weekStartDay } from "../../Utils/DateUtils";
import GetUserUseCase from "../UserAuth/GetUserUseCase";
import Booking from "./Booking";
import BookingsRepository from "./BookingsRepository";

/**
 * Returns the bookings that the current user made that have not already took place.
 */
export default class GetCustomerFutureBookingsUseCase {

  constructor(
    private readonly getUserUseCase: GetUserUseCase,
    private readonly bookingsRepository: BookingsRepository,
    private readonly dateTimeProvider: DateTimeProvider,
  ) {
  }

  execute: () => Observable<Booking[]> = () =>
    this.getUserUseCase.execute().pipe(switchMap(user => {
      const date = new Date(this.dateTimeProvider.currentTimeMillis());
      return this.bookingsRepository
        .getUserBookings(user, weekStartDay(date), null)
        .pipe(map(bookings =>
          bookings.map(booking => ({
            ...booking,
            // Booking is cancelable if it will take place before than 24 hours ahead the due date.
            isCancelable: (booking.startDate.valueOf() - date.valueOf()) > 24 * 60 * 60 * 1000,
          })))
        );
    }));
}