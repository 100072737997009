import { PackageState, packageStatusActive, packageStatusExpired, packageStatusInactive } from "../../../Domain/Entities/Package";
import { PackageType, perUsagePackage, sixMonthsPackage, threeMonthsPackage, twelveMonthsPackage } from "../../../Domain/Entities/PackageTypes";
import packageOneYear from "../../../Resources/packageOneYear.svg";
import packageSixMonths from "../../../Resources/packageSixMonths.svg";
import packageThreeMonths from "../../../Resources/packageThreeMonths.svg";
import packageUsage from "../../../Resources/packageUsage.svg";
import strings from "../../Utils/LocalizedStrings";

/**
 * Returns the name of the package that matches the given type.
 */
export const getPackageName: (type: PackageType) => string = (type) => {
  let name: string | undefined;
  switch (type) {
    case threeMonthsPackage:
      name = strings.packages.threeMonthsPackage;
      break;
    case sixMonthsPackage:
      name = strings.packages.sixMonthsPackage;
      break;
    case twelveMonthsPackage:
      name = strings.packages.twelveMonthsPackage;
      break;
    case perUsagePackage:
      name = strings.packages.perUsagePackage;
      break;
  }
  return name ?? strings.commons.other;
}

export const getPackageImage = (type: string): string => {
  switch (type) {
    case threeMonthsPackage:
      return packageThreeMonths;
    case sixMonthsPackage:
      return packageSixMonths;
    case twelveMonthsPackage:
      return packageOneYear;
    case perUsagePackage:
      return packageUsage;
  }
  return "";
}

export const getPackageStatusText = (status: PackageState): string => {
  switch (status) {
    case packageStatusActive: return strings.packages.statusActive;
    case packageStatusInactive: return strings.packages.statusInactive;
    case packageStatusExpired: return strings.packages.statusExpired;
  }
}

export const getPackageColor = (status: PackageState): string => {
  switch (status) {
    case packageStatusActive: return "#6FCF97";
    case packageStatusInactive: return "#00000061";
    case packageStatusExpired: return "#EB5757";
  }
}