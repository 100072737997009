import SplashScreenView from "./SplashScreenView"
import IsUserLoggedUseCase from "../../Domain/IsUserLoggedUseCase";
import { take } from 'rxjs/operators';
import { Subscription } from "rxjs";

export default class SplashScreenPresenter {

  private subscription = new Subscription();

  private isUserLogged: IsUserLoggedUseCase;

  constructor(isUserLogged: IsUserLoggedUseCase) {
    this.isUserLogged = isUserLogged;
  }

  attachView = (view: SplashScreenView) => {
    this.subscription.add(
      this.isUserLogged
        .execute()
        .pipe(take(1))
        .subscribe(isLogged => {
          if (isLogged) {
            return view.navigateToHome();
          } else {
            return view.navigateToLogin();
          }
        })
    );
  }

  detachView = () => {
    this.subscription.unsubscribe();
  }
}