export interface Hour {
  readonly hour: number;
  readonly minute: number;
}

export const hourToComparable = (hour: Hour) => hour.hour * 100 + hour.minute;

/**
 * A BusinessHoursSlot is a time interval between two hours in which the company is open.
 */
export interface BusinessHoursSlot {
  readonly start: Hour;
  readonly end: Hour;
  readonly customersLimit: number;
}

/**
 * A BusinessDay represents a day of the week and defines the business hours 
 * in which the company is regularly open.
 */
export interface BusinessDay {
  readonly dayOfWeek: number;
  readonly businessHourSlots: BusinessHoursSlot[];
}

/**
 * A BusinessDaysSchedule defines a constant schedule that the company follows
 * for opening the business to the public. Such schedule is active between
 * two bound dates.
 */
export default interface BusinessDaysSchedule {
  readonly identifier: string;
  readonly description: string;
  readonly startDate: Date;
  readonly endDate: Date;
  readonly businessDays: BusinessDay[];
}