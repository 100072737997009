import { endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { empty, Observable, ReplaySubject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, map, shareReplay, switchMap, take } from "rxjs/operators";
import Appointment from "../../../Domain/Appointments/Appointment";
import GetAppointmentsUseCase from "../../../Domain/Appointments/GetAppointmentsUseCase";
import Logger from "../../../Logger/Logger";
import { format } from "../../../Utils/DateUtils";
import strings from "../../Utils/LocalizedStrings";
import CalendarView from "./CalendarView";
import UiSimpleEvent from "./UiSimpleEvent";

export default class CalendarPresenter {

  private view?: CalendarView;
  private readonly dateRangeChanges = new ReplaySubject<[Date, Date]>(1);
  private subscriptions = new Subscription();

  private appointments: Observable<Appointment[]> = empty();

  constructor(
    private readonly getAppointmentsUseCase: GetAppointmentsUseCase,
  ) {
    this.appointments = this.dateRangeChanges.pipe(
      distinctUntilChanged(),
      debounceTime(1),
      switchMap(([start, end]) => this.getAppointmentsUseCase.execute(start, end)),
      shareReplay(1)
    );

    this.monthViewSelected();
  }

  attachView = (v: CalendarView) => {
    this.view = v;

    this.subscriptions.add(
      this.appointments
        .pipe(map(appointments => appointments.map(this.toUiSimpleEvent)))
        .subscribe(
          events => {
            this.view?.showEvents(events);
          },
          error => this.handleError(error)
        )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscriptions.unsubscribe();
  }

  eventClicked = (startDate?: Date, endDate?: Date) => {
    if (startDate && endDate) {
      this.subscriptions.add(
        this.appointments
          .pipe(take(1))
          .subscribe(appointments => {
            const appointment = appointments.find(a => a.startDate === startDate && a.endDate === endDate);
            appointment && this.showAppointmentDetail(appointment);
          }, error => this.handleError(error))
      );
    }
  }

  dayViewSelected = () => {
    const currentDate = new Date();
    const start = startOfDay(currentDate);
    const end = endOfDay(currentDate);
    this.dateRangeChanges.next([start, end]);
  }

  weekViewSelected = () => {
    const currentDate = new Date();
    const start = startOfWeek(currentDate);
    const end = endOfWeek(currentDate);
    this.dateRangeChanges.next([start, end]);
  }

  monthViewSelected = () => {
    const currentDate = new Date();
    const start = startOfMonth(currentDate);
    const end = endOfMonth(currentDate);
    this.dateRangeChanges.next([start, end]);
  }

  dateRangeChanged = (start: Date, end: Date) => {
    this.dateRangeChanges.next([start, end]);
  };

  // Private methods

  private showAppointmentDetail = (appointment: Appointment) => {
    this.view?.showEventDetail(appointment.identifier);
  }

  private toUiSimpleEvent: (appointment: Appointment) => UiSimpleEvent = (app) => {
    const clientsCount = app.bookings.length;
    let title: string;
    if (clientsCount === 1) {
      title = strings.admin.calendar.oneClient;
    } else {
      title = strings.formatString(strings.admin.calendar.moreClient, clientsCount).toString();
    }
    return {
      displayableHour: format(app.startDate, "HH:mm"),
      title: title,
      startDate: app.startDate,
      endDate: app.endDate
    };
  }

  private handleError = (error: Error) => {
    Logger.e(error);
  }
}