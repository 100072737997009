import { Observable } from "rxjs";
import { map, switchMap } from "rxjs/operators";
import DateTimeProvider from "../../Utils/DateTimeProvider";
import { weekEndDay, weekStartDay } from "../../Utils/DateUtils";
import GetUserUseCase from "../UserAuth/GetUserUseCase";
import BookingsRepository from "./BookingsRepository";

/**
 * Returns the current user's used tokens for the ongoing week.
 */
export default class GetTokensForCurrentWeekUseCase {

  constructor(
    private readonly getUserUseCase: GetUserUseCase,
    private readonly bookingsRepository: BookingsRepository,
    private readonly dateTimeProvider: DateTimeProvider,
  ) {
  }

  execute: () => Observable<number> = () =>
    this.getUserUseCase.execute().pipe(switchMap(user => {
      const date = new Date(this.dateTimeProvider.currentTimeMillis());
      return this.bookingsRepository.getUserBookings(
        user, weekStartDay(date), weekEndDay(date)
      ).pipe(map(bookings => bookings.length));
    }));
}