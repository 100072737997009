import { add } from "date-fns";
import { differenceInMilliseconds, hoursToMilliseconds } from "date-fns/esm";
import React, { useState } from "react";
import { Calendar, Event, ToolbarProps, View } from "react-big-calendar";
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import CalendarLocalizer from "../Utils/CalendarLocalizer";
import "./calendar.css";
import CustomToolbar from "./CustomToolbar";
import {
  CalendarEvent,
  CalendarEventInfo,
  calendarMaxTimeInColumnView,
  calendarMinTimeInColumnView,
  calendarSteps,
  calendarTimeSlots,
  handleEventMove,
  handleEventResize,
  InteractionEvent,
  SlotInfo,
  stringOrDateToDate
} from "./DragAndDropCalendar.helpers";

// @ts-ignore
const BigCalendar = withDragAndDrop(Calendar);

interface DragAndDropCalendarProps<E extends CalendarEvent, TP extends ToolbarProps> extends React.HTMLAttributes<HTMLElement> {
  events: E[];
  toolbar?: React.ComponentType<TP>;
  onEventCreated?: (event: CalendarEventInfo) => void;
  onEventMoved?: (event: E) => void;
  onEventResized?: (event: E) => void;
  onEventClicked?: (event: E) => void;
}

const DragAndDropCalendar = function <E extends CalendarEvent, TP extends ToolbarProps>(
  props: DragAndDropCalendarProps<E, TP>
) {
  const { events } = props;
  const [currentView, setView] = useState<View>("month");

  const onNewEvent = (slotInfo: SlotInfo) => {
    const start = stringOrDateToDate(slotInfo.start);
    const end = stringOrDateToDate(slotInfo.end);

    const isOneOrMoreFullDays = differenceInMilliseconds(end, start) % hoursToMilliseconds(24) === 0;

    let newEvent: CalendarEventInfo;
    if (isOneOrMoreFullDays) {
      const newEnd = add(end, { seconds: -1 });
      newEvent = {
        start: start,
        end: newEnd,
        isAllDay: isOneOrMoreFullDays,
      };
    } else {
      newEvent = {
        start: start,
        end: end,
        isAllDay: isOneOrMoreFullDays,
      }
    }

    props.onEventCreated && props.onEventCreated(newEvent);
  };

  const onEventMove = (event: InteractionEvent) => {
    const index = events.find(e => e.id === event.event.resource.id);
    if (!index) return;

    const targetEvent = handleEventMove(event, events);
    if (targetEvent) {
      props.onEventMoved && props.onEventMoved(targetEvent as E);
    }
  };

  const onEventResize = (event: InteractionEvent) => {

    const targetEvent = handleEventResize(event, events);

    if (targetEvent) {
      props.onEventResized && props.onEventResized(targetEvent as E);
    }
  };

  const calendarEvents: Event[] = events.map(event => ({
    resourceId: event.id,
    title: event.title,
    start: event.start,
    end: event.end,
    allDay: event.isAllDay,
    resource: event,
  }));

  return (
    <BigCalendar
      className={props.className}
      localizer={CalendarLocalizer}
      culture={navigator.language}
      defaultView="month"
      onView={setView}
      selectable
      resizable={currentView !== "month"}
      step={calendarSteps}
      timeslots={calendarTimeSlots}
      onSelectSlot={onNewEvent}
      onEventDrop={onEventMove}
      onEventResize={onEventResize}
      toolbar={true}
      events={calendarEvents}
      min={calendarMinTimeInColumnView}
      max={calendarMaxTimeInColumnView}
      // @ts-ignore
      onSelectEvent={e => props.onEventClicked && props.onEventClicked(e.resource as E)}
      components={{
        toolbar: (props.toolbar as React.ComponentType<ToolbarProps> | undefined | null) ?? CustomToolbar,
      }}
    >

    </BigCalendar>
  )
};

export default DragAndDropCalendar;