import { Observable } from "rxjs";
import UserInfo from "../UserInfo";
import CustomersRepository from "./CustomersRepository";

export default class GetAllCustomersUseCase {

  constructor(
    private readonly customersRepository: CustomersRepository,
  ) { }

  execute = (): Observable<UserInfo[]> => this.customersRepository.getAllCustomersInfo();
}