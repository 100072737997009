import { combineLatest, defer, Observable, of } from "rxjs";
import { flatMap, map, switchMap, tap } from "rxjs/operators";
import Appointment from "../Domain/Appointments/Appointment";
import AppointmentsRepository from "../Domain/Appointments/AppointmentsRepository";
import { fromFloatingDateString, toFloatingDateString } from "../Utils/DateUtils";
import { asObservable } from "../Utils/PromiseUtils";
import BookingsApi from "./Rest/BookingsApi";

interface GetUserAppointmentsRequest {
  readonly userId: string;
  readonly from: string;
  readonly to: string;
}

interface UserAppointmentResponse {
  readonly id: string;
  readonly startTimestamp: string;
  readonly endTimestamp: string;
  readonly date: string;
  readonly bookingsCount: number;
  readonly bookings?: UserAppointmentBookingsResponse[];
}

interface UserAppointmentBookingsResponse {
  readonly id: string;
  readonly userId: string;
  readonly name: string;
  readonly surname: string;
}

export default class AppointmentRepoImp implements AppointmentsRepository {

  // Holds the cached appointments.
  private readonly appointments: Map<string, Appointment[]> = new Map();

  private readonly firestore: () => firebase.firestore.Firestore;

  constructor(
    private readonly bookingsApi: BookingsApi,
    firestore: () => firebase.firestore.Firestore,
  ) {
    this.firestore = firestore;
  }

  getUserAppointments = (userId: string, from: Date, to: Date) => new Observable<Appointment[]>(emitter => {
    const requestBody: GetUserAppointmentsRequest = {
      userId: userId,
      from: from.toISOString(),
      to: to.toISOString()
    };
    this.bookingsApi
      .getUserAppointments(requestBody)
      .then(appointments => appointments.map(appointment => this.mapResponseToAppointment(appointment)))
      .then(appointments => {
        emitter.next(appointments);
        emitter.complete();
      })
      .catch(error => emitter.error(error));
  });

  private mapResponseToAppointment = (response: UserAppointmentResponse): Appointment => ({
    identifier: response.id,
    startDate: fromFloatingDateString(response.startTimestamp),
    endDate: fromFloatingDateString(response.endTimestamp),
    bookings: response.bookings?.map(booking => ({
      identifier: booking.id,
      userInfo: {
        identifier: booking.userId,
        name: booking.name,
        surname: booking.surname
      }
    })) ?? [],
  });

  getAppointments = (from: Date, to: Date) =>
    defer(() => {
      // Determine which appointments have already been found
      // in the requested range of dates.
      const newCacheKey = from.toISOString() + to.toISOString();
      const cachedAppointments: Appointment[] = this.appointments.get(newCacheKey) ?? [];

      if (cachedAppointments.length === 0) {
        // If no appointments have been found, fetch them and save them to cache.
        return this.loadAppointments(from, to).pipe(tap(app => {
          this.appointments.set(newCacheKey, app);
        }));
      } else {
        // Appointments have already been found, so just return them.
        return of(cachedAppointments);
      }
    });

  getAppointment = (appointmentId: string) => defer(() => {
    let appointmentFromCache: Appointment | null = null;
    this.appointments.forEach((value) => {
      const appointment = value.find(app => app.identifier === appointmentId);
      if (appointment) {
        appointmentFromCache = appointment;
      }
    });
    if (appointmentFromCache) {
      return of(appointmentFromCache);
    } else {
      return asObservable(this.firestore().collection("appointments").doc(appointmentId).get())
        .pipe(
          flatMap(doc => combineLatest(of(doc), this.bookingInfo(doc.ref))),
          map(([doc, bookingInfo]) => mapToAppointment(doc, bookingInfo))
        )
    }
  })

  // Private functions

  private loadAppointments = (from: Date, to: Date): Observable<Appointment[]> =>
    this.appointmentsBetween(from, to)
      .pipe(switchMap(docs => {
        const docsWithInfo = docs.map(doc => this.bookingInfo(doc.ref).pipe(map(info =>
          ({ doc: doc, bookingInfo: info })
        )));
        return docsWithInfo.length === 0 ? of([]) : combineLatest(docsWithInfo);
      }), map(docs => {
        return docs.map(({ doc, bookingInfo }) => mapToAppointment(doc, bookingInfo))
      }));

  private appointmentsBetween = (from: Date, to: Date): Observable<Document[]> => new Observable(emitter =>
    this.firestore()
      .collection("appointments")
      .where("date", ">=", toFloatingDateString(from))
      .onSnapshot(snapshot => {
        const docs = snapshot.docs.filter(doc => doc.get("date") <= toFloatingDateString(to));
        emitter.next(docs);
      }, error => emitter.error(error))
  );

  private bookingInfo = (
    docRef: DocumentRef
  ): Observable<Document[]> => new Observable(emitter => {
    docRef.collection("bookingInfo").get()
      .then(result => {
        emitter.next(result.docs);
        emitter.complete();
      })
      .catch(error => emitter.error(error));
  });
}

const mapToAppointment = (doc: Document, bookingInfo: Document[]): Appointment => ({
  identifier: doc.id,
  startDate: fromFloatingDateString(doc.get("startTimestamp")),
  endDate: fromFloatingDateString(doc.get("endTimestamp")),
  bookings: bookingInfo.map(bookingDoc => ({
    identifier: bookingDoc.id,
    userInfo: {
      identifier: bookingDoc.get("userId"),
      name: bookingDoc.get("name"),
      surname: bookingDoc.get("surname"),
    },
  })),
});

type Document = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
type DocumentRef = firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;