import { Observable } from "rxjs";
import Customer from "../Entities/Customer";
import UserInfo from "../UserInfo";

export class UpdateTokenCountError extends Error { }

export default interface CustomersRepository {
  getCustomer: (customerId: string) => Observable<Customer>;
  getCustomerInfo: (customerId: string) => Observable<UserInfo | null>;
  getAllCustomersInfo: () => Observable<UserInfo[]>;
  saveRecentSearchedCustomer: (customerId: string, limit: number) => Observable<void>;
  getRecentSearchedCustomers: () => Observable<string[]>;
  createCustomer: (name: string, surname: string, email: string, phoneNumber: string | null) => Observable<Customer>;
  editCustomer: (userId: string, name: string, surname: string, email: string, phoneNumber: string | null) => Observable<void>;
  updateTokenCount: (customerId: string, packageId: string, updater: (prevCount: number) => number) => Observable<void>;
}