import { createTheme } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React, { useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import AppFactory from './DI/AppFactory';
import { AppFactoryContext } from './DI/AppFactoryContext';
import MainRouter from './Routing/MainRouter';

const theme = createTheme({
  palette: {
    primary: {
      main: "#dc9234", // orange
      contrastText: "#ffffff"
    },
    secondary: {
      main: "#2f3787", // blue
      contrastText: "#ffffff"
    }
  },
  typography: {
    fontFamily: "-apple-system, BlinkMacSystemFont, sans-serif",
  }
});

const App = () => {
  const [factory] = useState(new AppFactory());

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AppFactoryContext.Provider value={factory}>
          <MainRouter />
        </AppFactoryContext.Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
