import { createStyles, makeStyles } from '@material-ui/core';
import React from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import CustomToolbar from '../../Commons/CustomToolbar';
import DragAndDropCalendar from '../../Commons/DragAndDropCalendar';
import { CalendarEvent, CalendarEventInfo } from '../../Commons/DragAndDropCalendar.helpers';

interface CompanyClosuresViewProps {
  readonly events: CalendarEvent[];
  onEventCreated: (event: CalendarEventInfo) => void;
  onEventUpdated: (event: CalendarEvent) => void;
  onEventClicked: (event: CalendarEvent) => void;
}

const useStyles = makeStyles((theme) => createStyles({
  calendar: {
    height: "85vh",
  }
}));

const CompanyClosuresScreen = (props: CompanyClosuresViewProps) => {

  const classes = useStyles();

  return (
    <DragAndDropCalendar
      className={classes.calendar}
      events={props.events}
      toolbar={CustomToolbar}
      onEventCreated={event => {
        props.onEventCreated(event);
      }}
      onEventMoved={event => {
        props.onEventUpdated(event);
      }}
      onEventResized={event => {
        props.onEventUpdated(event);
      }}
      onEventClicked={event => {
        props.onEventClicked(event)
      }}
    />
  )
};

export default CompanyClosuresScreen;