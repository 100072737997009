
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { of, Subscription } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import CreateCustomerUseCase from '../../../../Domain/Customers/CreateCustomerUseCase';
import Customer from '../../../../Domain/Entities/Customer';
import { Constants } from '../../../../Utils/Constants';
import KeyValuePair from '../../../../Utils/KeyValuePair';
import { Failure, Loading, Success } from '../../../../Utils/Result';
import NewCustomerInfo from './NewCustomerInfo';
import NewCustomerView from './NewCustomerView';
import Logger from '../../../../Logger/Logger';

export default class NewCustomerPresenter {

  private readonly subscription = new Subscription();

  private info?: NewCustomerInfo;
  private view?: NewCustomerView;

  constructor(
    private readonly createCustomerUseCase: CreateCustomerUseCase,
  ) {
  }

  attachView = (v: NewCustomerView) => {
    this.view = v;
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  setCustomerInfo = (info?: NewCustomerInfo) => {
    this.info = info;
  }

  saveCustomer = () => {
    const info = this.info;
    if (!info) return;

    let areInfoValid = true;

    if (info.name.length === 0) {
      this.view?.showNameError("Inserisci il nome del cliente");
      areInfoValid = false;
    } else {
      this.view?.showNameError("");
    }

    if (info.surname.length === 0) {
      this.view?.showSurnameError("Inserisci il cognome del cliente");
      areInfoValid = false;
    } else {
      this.view?.showSurnameError("");
    }

    const email = info.email;
    if (email.length >= 0 && !email.match(Constants.emailRegex)) {
      this.view?.showEmailError("Indirizzo email invalido");
      areInfoValid = false;
    } else {
      this.view?.showEmailError("");
    }

    if (info.phoneNumber && info.phoneNumber.length > 0 && !info.phoneNumber.match(Constants.phoneNumberRegex)) {
      this.view?.showPhoneError("Numero di telefono invalido");
      areInfoValid = false;
    } else if (info.phoneNumber && !(parsePhoneNumberFromString(info.phoneNumber)?.country)) {
      this.view?.showPhoneError("Prefisso telefonico mancante (es. +39)");
      areInfoValid = false;
    } else {
      this.view?.showPhoneError("");
    }

    if (areInfoValid) {
      const entries: KeyValuePair<string, string>[] = [
        { key: "Nome", value: info.name },
        { key: "Cognome", value: info.surname },
        { key: "Email", value: info.email }
      ];
      if (info.phoneNumber) {
        entries.push({ key: "Telefono", value: info.phoneNumber });
      }
      this.view?.showConfirmationDialog(entries);
    }
  }

  saveCustomerConfirmed = () => {
    if (!this.info) return;

    this.subscription.add(
      this.createCustomerUseCase
        .execute(this.info.name, this.info.surname, this.info.email, this.info.phoneNumber ?? null)
        .pipe(
          map(customer => Success(customer)),
          catchError(error => of(Failure<Customer>(error))),
          startWith(Loading<Customer>())
        )
        .subscribe(
          result => {
            if (result.isLoading) {
              this.view?.showLoading();
            } else {
              this.view?.hideLoading();
              result.fold(
                customer => {
                  Logger.d("customer created, navigating out");
                  this.view?.customerCreated(customer);
                },
                error => {
                  Logger.e(error);
                  this.view?.showErrorDialog();
                }
              );
            }
          },
          Logger.e
        )
    );
  }
};

