import { Box } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuIcon from '@material-ui/icons/Menu';
import React, { FunctionComponent, ReactElement } from 'react';
import { Link } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      marginLeft: drawerWidth,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1
    },
    link: {
      textDecoration: 'none',
      color: theme.palette.text.primary
    },
    title: {
      display: "flex",
      flexGrow: 1
    },
    footer: {
      color: theme.palette.grey[400]
    }
  })
);

interface DrawerListItem {
  readonly title: string;
  readonly path: string;
}

interface ResponsiveDrawerProps {
  readonly logo: string;
  readonly drawerListItems: DrawerListItem[];
  readonly selectedIndex: number;
  readonly footer: string | null;
  readonly rightButtonItem?: () => ReactElement;
}

const ResponsiveDrawer: FunctionComponent<ResponsiveDrawerProps> = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const [state, setState] = React.useState({
    mobileOpen: false,
    selectedIndex: props.selectedIndex
  });

  const handleDrawerToggle = () => {
    setState((state) => ({
      ...state,
      mobileOpen: !state.mobileOpen
    }));
  };

  const itemClickHandler = (index: number) => () => {
    setState(state => ({
      ...state,
      selectedIndex: index
    }));
  };

  const drawer = (
    <div>
      <img src={props.logo} alt="Logo del drawer" width={drawerWidth} />
      <Divider />
      <List>
        {props.drawerListItems.map(({ title, path }, index) => (
          <Link
            key={index}
            to={path}
            replace
            className={classes.link}
            onClick={itemClickHandler(index)}>
            <ListItem button selected={index === state.selectedIndex}>
              <ListItemText primary={title} />
            </ListItem>
          </Link>
        ))}
      </List>
      <Box className={classes.footer}>
        <Divider />
        <ListItem>
          <ListItemText primary={props.footer}></ListItemText>
        </ListItem>
      </Box>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon />
          </IconButton>
          <Typography className={classes.title} variant="h6" noWrap>
            {props.drawerListItems[state.selectedIndex].title}
          </Typography>
          {props.rightButtonItem && props.rightButtonItem()}
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={state.mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        {props.children}
      </main>
    </div>
  );
}

export default ResponsiveDrawer;