import { combineLatest, Observable, Subscription } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import Booking from '../../../Domain/Bookings/Booking';
import CancelBookingUseCase from '../../../Domain/Bookings/CancelBookingUseCase';
import GetCustomerFutureBookingsUseCase from '../../../Domain/Bookings/GetCustomerFutureBookingsUseCase';
import GetTokensForCurrentWeekUseCase from '../../../Domain/Bookings/GetTokensForCurrentWeekUseCase';
import GetCustomerUseCase from '../../../Domain/Customers/GetCustomerUseCase';
import Customer from '../../../Domain/Entities/Customer';
import { getPackageState, packageStatusActive, packageStatusExpired } from '../../../Domain/Entities/Package';
import GetUserUseCase from '../../../Domain/UserAuth/GetUserUseCase';
import Logger from '../../../Logger/Logger';
import { Constants } from '../../../Utils/Constants';
import DateTimeProvider from '../../../Utils/DateTimeProvider';
import { format } from '../../../Utils/DateUtils';
import UiBasicAppointment from '../../Admin/Customers/Detail/UiBasicAppointment';
import { mapPackageToUiSimplePackage } from '../../Admin/Customers/Detail/UiSimplePackage';
import UiUserOverview from './UiUserOverview';
import UserOverviewView from './UserOverviewView';
import { ascending } from "../../../Utils/ArrayUtils";
import { perUsagePackage } from "../../../Domain/Entities/PackageTypes";

export default class UserOverviewPresenter {

  private readonly subscription = new Subscription();
  private readonly customer: Observable<Customer>;
  private readonly bookings: Observable<Booking[]>;
  private readonly usedWeekTokensCount: Observable<number>;

  private view?: UserOverviewView;

  constructor(
    getUserUseCase: GetUserUseCase,
    getCustomerUseCase: GetCustomerUseCase,
    getCustomerWeekBookingsUseCase: GetCustomerFutureBookingsUseCase,
    getTokensForCurrentWeekUseCase: GetTokensForCurrentWeekUseCase,
    private readonly cancelBookingUseCaseProvider: () => CancelBookingUseCase,
    private readonly dateTimeProvider: DateTimeProvider,
  ) {
    // The current user is a customer of the system.
    const user = getUserUseCase.execute().pipe(shareReplay(1));

    this.customer = user.pipe(
      switchMap(user => getCustomerUseCase.execute(user.identifier)),
      shareReplay(1)
    );
    this.bookings = user.pipe(
      switchMap(() => getCustomerWeekBookingsUseCase.execute()),
      shareReplay(1)
    );
    this.usedWeekTokensCount = getTokensForCurrentWeekUseCase.execute().pipe(shareReplay(1));
  }

  attachView = (v: UserOverviewView) => {
    this.view = v;

    this.subscription.add(
      combineLatest(this.customer, this.usedWeekTokensCount)
        .pipe(map(([customer, tokensUsedInWeek]) =>
          this.toUiUserOverview(
            new Date(this.dateTimeProvider.currentTimeMillis()),
            customer, tokensUsedInWeek
          )
        ))
        .subscribe(
          overview => this.view?.showUserOverview(overview),
          error => {
            this.view?.showError(`
              Si è verificato un errore con il recupero dei tuoi dati. Ricarca la pagina.
            `.trim());
            Logger.e(error);
          }
        )
    );

    this.subscription.add(
      this.bookings
        .pipe(map(bookings => bookings.map(this.toAppointment)))
        .subscribe(
          displayableBookings => this.view?.showBookings(displayableBookings),
          error => {
            this.view?.showError(`
              Si è verificato un errore con il recupero delle tue prenotazioni. Ricarca la pagina.
            `.trim());
            Logger.e(error);
          }
        )
    );
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  cancelBooking = (identifier: string) => {
    this.subscription.add(
      this.bookings.pipe(take(1))
        .subscribe(
          bookings => {
            const booking = bookings.find(booking => booking.identifier === identifier)
            if (booking) {
              const day = format(booking.startDate, "EEEE dd MMMM yyyy");
              const time = format(booking.startDate, "HH:mm");
              this.view?.showBookingCancellationDialog(
                booking.identifier,
                `Sei sicuro di voler cancellare l'appuntamento di ${day} alle ${time}?`
              );
            }
          },
          Logger.e
        )
    )
  }

  confirmBookingCancellation = (identifier: string | undefined | null) => {
    if (!identifier) return;
    this.subscription.add(
      this.cancelBookingUseCaseProvider()
        .execute(identifier)
        .subscribe({
          error: Logger.e
        })
    );
  }

  // Private methods

  private toUiUserOverview = (currentDate: Date, customer: Customer, weekBookingsCount: number): UiUserOverview => {
    // Show only the first package.
    const packages = customer.packages
      .map(pack => ({
        ...pack,
        state: getPackageState(pack, currentDate)
      }))
      .filter(pack => pack.state !== packageStatusExpired)
      .sort(ascending((pack) => pack.startDate.getTime()));

    const activePackage = packages.find(pack => pack.state === packageStatusActive);
    const totalAvailableTokensInPeriod = activePackage?.type === perUsagePackage
      ? activePackage?.initialTokenCount ?? Constants.defaultWeeklyBookingsLimit
      : activePackage?.maxWeeklyBookingsCount ?? Constants.defaultWeeklyBookingsLimit;
    const totalLeftTokensInPeriod = activePackage?.type === perUsagePackage
      ? activePackage?.leftTokenCount
      : totalAvailableTokensInPeriod - weekBookingsCount;
    const leftTokenCount = activePackage?.leftTokenCount ?? 0;
    const displayablePackages = packages.map(pack => mapPackageToUiSimplePackage(pack, pack.state, date => format(date, "dd/MM/yyyy")));
    return {
      identifier: customer.identifier,
      name: customer.name,
      currentPackage: activePackage
        ? mapPackageToUiSimplePackage(activePackage, activePackage.state, date => format(date, "dd/MM/yyyy"))
        : null,
      packages: displayablePackages,
      totalTokenCount: totalAvailableTokensInPeriod,
      leftTokenCount: Math.max(0, Math.min(leftTokenCount, totalLeftTokensInPeriod)),
    }
  }

  private toAppointment: (booking: Booking) => UiBasicAppointment = (booking) => ({
    identifier: booking.identifier,
    displayableDate: format(booking.startDate, "EEEE, dd MMMM yyyy"),
    displayableTimeStart: format(booking.startDate, "HH:mm"),
    displayableTimeEnd: format(booking.endDate, "HH:mm"),
    participantsCount: "",
    isCancelable: booking.isCancelable
  })
};

