import { Observable } from "rxjs";

export const asTimestamp: (obj: any) => firebase.firestore.Timestamp =
  obj => obj as firebase.firestore.Timestamp

export const asTimestampOrNull: (obj: any) => firebase.firestore.Timestamp | null =
  obj => (obj as firebase.firestore.Timestamp) ?? null

export type FirestoreDocument = firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
export type FirestoreDocumentReference = firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
export type FirestoreCollectionReference = firebase.firestore.CollectionReference<firebase.firestore.DocumentData>;
export type FirestoreQuerySnapshot = firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>;

export const observe = (docRef: FirestoreDocumentReference): Observable<FirestoreDocument> =>
  new Observable(emitter => docRef.onSnapshot(doc => emitter.next(doc), error => emitter.error(error)));

export const observeCollection = (collectionRef: FirestoreCollectionReference): Observable<FirestoreQuerySnapshot> =>
  new Observable(emitter => collectionRef.onSnapshot(doc => emitter.next(doc), error => emitter.error(error)));