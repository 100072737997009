
const enabledLoggers: ILogger[] = [];

export interface ILogger {
  d: (message: any) => void;
  i: (message: any) => void;
  w: (message: any) => void;
  e: (message: any) => void;
}

export const setupLoggers = (loggers: ILogger[]) => {
  loggers.forEach(logger => enabledLoggers.push(logger));
}

const Logger: ILogger = {
  d: message => enabledLoggers.forEach(logger => logger.d(message)),
  i: message => enabledLoggers.forEach(logger => logger.i(message)),
  w: message => enabledLoggers.forEach(logger => logger.w(message)),
  e: message => enabledLoggers.forEach(logger => logger.e(message)),
};

export default Logger;