import { useState, useEffect } from "react";
import { Observable } from "rxjs";
import ViewModel from "./ViewModel";

export function useObservable<T>(observable: Observable<T>): [T | null, Error | null] {
  const [state, setState] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  useEffect(() => {
    const subscription = observable.subscribe(
      value => setState(value),
      error => setError(error)
    );
    return () => {
      subscription.unsubscribe();
    }
  }, [observable]);
  return [state, error];
}

export function useViewModel(viewModel: ViewModel) {
  useEffect(() => viewModel.onCleared, [viewModel]);
}