import { Card, createStyles, InputBase, makeStyles } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import React from 'react';

const useStyles = makeStyles((theme) =>
  createStyles({
    containerCard: {
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center'
    },
    input: {
      marginLeft: theme.spacing(1),
      flex: 1,
    },
    iconButton: {
      padding: 10,
    },
    divider: {
      height: 28,
      margin: 4,
    },
  }),
);

interface SearchBarProps {
  /** The suggestion displayed into the search bar */
  readonly hint?: string;
  /** Callback fired every time the text of the search bar changes */
  readonly onTextChanged?: (text: string) => void;
}

/**
 * A search bar that appears into a card in the classic Google-like style.
 * @param props The props
 */
const SearchBar = (props: SearchBarProps) => {
  const classes = useStyles();
  return (
    <Card className={classes.containerCard}>
      <InputBase
        onChange={(e) => props.onTextChanged && props.onTextChanged(e.target.value)}
        className={classes.input}
        placeholder={props.hint} />
      <div className={classes.iconButton} aria-label="search">
        <Search />
      </div>
    </Card>
  )
}

export default SearchBar;