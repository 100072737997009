
import { Subscription } from 'rxjs';
import UserHomeView from './UserHomeView';

export default class UserHomePresenter {

  private readonly subscription = new Subscription();

  private view?: UserHomeView;

  attachView = (v: UserHomeView) => {
    this.view = v;
  }

  detachView = () => {
    this.view = undefined;
    this.subscription.unsubscribe();
  }

  logout = () => {
  }

  // Private methods

};

