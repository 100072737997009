import { Box, createStyles, makeStyles } from "@material-ui/core";
import React from "react";
import brokenIconSrc from '../../broken.svg';
import UserErrors from "../../Domain/UserErrors";
import strings from "../Utils/LocalizedStrings";

export interface ErrorScreenProps {
    readonly error: string;
}

const useStyles = makeStyles((theme) => createStyles({
    container: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh"
    },
    text: {
        textAlign: "center",
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
    },
}));

const ErrorScreen = (props: ErrorScreenProps) => {
    const classes = useStyles();

    let message: string;
    switch (props.error) {
        case UserErrors.networkUnavailable:
            message = strings.error.networkUnreachable;
            break;
        default:
            message = strings.error.genericError;
    }

    return (
        <Box
            className={classes.container}>
            <img
                src={brokenIconSrc}
                alt={strings.alt.errorImageAlt}
                width="275"
            />
            <h1>{strings.error.title}</h1>
            <p className={classes.text}>{message}</p>
        </Box>
    );
}

export default ErrorScreen;