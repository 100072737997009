
import { Box, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import UiBasicAppointment from '../../Admin/Customers/Detail/UiBasicAppointment';
import SimpleDialog from '../../Commons/SimpleDialog';
import Snackbar from '../../Commons/Snackbar';
import UiUserOverview from './UiUserOverview';
import UserOverviewPresenter from './UserOverviewPresenter';
import UserOverviewScreen from './UserOverviewScreen';
import UserOverviewView from './UserOverviewView';

interface UserOverviewViewControllerProps {
  readonly presenter: UserOverviewPresenter;
  readonly onBookNowClicked: () => void;
}

interface UserOverviewState {
  readonly overviewData?: UiUserOverview;
  readonly bookings?: UiBasicAppointment[];
  readonly cancellationDialogInfo: CancellationDialogInfo | null;
  readonly errorMessage: string | null;
}

interface CancellationDialogInfo {
  readonly bookingId: string,
  readonly message: string,
}

const UserOverviewViewController = (props: UserOverviewViewControllerProps) => {
  const [state, setState] = useState<UserOverviewState>({
    cancellationDialogInfo: null,
    errorMessage: null,
  });
  const [presenter] = useState(props.presenter);

  const view: () => UserOverviewView = () => ({
    showUserOverview: (overview) => setState(state => ({ ...state, overviewData: overview })),
    showBookings: (bookings) => setState(state => ({ ...state, bookings: bookings })),
    showBookingCancellationDialog: (id, message) => setState(state => ({
      ...state,
      cancellationDialogInfo: {
        bookingId: id,
        message: message
      }
    })),
    showError: message => setState(state => ({ ...state, errorMessage: message })),
  });

  const closeDialog = () => setState(state => ({ ...state, cancellationDialogInfo: null }));
  const clearErrorMessage = () => setState(state => ({ ...state, errorMessage: null }));

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  const { overviewData, bookings } = state;
  if (overviewData) {
    return (
      <Box>
        <UserOverviewScreen
          displayName={overviewData.name}
          totalTokenCount={overviewData.totalTokenCount}
          availableTokenCount={overviewData.leftTokenCount}
          arePackagesLoading={false}
          activePackage={overviewData.currentPackage}
          packages={overviewData.packages}
          bookings={bookings ?? []}
          areBookingsLoading={bookings === undefined}
          onBookNowClicked={props.onBookNowClicked}
          onCancelBookingClicked={presenter.cancelBooking}
        />
        {state.cancellationDialogInfo
          ? <SimpleDialog
            isVisible
            title="Annulla appuntamento"
            content={state.cancellationDialogInfo?.message ?? ""}
            confirmButtonText="Conferma"
            onConfirmButtonClicked={() => {
              presenter.confirmBookingCancellation(state.cancellationDialogInfo?.bookingId);
              closeDialog();
            }}
            onCancelButtonClicked={closeDialog}
          />
          : null}
        <Snackbar
          open={state.errorMessage !== null}
          message={state.errorMessage ?? ""}
          variant="error"
          onClosed={clearErrorMessage} />
      </Box>
    );
  } else {
    return <CircularProgress />
  }
}

export default UserOverviewViewController;

