import { add, isBefore, isSameDay, startOfDay, getHours } from "date-fns";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import DateTimeProvider from "../../Utils/DateTimeProvider";
import TimeSlot from "../Entities/TimeSlot";
import BookingsRepository from "./BookingsRepository";
import { Constants } from "../../Utils/Constants";

/**
 * Returns all the time slots that can be used for booking an appointment.
 */
export default class GetAvailableTimeSlotsUseCase {

  constructor(
    private readonly bookingsRepository: BookingsRepository,
    private readonly dateTimeProvider: DateTimeProvider,
  ) {
  }

  execute: (day: Date) => Observable<TimeSlot[]> = day =>
    this.bookingsRepository
      .getAvailableTimeSlots(day.getDate(), day.getMonth(), day.getFullYear())
      .pipe(map(slots => {
        const currentTime = this.dateTimeProvider.currentTimeMillis()
        const today = startOfDay(currentTime);
        const tomorrow = add(today, { days: 1 });
        if (isBefore(day, today) || isSameDay(day, today)) {
          // The user wants to book in the past or for today
          // but that's not possible.
          return [];
        } else if (isSameDay(day, tomorrow)) {
          // The user wants to book for the next day.
          if (getHours(currentTime) >= Constants.startHourOfAfternoonSlots) {
            // Filter out the tomorrow morning slots if now is afternoon.
            return slots.filter(slot => slot.startHour >= Constants.startHourOfAfternoonSlots);
          } else {
            // It's morning, so the user can book freely for tomorrow.
            return slots;
          }
        } else {
          // The user can book in the future.
          return slots;
        }
      }))
}