export const groupBy = function <T, K>(array: Array<T>, key: (item: T) => K): Map<K, T[]> {
  const map = new Map<K, T[]>();
  array.forEach(item => {
    const itemKey = key(item);
    const grouping = map.get(itemKey);
    if (grouping) {
      grouping.push(item);
      map.set(itemKey, grouping);
    } else {
      map.set(itemKey, [item]);
    }
  })
  return map;
}

export const flatMap = function <T, V>(array: T[], block: (item: T) => V[]): V[] {
  const newArray: V[] = [];
  array.forEach(element => {
    newArray.push(...block(element));
  });
  return newArray;
}

/**
 * Creates an array of the given size and initialized its contents with the provided function.
 * @param size size of the array to create
 * @param item function that creates the item at the given index
 */
export const arrayOfSize = function <T>(size: number, item: (index: number) => T): T[] {
  let index = 0;
  const array: T[] = [];
  while (index < size) {
    array.push(item(index++));
  }
  return array;
}

export const windowed = function <T>(array: T[], size: number): T[][] {
  const arrayContainer: T[][] = [];
  array.forEach((item, i) => {
    if ((i % size) === 0) {
      arrayContainer.push([item]);
    } else {
      const last = arrayContainer.pop() ?? [];
      arrayContainer.push(last.concat([item]));
    }
  });
  return arrayContainer;
}

export const distinctBy = function <T>(array: T[], selector: (element: T) => any): T[] {
  const arr: T[] = [];
  return array.reduce((newArray, element) => {
    const index = newArray.findIndex(newElem => selector(newElem) === selector(element));
    if (index === -1) {
      newArray.push(element);
    }
    return newArray;
  }, arr);
}

export const maxBy = function <T>(array: T[], selector: (element: T) => any): T | null {
  return compareBy(array, selector, (lhs, rhs) => lhs > rhs);
}

export const minBy = function <T>(array: T[], selector: (element: T) => any): T | null {
  return compareBy(array, selector, (lhs, rhs) => lhs < rhs);
}

export const compareBy = function <T>(
  array: T[],
  selector: (element: T) => any,
  comparator: (lhs: any, rhs: any) => boolean
): T | null {
  if (array.length === 0) return null;
  if (array.length === 1) return array[0];
  let max = array[0];
  array.forEach((element) => {
    if (comparator(selector(element), selector(max))) {
      max = element;
    }
  });
  return max;
}

export const ascending = <T>(selector: (value: T) => number): (a: T, b: T) => number => {
  return (a, b) => {
    return selector(a) - selector(b);
  }
};
