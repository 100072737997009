import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Checkbox, CircularProgress, createStyles, FormControlLabel, Grid, Hidden, IconButton, makeStyles, TextField, Tooltip } from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import PasswordChangeForm, { PasswordChangeFormProps } from '../../Commons/PasswordChangeForm';
import Select from '../../Commons/Select';
import strings from '../../Utils/LocalizedStrings';
import BusinessHoursPicker from './BusinessHoursPicker';
import { UiAppointmentDuration, UiBusinessDay, UiSchedule } from './SettingsPresenter';
import { locale } from "../../../Utils/DateUtils";

interface SettingsViewProps {
  readonly schedules: UiSchedule[];
  readonly selectedSchedule: UiSchedule | null;
  readonly businessDays: UiBusinessDay[];
  readonly appointmentDurations: UiAppointmentDuration[];
  readonly passwordProps: PasswordProps;
  readonly isUpdatePasswordButtonEnabled: boolean;
  readonly showPasswordLoading: boolean;

  readonly onAddScheduleClicked: () => void;
  readonly onDeleteScheduleClicked: () => void;
  readonly onScheduleSelected: (scheduleId: string) => void;
  readonly onBusinessDaySelectedChanged: (businessDay: UiBusinessDay, isSelected: boolean) => void;
  readonly onSlotStartChange: (businessDay: UiBusinessDay, slotIndex: number, newStart: Date) => void;
  readonly onSlotEndChange: (businessDay: UiBusinessDay, slotIndex: number, newEnd: Date) => void;
  readonly onSlotCustomersLimitChange: (businessDay: UiBusinessDay, slotIndex: number, newLimit: number) => void;
  readonly onSlotDelete: (businessDay: UiBusinessDay, slotIndex: number) => void;
  readonly onSlotAdd: (businessDay: UiBusinessDay) => void;
  readonly onDurationChanged: (durationId: string) => void;
  readonly onChangePasswordClicked: () => void;
  readonly onSaveDetailsClicked: (description: string | null, startDate: Date | null, endDate: Date | null) => void;
}

interface PasswordProps extends PasswordChangeFormProps {
}

const useStyles = makeStyles((theme) => createStyles({
  row: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  column: {
    display: "flex",
    flexDirection: "column"
  },
  sectionTitle: {
    ...theme.typography.h4,
  },
  subsectionTitle: {
    ...theme.typography.h6,
  },
  sectionContent: {
    marginBottom: theme.spacing(3),
    marginRight: theme.spacing(2),
  },
  bottomMargin: {
    marginBottom: theme.spacing(4),
  },
  smallBottomMargin: {
    // marginBottom: theme.spacing(2),
  },
  smallRightMargin: {
    marginRight: theme.spacing(2),
  },
  checkbox: {
    width: "62px"
  },
  itemGrow: {
    display: "flex",
    flexGrow: 1
  },
  changePasswordButton: {
    width: 300
  }
}));

const time = (hour: number, minute: number) => {
  const date = new Date();
  date.setHours(hour);
  date.setMinutes(minute);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

interface SchedulesDetailsState {
  readonly description: string | null;
  readonly startDate: Date | null;
  readonly endDate: Date | null;
}

const SettingsScreen = (props: SettingsViewProps) => {
  // const { } = props;

  const classes = useStyles();

  const [schedulesDetailsState, setSchedulesDetailsState] = useState<SchedulesDetailsState>({
    description: null,
    startDate: null,
    endDate: null,
  });

  useEffect(() => {
    setSchedulesDetailsState({
      description: props.selectedSchedule?.description ?? null,
      startDate: props.selectedSchedule?.startDate ?? null,
      endDate: props.selectedSchedule?.endDate ?? null,
    });
  }, [props.selectedSchedule]);

  const saveDetailsClicked = () => {
    const { description, startDate, endDate } = schedulesDetailsState;
    props.onSaveDetailsClicked(description, startDate, endDate);
  };

  return (
    <Box className={classes.column}>
      <Grid container>

        {/* Giorni lavorativi */}

        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale()}>
          <Grid item lg={5} xs={12} className={classes.sectionContent}>
            <Box className={classes.column}>
              {/* Title */}
              <span className={classes.sectionTitle}>
                {strings.admin.settings.businessHours}
              </span>
              {/* Description */}
              <p className={classes.bottomMargin}>
                {strings.admin.settings.businessHoursTopDescr}<br />
                {strings.admin.settings.businessHoursBottomDescr}
              </p>
              {/* Available periods and period data */}
              <Grid container alignItems="center" spacing={2} className={classes.bottomMargin}>
                <Grid item xs={11} md={8}>
                  <Select
                    className={`${classes.itemGrow} ${classes.smallBottomMargin}`}
                    label={strings.admin.settings.seasonalHours}
                    value={props.selectedSchedule?.identifier ?? ""}
                    entries={props.schedules.map(schedule => ({
                      key: schedule.identifier, value: schedule.description
                    }))}
                    onSelect={props.onScheduleSelected}
                    enabled
                  />
                </Grid>

                <Grid item xs={1} md={4} >
                  <Tooltip title={strings.admin.settings.addHours}>
                    <IconButton
                      size="small"
                      onClick={props.onAddScheduleClicked}>
                      <AddCircleOutline />
                    </IconButton>
                  </Tooltip>
                </Grid>

                <Grid item xs={12}>
                  {
                    props.businessDays.map(businessDay =>
                      <Grid container
                        className={`${classes.row} ${classes.bottomMargin}`}
                        key={businessDay.name}>
                        <Grid item xs={12} lg={2}>
                          <FormControlLabel
                            className={classes.checkbox}
                            value={businessDay.name}
                            control={
                              <Checkbox
                                value="secondary"
                                color="primary"
                                inputProps={{ 'aria-label': 'secondary checkbox' }}
                                checked={businessDay.isSelected}
                                onChange={event => props.onBusinessDaySelectedChanged(businessDay, event?.target?.checked === true)}
                              />
                            }
                            label={businessDay.name}
                            labelPlacement="end"
                          />
                        </Grid>
                        <Grid item xs={12} lg={10}>
                          <BusinessHoursPicker
                            inheritsPickerUtilsProvider
                            slots={businessDay.hours.map(({ start, end, customersLimit }) => ({
                              start: time(start.hour, start.minute),
                              end: time(end.hour, end.minute),
                              customersLimit: customersLimit
                            }))}
                            onSlotStartChange={(index, date) =>
                              props.onSlotStartChange(businessDay, index, date)
                            }
                            onSlotEndChange={(index, date) =>
                              props.onSlotEndChange(businessDay, index, date)
                            }
                            onSlotCustomersLimitChange={(index, number) =>
                              props.onSlotCustomersLimitChange(businessDay, index, number)
                            }
                            onSlotDelete={(index) =>
                              props.onSlotDelete(businessDay, index)
                            }
                            onSlotAdd={() => props.onSlotAdd(businessDay)}
                          />
                        </Grid>
                      </Grid>
                    )
                  }
                </Grid>

                <Grid item xs={12}>
                  {props.selectedSchedule
                    ? <span className={classes.subsectionTitle}>
                      {strings.admin.settings.detailsSectionTitle}
                    </span>
                    : null}
                </Grid>

                {/* Period name */}
                <Grid item xs={12} lg={8}>
                  {schedulesDetailsState.description !== null
                    ? <TextField
                      className={`${classes.itemGrow} ${classes.smallBottomMargin}`}
                      label={strings.admin.settings.description}
                      placeholder={strings.admin.settings.descriptionSample}
                      value={schedulesDetailsState.description}
                      onChange={e => {
                        const value = e.target?.value ?? "";
                        setSchedulesDetailsState(state => ({ ...state, description: value }))
                      }}
                    />
                    : null
                  }
                </Grid>

                <Hidden mdDown>
                  <Grid item lg={4} />
                </Hidden>

                {/* Period dates */}
                <Grid item xs={12}>
                  {(schedulesDetailsState.startDate && schedulesDetailsState.endDate)
                    ? <Box className={classes.row}>
                      <DatePicker
                        className={classes.smallRightMargin}
                        autoOk
                        cancelLabel={strings.commons.cancel}
                        label={strings.admin.settings.startDate}
                        format="dd / MM / yyyy"
                        value={schedulesDetailsState.startDate}
                        onChange={date =>
                          date && setSchedulesDetailsState(state => ({ ...state, startDate: date }))
                        } />
                      <DatePicker
                        autoOk
                        cancelLabel={strings.commons.cancel}
                        label={strings.admin.settings.endDate}
                        format="dd / MM / yyyy"
                        value={schedulesDetailsState.endDate}
                        minDate={schedulesDetailsState.startDate}
                        minDateMessage={strings.admin.settings.errorFutureEndDate}
                        onChange={date =>
                          date && setSchedulesDetailsState(state => ({ ...state, endDate: date }))
                        } />
                    </Box>
                    : null
                  }
                </Grid>

                {/* Action button */}
                <Grid item xs={12}>
                  {props.selectedSchedule
                    ? <Box className={classes.row}>
                      <Tooltip title={strings.admin.settings.saveTooltip} enterDelay={500}>
                        <Button
                          className={classes.smallRightMargin}
                          variant="contained"
                          size="small"
                          color="primary"
                          onClick={() => saveDetailsClicked()}
                          disableElevation >{strings.admin.settings.saveDetails}</Button>
                      </Tooltip>
                      <Tooltip title={strings.admin.settings.deleteSeasonalHoursTooltip} enterDelay={500}>
                        <Button
                          variant="outlined"
                          size="small"
                          color="primary"
                          onClick={props.onDeleteScheduleClicked}
                          disableElevation >{strings.admin.settings.deleteSeasonalHours}</Button>
                      </Tooltip>
                    </Box>
                    : null
                  }
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </MuiPickersUtilsProvider>

        {/* Account */}

        <Grid item lg={5} xs={12} className={classes.sectionContent}>
          <Box className={classes.column}>

            <span className={classes.sectionTitle}>{strings.admin.settings.account}</span>
            <PasswordChangeForm
              password={props.passwordProps.password}
              passwordConfirmation={props.passwordProps.passwordConfirmation}
              passwordError={props.passwordProps.passwordError}
              onPasswordChanged={props.passwordProps.onPasswordChanged}
              onPasswordConfirmationChanged={props.passwordProps.onPasswordConfirmationChanged}
            />
            {
              props.showPasswordLoading
                ? <CircularProgress />
                : <Button
                  className={classes.changePasswordButton}
                  disabled={!props.isUpdatePasswordButtonEnabled}
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => props.onChangePasswordClicked()}>
                  {strings.admin.settings.changePassword}
                </Button>
            }
          </Box>
        </Grid>
      </Grid>
    </Box >
  )
};

export default SettingsScreen;

