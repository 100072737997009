
export type RestErrorType =
    "network-unavailable"
    | "other"
    ;

export class RestError extends Error {

    constructor(
        readonly type: RestErrorType
    ) {
        super(type);
    }
}