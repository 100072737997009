import { Button, createStyles, makeStyles } from '@material-ui/core';
import { ExitToApp } from '@material-ui/icons';
import React, { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import logo from '../../logo.svg';
import {
  ROUTE_ADMIN_CALENDAR,
  ROUTE_ADMIN_CLIENTS,
  ROUTE_ADMIN_CLOSURES,
  ROUTE_ADMIN_SETTINGS,
  ROUTE_LOGIN
} from '../../Routing/RoutingConstants';
import Version from '../../version';
import LoadingDialog from '../Commons/LoadingDialog';
import ResponsiveDrawer from '../Commons/ResponsiveDrawer';
import LogoutPresenter from '../Logout/LogoutPresenter';
import LogoutView from '../Logout/LogoutView';
import AdminHomeView from './AdminHomeView';
import CalendarViewController from './Calendar/CalendarViewController';
import CompanyClosuresViewController from './CompanyClosures/CompanyClosuresViewController';
import CustomersViewController from './Customers/CustomersViewController';
import SettingsViewController from './Settings/SettingsViewController';
import strings from '../Utils/LocalizedStrings';
import { useAppFactory } from "../../DI/AppFactoryContext";


const useStyles = makeStyles((theme) => createStyles({
  drawerContainerComponent: {
    background: theme.palette.common.white,
    padding: theme.spacing(3)
  },
  logoutButton: {
    color: theme.palette.common.white
  }
}));

const items = [
  {
    title: strings.admin.navigation.appointments,
    path: ROUTE_ADMIN_CALENDAR
  },
  {
    title: strings.admin.navigation.clients,
    path: ROUTE_ADMIN_CLIENTS
  },
  {
    title: strings.admin.navigation.companyClosures,
    path: ROUTE_ADMIN_CLOSURES
  },
  {
    title: strings.admin.navigation.settings,
    path: ROUTE_ADMIN_SETTINGS
  },
];

interface AdminHomeViewControllerProps {
  readonly logoutPresenter: LogoutPresenter;
  /**
   * The path that the user wants to navigate to. 
   * Based on that, this component will render the matching subcomponent 
   * and react accordingly.
   */
  readonly selectedItemPath: string;
}

const AdminHomeViewController = (props: AdminHomeViewControllerProps) => {
  const factory = useAppFactory();
  const history = useHistory();
  const { logoutPresenter, selectedItemPath } = props;

  const [presenter] = useState(factory.adminHomePresenter());
  const [isLoading, setLoading] = useState(false);

  const view: () => AdminHomeView = useCallback(() => ({
  }), []);

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter, view]);

  const logoutView: () => LogoutView = useCallback(() => ({
    showLogoutProgress: () => setLoading(true),
    hideLogoutProgress: () => setLoading(false),
    navigateToLogin: () => history.replace(ROUTE_LOGIN)
  }), [history]);

  useEffect(() => {
    logoutPresenter.attachView(logoutView());
    return () => logoutPresenter.detachView();
  }, [logoutPresenter, logoutView]);

  const result = items.findIndex(item => selectedItemPath.indexOf(item.path) >= 0);
  const index = result >= 0 ? result : 0;

  const classes = useStyles();

  return (
    <ResponsiveDrawer
      key={index}
      logo={logo}
      drawerListItems={items}
      footer={Version.name}
      rightButtonItem={() =>
        <Button
          className={classes.logoutButton}
          startIcon={<ExitToApp />}
          onClick={() => logoutPresenter.logout()}>
          {strings.commons.logout}
        </Button>
      }
      selectedIndex={index}>
      <div className={classes.drawerContainerComponent}>
        <Switch>
          <Route
            path={ROUTE_ADMIN_CALENDAR}
            render={(() =>
              <CalendarViewController
                factory={factory}
                presenter={factory.calendarPresenter()} />)} />
          <Route
            path={ROUTE_ADMIN_CLIENTS}
            render={(() =>
              <CustomersViewController
                presenter={factory.customersPresenter()}
                factory={factory} />)} />
          <Route
            path={ROUTE_ADMIN_CLOSURES}
            render={() =>
              <CompanyClosuresViewController
                presenter={factory.companyClosuresPresenter()} />
            } />
          <Route
            path={ROUTE_ADMIN_SETTINGS}
            render={() =>
              <SettingsViewController
                presenter={factory.settingsPresenter()} />
            } />
          <Redirect to={ROUTE_ADMIN_CALENDAR} />
        </Switch>
        {isLoading ? <LoadingDialog /> : <div />}
      </div>
    </ResponsiveDrawer>
  )
}

export default AdminHomeViewController;

