import { Observable } from "rxjs";
import { BusinessDay } from "./BusinessDay";
import SettingsRepository from "./SettingsRepository";

export default class SaveBusinessDaysUseCase {

  constructor(
    private readonly settingsRepository: SettingsRepository,
  ) { }

  execute = (scheduleId: string, businessDays: BusinessDay[]): Observable<void> =>
    this.settingsRepository.saveSelectedBusinessDays(scheduleId, businessDays);
}