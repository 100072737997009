import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import AppointmentRepoImp from '../Data/AppointmentRepoImp';
import BookingsRepoImp from '../Data/BookingsRepoImp';
import CompanyRepoImp from '../Data/CompanyRepoImp';
import CustomersRepoImp from "../Data/CustomersRepoImp";
import PackagesRepoImp from '../Data/PackagesRepoImp';
import BookingsApi from '../Data/Rest/BookingsApi';
import FirebaseBookingsApi from '../Data/Rest/FirebaseBookingsApi';
import FirebaseUserApi from '../Data/Rest/FirebaseUserApi';
import UserApi from "../Data/Rest/UserApi";
import SettingsRepoImp from '../Data/SettingsRepoImp';
import UserRepoImp from "../Data/UserRepoImp";
import AppointmentsRepository from '../Domain/Appointments/AppointmentsRepository';
import GetAppointmentsUseCase from '../Domain/Appointments/GetAppointmentsUseCase';
import GetAppointmentUseCase from '../Domain/Appointments/GetAppointmentUseCase';
import GetCustomersAppointmentsUseCase from '../Domain/Appointments/GetCustomersAppointmentsUseCase';
import BookAppointmentUseCase from '../Domain/Bookings/BookAppointmentUseCase';
import BookingsRepository from '../Domain/Bookings/BookingsRepository';
import CancelBookingUseCase from '../Domain/Bookings/CancelBookingUseCase';
import GetAvailableMonthDaysUseCase from '../Domain/Bookings/GetAvailableMonthDaysUseCase';
import GetAvailableTimeSlotsUseCase from '../Domain/Bookings/GetAvailableTimeSlotsUseCase';
import GetCustomerFutureBookingsUseCase from '../Domain/Bookings/GetCustomerFutureBookingsUseCase';
import GetTokensForCurrentWeekUseCase from '../Domain/Bookings/GetTokensForCurrentWeekUseCase';
import IsBookingAvailableUseCase from '../Domain/Bookings/IsBookingAvailableUseCase';
import { createCompanyClosureFactory } from '../Domain/CompanyClosures/CompanyClosureFactory';
import CompanyRepository from '../Domain/CompanyClosures/CompanyRepository';
import GetCompanyClosurePeriodsUseCase from '../Domain/CompanyClosures/GetCompanyClosurePeriodsUseCase';
import SaveCompanyClosurePeriodsUseCase from '../Domain/CompanyClosures/SaveCompanyClosurePeriodsUseCase';
import CreateCustomerUseCase from '../Domain/Customers/CreateCustomerUseCase';
import CustomersRepository from '../Domain/Customers/CustomersRepository';
import EditCustomerUseCase from '../Domain/Customers/EditCustomerUseCase';
import GetAllCustomersUseCase from '../Domain/Customers/GetAllCustomersUseCase';
import GetCustomerUseCase from '../Domain/Customers/GetCustomerUseCase';
import GetRecentSearchedCustomerUseCase from '../Domain/Customers/GetRecentSearchedCustomerUseCase';
import SaveRecentSearchedCustomerUseCase from '../Domain/Customers/SaveRecentSearchedCustomerUseCase';
import SearchCustomersUseCase from '../Domain/Customers/SearchCustomersUseCase';
import IsUserLoggedUseCase from "../Domain/IsUserLoggedUseCase";
import LoginUseCase from "../Domain/LoginUseCase";
import LogoutUseCase from '../Domain/LogoutUseCase';
import AssignPackageUseCase from '../Domain/Packages/AssignPackageUseCase';
import EditPackageUseCase from '../Domain/Packages/EditPackageUseCase';
import EstimatePackageEndDateUseCase from '../Domain/Packages/EstimatePackageEndDateUseCase';
import GetAvailablePackagesUseCase from '../Domain/Packages/GetAvailablePackagesUseCase';
import GetUserPackageOnDateUseCase from '../Domain/Packages/GetUserPackageOnDateUseCase';
import PackagesRepository from '../Domain/Packages/PackagesRepository';
import CreateBusinessDayScheduleUseCase from '../Domain/Settings/CreateBusinessDayScheduleUseCase';
import DeleteBusinessDayScheduleUseCase from '../Domain/Settings/DeleteBusinessDayScheduleUseCase';
import GetAppointmentDurationUseCase from '../Domain/Settings/GetAppointmentDurationUseCase';
import GetAvailableAppointmentDurationsUseCase from '../Domain/Settings/GetAvailableAppointmentDurationsUseCase';
import GetBusinessDaysUseCase from '../Domain/Settings/GetBusinessDaysUseCase';
import SaveAppointmentDurationUseCase from '../Domain/Settings/SaveAppointmentDurationUseCase';
import SaveBusinessDaysUseCase from '../Domain/Settings/SaveBusinessDaysUseCase';
import SettingsRepository from '../Domain/Settings/SettingsRepository';
import UpdateBusinessDayScheduleUseCase from '../Domain/Settings/UpdateBusinessDayScheduleUseCase';
import GetUserUseCase from '../Domain/UserAuth/GetUserUseCase';
import ResetUserPasswordUseCase from '../Domain/UserAuth/ResetUserPasswordUseCase';
import UpdateUserPasswordUseCase from '../Domain/UserAuth/UpdateUserPasswordUseCase';
import UpdateUserPhoneNumberUseCase from '../Domain/UserAuth/UpdateUserPhoneNumberUseCase';
import UserRepository from "../Domain/UserRepository";
import firebaseConfig from '../firebaseConfig';
import setupApplication from '../setupApplication';
import AdminHomePresenter from '../UI/Admin/AdminHomePresenter';
import CalendarPresenter from '../UI/Admin/Calendar/CalendarPresenter';
import EventDetailPresenter from '../UI/Admin/Calendar/EventDetail/EventDetailPresenter';
import CompanyClosuresPresenter from '../UI/Admin/CompanyClosures/CompanyClosuresPresenter';
import CustomersPresenter from '../UI/Admin/Customers/CustomersPresenter';
import CustomerDetailPresenter from '../UI/Admin/Customers/Detail/CustomerDetailPresenter';
import NewCustomerPresenter from '../UI/Admin/Customers/NewCustomer/NewCustomerPresenter';
import PackageAssignmentViewModel, { PackageAssignmentViewModelImpl } from '../UI/Admin/Customers/PackageAssignment/PackageAssignmentViewModel';
import SettingsPresenter from '../UI/Admin/Settings/SettingsPresenter';
import AdminLoginPresenter from "../UI/Login/AdminLoginPresenter";
import LogoutPresenter from '../UI/Logout/LogoutPresenter';
import SplashScreenPresenter from "../UI/SplashScreen/SplashScreenPresenter";
import UserBookingPresenter from '../UI/User/Booking/UserBookingPresenter';
import UserOverviewPresenter from '../UI/User/Overview/UserOverviewPresenter';
import UserProfilePresenter from '../UI/User/Profile/UserProfilePresenter';
import UserHomePresenter from '../UI/User/UserHomePresenter';
import DateTimeProvider from '../Utils/DateTimeProvider';
import { lazy } from '../Utils/ObjectUtils';

export default class AppFactory {

  readonly firebaseApp = firebase.initializeApp(firebaseConfig);

  constructor() {
    setupApplication();
  }

  splashScreenPresenter: () => SplashScreenPresenter = () => new SplashScreenPresenter(this.isUserLoggedUseCase())

  loginPresenter: () => AdminLoginPresenter = () => new AdminLoginPresenter(
    new LoginUseCase(this.userRepository())
  )

  logoutPresenter: () => LogoutPresenter = () => new LogoutPresenter(
    new LogoutUseCase(this.userRepository())
  )

  // User

  userHomePresenter: () => UserHomePresenter = () => new UserHomePresenter();

  userOverviewPresenter: () => UserOverviewPresenter = () => {
    const getUserUseCase = new GetUserUseCase(this.userRepository());
    return new UserOverviewPresenter(
      getUserUseCase,
      new GetCustomerUseCase(this.customersRepository()),
      new GetCustomerFutureBookingsUseCase(getUserUseCase, this.bookingsRepository(), this.dateTimeProvider()),
      new GetTokensForCurrentWeekUseCase(getUserUseCase, this.bookingsRepository(), this.dateTimeProvider()),
      () => new CancelBookingUseCase(this.getUserPackageOnDate(), this.bookingsRepository(), this.customersRepository()),
      this.dateTimeProvider()
    );
  }
  userBookingPresenter: () => UserBookingPresenter = () => {
    const isBookingAvailable = new IsBookingAvailableUseCase(new GetCustomerUseCase(this.customersRepository()), this.dateTimeProvider());
    const getAvailableTimeSlots = new GetAvailableTimeSlotsUseCase(
      this.bookingsRepository(),
      this.dateTimeProvider()
    );
    return new UserBookingPresenter(
      new GetUserUseCase(this.userRepository()),
      isBookingAvailable,
      new BookAppointmentUseCase(
        isBookingAvailable,
        this.bookingsRepository()
      ),
      new GetAvailableMonthDaysUseCase(this.bookingsRepository()),
      getAvailableTimeSlots
    );
  }

  userProfilePresenter: () => UserProfilePresenter = () => {
    const getUser = new GetUserUseCase(this.userRepository());
    return new UserProfilePresenter(
      getUser,
      () => new UpdateUserPhoneNumberUseCase(getUser, this.userRepository()),
      () => new UpdateUserPasswordUseCase(getUser, this.userRepository()),
    );
  }

  // Admin

  adminHomePresenter: () => AdminHomePresenter = () => new AdminHomePresenter();

  calendarPresenter: () => CalendarPresenter = () => new CalendarPresenter(
    new GetAppointmentsUseCase(this.appointmentsRepository())
  )

  eventDetailPresenter: (identifier: string) => EventDetailPresenter = (identifier: string) =>
    new EventDetailPresenter(identifier, new GetAppointmentUseCase(this.appointmentsRepository()))

  customersPresenter: () => CustomersPresenter = () => new CustomersPresenter(
    new GetAllCustomersUseCase(this.customersRepository()),
    new SearchCustomersUseCase(this.customersRepository()),
    new SaveRecentSearchedCustomerUseCase(this.customersRepository()),
    new GetRecentSearchedCustomerUseCase(this.customersRepository())
  );

  customerDetailPresenter: (customerId: string) => CustomerDetailPresenter = (customerId) => new CustomerDetailPresenter(
    customerId, new GetCustomerUseCase(this.customersRepository()),
    new GetCustomersAppointmentsUseCase(this.appointmentsRepository(), this.dateTimeProvider()),
    () => new EditCustomerUseCase(this.customersRepository()),
    () => new CancelBookingUseCase(this.getUserPackageOnDate(), this.bookingsRepository(), this.customersRepository()),
    () => new ResetUserPasswordUseCase(this.userRepository()),
    this.dateTimeProvider()
  );

  newCustomerPresenter: () => NewCustomerPresenter = () => new NewCustomerPresenter(
    new CreateCustomerUseCase(this.customersRepository()),
  );

  packageAssignmentViewModel = (
    customerId: string,
    packageId: string | null,
    onPackageAssigned: (customerId: string) => void,
  ): PackageAssignmentViewModel => new PackageAssignmentViewModelImpl(
    customerId,
    packageId,
    new GetCustomerUseCase(this.customersRepository()),
    new GetAvailablePackagesUseCase(),
    new EstimatePackageEndDateUseCase(),
    () => new AssignPackageUseCase(this.packagesRepository()),
    () => new EditPackageUseCase(this.packagesRepository()),
    onPackageAssigned
  );

  companyClosuresPresenter = (): CompanyClosuresPresenter => {
    const getCompanyClosurePeriodsUseCase = new GetCompanyClosurePeriodsUseCase(this.companyRepository());
    return new CompanyClosuresPresenter(
      getCompanyClosurePeriodsUseCase,
      new SaveCompanyClosurePeriodsUseCase(getCompanyClosurePeriodsUseCase, this.companyRepository()),
      createCompanyClosureFactory(),
    );
  }

  settingsPresenter: () => SettingsPresenter = () => new SettingsPresenter(
    new GetAppointmentDurationUseCase(this.settingsRepository()),
    new GetAvailableAppointmentDurationsUseCase(this.settingsRepository()),
    new GetBusinessDaysUseCase(this.settingsRepository(), this.dateTimeProvider()),
    () => new CreateBusinessDayScheduleUseCase(this.settingsRepository()),
    () => new UpdateBusinessDayScheduleUseCase(this.settingsRepository()),
    () => new DeleteBusinessDayScheduleUseCase(this.settingsRepository()),
    () => new SaveBusinessDaysUseCase(this.settingsRepository()),
    () => new SaveAppointmentDurationUseCase(this.settingsRepository()),
    () => new UpdateUserPasswordUseCase(new GetUserUseCase(this.userRepository()), this.userRepository()),
  );

  // Use Cases

  isUserLoggedUseCase: () => IsUserLoggedUseCase = () => new IsUserLoggedUseCase(this.userRepository());

  getUserUseCase: () => GetUserUseCase = () => new GetUserUseCase(this.userRepository());

  getUserPackageOnDate = () => new GetUserPackageOnDateUseCase(this.packagesRepository());

  // Private properties

  private auth = lazy(() => this.firebaseApp.auth());
  private firestore = lazy(() => this.firebaseApp.firestore());
  private functions = lazy(() => this.firebaseApp.functions('europe-west1'));
  private userRepository = lazy<UserRepository>(() => new UserRepoImp(this.auth, this.userApi()));
  private customersRepository = lazy<CustomersRepository>(() => new CustomersRepoImp(this.firestore, this.functions, this.dateTimeProvider))
  private packagesRepository = lazy<PackagesRepository>(() => new PackagesRepoImp(this.firestore, this.dateTimeProvider()));
  private bookingsRepository = lazy<BookingsRepository>(() => new BookingsRepoImp(this.userRepository(), this.bookingsApi(), this.firestore));
  private appointmentsRepository = lazy<AppointmentsRepository>(() => new AppointmentRepoImp(this.bookingsApi(), this.firestore));
  private companyRepository = lazy<CompanyRepository>(() => new CompanyRepoImp(this.firestore));
  private settingsRepository = lazy<SettingsRepository>(() => new SettingsRepoImp(this.firestore));
  private userApi = lazy<UserApi>(() => new FirebaseUserApi(
    this.auth, this.firestore, this.functions
  ));
  private bookingsApi = lazy<BookingsApi>(() => FirebaseBookingsApi(this.functions));

  private dateTimeProvider = lazy(() => new DateTimeProvider());
}