import { Observable } from 'rxjs';
import Package from '../Entities/Package';
import PackagesRepository from './PackagesRepository';

export default class GetUserPackageOnDateUseCase {

  constructor(
    private readonly packagesRepository: PackagesRepository,
  ) { }

  execute = (customerId: string, date: Date): Observable<Package | null> => {
    return this.packagesRepository.getCustomerPackageOnDate(customerId, date);
  }
}