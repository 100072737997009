import React, { useCallback, useEffect, useState } from 'react';
import Customer from '../../../../Domain/Entities/Customer';
import KeyValueDialog from '../../../Commons/KeyValueDialog';
import LoadingDialog from '../../../Commons/LoadingDialog';
import SimpleDialog from '../../../Commons/SimpleDialog';
import KeyValuePair from '../../../../Utils/KeyValuePair';
import strings from '../../../Utils/LocalizedStrings';
import NewCustomerErrors from './NewCustomerErrors';
import NewCustomerInfo from './NewCustomerInfo';
import NewCustomerPresenter from './NewCustomerPresenter';
import NewCustomerScreen from './NewCustomerScreen';
import NewCustomerView from './NewCustomerView';

interface NewCustomerViewControllerProps {
  readonly presenter: NewCustomerPresenter;
  readonly onCustomerCreated: (customer: Customer) => void;
}

const NewCustomerViewController = (props: NewCustomerViewControllerProps) => {
  const { presenter, onCustomerCreated } = props;
  const [newCustomerInfo, setCustomerInfo] = useState<NewCustomerInfo>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errors, setErrors] = useState<NewCustomerErrors>({});
  const [isErrorDialogVisible, setErrorDialogVisible] = useState(false);
  const [confirmationEntries, setConfirmationEntries] = useState<KeyValuePair<string, string>[]>();
  const closeConfirmationDialog = () => setConfirmationEntries(undefined);

  const view: () => NewCustomerView = useCallback(() => ({
    showNameError: message => setErrors(errors => ({ ...errors, nameError: message })),
    showSurnameError: message => setErrors(errors => ({ ...errors, surnameError: message })),
    showEmailError: message => setErrors(errors => ({ ...errors, emailError: message })),
    showPhoneError: message => setErrors(errors => ({ ...errors, phoneError: message })),
    showConfirmationDialog: info => setConfirmationEntries(info),
    showErrorDialog: () => setErrorDialogVisible(true),
    showLoading: () => setLoading(true),
    hideLoading: () => setLoading(false),
    customerCreated: (customer: Customer) => onCustomerCreated(customer),
  }), [onCustomerCreated]);

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter, view]);

  useEffect(() => {
    presenter.setCustomerInfo(newCustomerInfo);
  }, [presenter, newCustomerInfo]);

  return (
    <div>
      <NewCustomerScreen
        info={newCustomerInfo}
        errors={errors}
        onCustomerInfoChange={setCustomerInfo}
      />
      <KeyValueDialog
        isVisible={!(!confirmationEntries)}
        title={strings.admin.clients.creation.confirmationTitle}
        content={confirmationEntries ?? []}
        confirmButtonText={strings.admin.clients.creation.addClient}
        onConfirmButtonClicked={() => presenter.saveCustomerConfirmed()}
        onCancelButtonClicked={closeConfirmationDialog} />
      {isLoading ? <LoadingDialog /> : <div />}
      <SimpleDialog
        isVisible={isErrorDialogVisible}
        title={strings.admin.clients.creation.errorTitle}
        content={strings.admin.clients.creation.errorMessage}
        hideCancelButton
        onConfirmButtonClicked={() => {
          closeConfirmationDialog();
          setErrorDialogVisible(false);
        }} />
    </div>
  );
}

export default NewCustomerViewController;

