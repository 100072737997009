import React from 'react';
import { makeStyles, createStyles, Box, FormControl, TextField, FormHelperText } from '@material-ui/core';
import strings from '../Utils/LocalizedStrings';


const useStyles = makeStyles((theme) => createStyles({
  columnContainer: {
    display: "flex",
    flexDirection: "column"
  },
  formControl: {
    paddingBottom: theme.spacing(0.5)
  },
  textField: {
    width: 300
  }
}));

export interface PasswordChangeFormProps {
  readonly password?: string;
  readonly passwordConfirmation?: string;
  readonly passwordError?: string;
  readonly disabled?: boolean
  readonly onPasswordChanged: (password: string) => void;
  readonly onPasswordConfirmationChanged: (password: string) => void;
}

/**
 * A component that shows a title with two text fields that allow to input a password
 * and its confirmation.
 */
const PasswordChangeForm = (props: PasswordChangeFormProps) => {

  const classes = useStyles();

  return (
    <Box className={classes.columnContainer}>
      <p>{strings.passwordChange.description}</p>

      <FormControl
        className={classes.formControl}
        variant="outlined"
        error={!!props.passwordError}>
        <TextField
          className={classes.textField}
          disabled={!!props.disabled}
          variant="outlined"
          autoComplete="off"
          label={strings.passwordChange.newPassword}
          type="password"
          value={props.password ?? ""}
          onChange={e => props.onPasswordChanged(e.target?.value ?? "")}
        />
        <FormHelperText id="password-error-field">{props.passwordError ?? ""}</FormHelperText>
      </FormControl>

      <FormControl
        className={classes.formControl}
        variant="outlined"
        error={!!props.passwordError}>
        <TextField
          className={classes.textField}
          disabled={!!props.disabled}
          variant="outlined"
          autoComplete="off"
          label={strings.passwordChange.confirmPassword}
          type="password"
          value={props.passwordConfirmation ?? ""}
          onChange={e => props.onPasswordConfirmationChanged(e.target?.value ?? "")}
        />
        <FormHelperText id="password-confirmation-error-field">{props.passwordError ?? ""}</FormHelperText>
      </FormControl>
    </Box>
  )
}

export default PasswordChangeForm;