import React, { useEffect, useState } from "react";
import EventDialog from "../../../Commons/EventDialog";
import LoadingDialog from '../../../Commons/LoadingDialog';
import { UiAppointment } from "../UiAppointment";
import EventDetailPresenter from "./EventDetailPresenter";

interface EventDetailViewControllerProps {
  readonly presenter: EventDetailPresenter;
  readonly onClose: () => void;
  readonly onCustomerClicked: (customerId: string) => void;
}

interface EventDetailState {
  readonly detail?: UiAppointment;
}

const EventDetailViewController = (props: EventDetailViewControllerProps) => {
  const { presenter, onClose } = props;
  const [state, setState] = useState<EventDetailState>({});

  const view = () => ({
    showEventDetail: (detail: UiAppointment) => {
      setState({
        detail: detail
      })
    }
  })

  useEffect(() => {
    presenter.attachView(view());
    return () => presenter.detachView();
  }, [presenter]);

  if (state.detail) {
    return (
      <EventDialog
        title={state.detail.displayableDate}
        displayableTime={state.detail.displayableTime}
        clickableEntries={state.detail.participants.map(entry => ({
          identifier: entry.userId,
          text: entry.displayName
        }))}
        onClosed={() => onClose()}
        onEntryClicked={id => props.onCustomerClicked(id)}
      />
    );
  } else {
    return (
      <LoadingDialog />
    );
  }
}

export default EventDetailViewController;