
import React from 'react';
import KeyValueDialog from '../../../Commons/KeyValueDialog';
import LoadingDialog from '../../../Commons/LoadingDialog';
import { useObservable, useViewModel } from '../../../Utils/ObservableHook';
import PackageAssignmentScreen from './PackageAssignmentScreen';
import PackageAssignmentViewModel from './PackageAssignmentViewModel';

interface PackageAssignmentViewControllerProps {
  readonly viewModel: PackageAssignmentViewModel;
  readonly messages: PackageAssignmentMessages;
}

interface PackageAssignmentMessages {
  readonly title: string;
  readonly sectionTitleWithUsername: string;
  readonly packageSelectLabel: string;
  readonly confirmationDialogTitle: string;
  readonly confirmationDialogButtonText: string;
}

const PackageAssignmentViewController = (props: PackageAssignmentViewControllerProps) => {
  const { viewModel } = props;

  useViewModel(viewModel);

  const [displayName] = useObservable(viewModel.displayName);
  const [packagesWithName] = useObservable(viewModel.packagesWithName);
  const [packageAssignmentInfo] = useObservable(viewModel.packageAssignmentInfo);
  const [errors] = useObservable(viewModel.errors);
  const [isLoading] = useObservable(viewModel.isLoading);
  const [confirmationDialogVisibility] = useObservable(viewModel.confirmationDialogVisibility);

  const isDialogVisible = (confirmationDialogVisibility?.type === "show") || false;
  const dialogEntries = (confirmationDialogVisibility?.type === "show"
    && confirmationDialogVisibility.values.map(([key, value]) => ({ key, value }))) || [];

  return (
    <div>
      <PackageAssignmentScreen
        title={props.messages.sectionTitleWithUsername.replace("%s", displayName ?? "...")}
        packageSelectLabel={props.messages.packageSelectLabel}
        packages={packagesWithName ?? []}
        info={packageAssignmentInfo}
        errors={errors ?? {}}
        onPackageTypeChange={viewModel.packageTypeChanged}
        onBuyDateChange={viewModel.buyDateChanged}
        onStartDateChange={viewModel.startDateChanged}
        onEndDateChange={viewModel.endDateChanged}
        onTokenLimitChange={viewModel.tokenLimitChanged}
        onLeftTokenCountChange={viewModel.leftTokenCountChanged}
        showBuyDate
        showLeftTokenCount={packageAssignmentInfo?.leftTokenCount !== null}
      />
      <KeyValueDialog
        title={props.messages.confirmationDialogTitle}
        content={dialogEntries}
        isVisible={isDialogVisible}
        confirmButtonText={props.messages.confirmationDialogButtonText}
        onConfirmButtonClicked={viewModel.confirmPackageAssignment}
        onCancelButtonClicked={viewModel.cancelPackageAssignment}
      />
      {isLoading ? <LoadingDialog /> : null}
    </div>
  );
}

export default PackageAssignmentViewController;

