import { Observable } from "rxjs";
import User from "../User";
import UserRepository from "../UserRepository";

export default class GetUserUseCase {
  private userRepo: UserRepository;

  constructor(userRepository: UserRepository) {
    this.userRepo = userRepository;
  }

  execute: () => Observable<User> = () => this.userRepo.getUser();
}