import UserRepository from './UserRepository';

export default class LoginUseCase {
  
  private readonly userRepository: UserRepository;
  
  constructor(userRepository: UserRepository) {
    this.userRepository = userRepository;
  }

  execute = (email: string, password: string) => this.userRepository.login(email, password);
}