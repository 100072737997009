import { Button, CircularProgress, createStyles, FormControl, FormHelperText, makeStyles, TextField } from '@material-ui/core';
import React from 'react';
import logo from '../../logo.svg';
import strings from '../Utils/LocalizedStrings';

interface AdminLoginViewProps {
  readonly onEmailChange: (email: string) => void;
  readonly onPasswordChange: (password: string) => void;
  readonly onLoginClicked: () => void;
  readonly emailErrorMessage: string;
  readonly passwordErrorMessage: string;
  readonly isLoading: boolean;
}

const useStyles = makeStyles((theme) => createStyles({
  spacing: {
    margin: theme.spacing(1)
  },
  textField: {
    width: 275
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh"
  }
}));

const AdminLoginScreen = (props: AdminLoginViewProps) => {
  const {
    onEmailChange,
    onPasswordChange,
    onLoginClicked,
    emailErrorMessage,
    passwordErrorMessage,
    isLoading
  } = props;

  const classes = useStyles();

  const textChangeHandler = (callback: (text: string) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {
    callback(event.target.value)
  };

  const submitComponent = isLoading ? <CircularProgress /> : (
    <Button
      className={classes.spacing}
      type="submit"
      variant="contained"
      color="primary"
      onClick={onLoginClicked}>
      {strings.login.loginButton}
    </Button>
  );

  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        onLoginClicked();
      }}
      className={classes.form} >

      <img
        src={logo}
        width="275"
        alt="PerformHealth logo"
      />

      <FormControl
        variant="filled"
        error={emailErrorMessage.length > 0}
        className={classes.spacing}>
        <TextField
          id="email-text-field"
          onChange={textChangeHandler(onEmailChange)}
          aria-describedby="email-error-field"
          variant="outlined"
          label={strings.login.email}
          className={classes.textField}
          disabled={isLoading}
        />
        <FormHelperText id="email-error-field">{emailErrorMessage}</FormHelperText>
      </FormControl>

      <FormControl
        variant="outlined"
        error={passwordErrorMessage.length > 0}
        className={classes.spacing}>
        <TextField
          id="password-text-field"
          onChange={textChangeHandler(onPasswordChange)}
          aria-describedby="password-error-field"
          variant="outlined"
          label={strings.login.password}
          type="password"
          className={classes.textField}
          disabled={isLoading}
        />
        <FormHelperText id="password-error-field">{passwordErrorMessage}</FormHelperText>
      </FormControl>

      {submitComponent}

    </form>
  )
};

export default AdminLoginScreen;
