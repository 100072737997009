import { from, Observable } from "rxjs";
import { flatMap, map, switchMap, take, toArray } from "rxjs/operators";
import UserInfo from "../UserInfo";
import CustomersRepository from "./CustomersRepository";

/**
 * Returns the info of the latest searched users.
 */
export default class GetRecentSearchedCustomerUseCase {

  private readonly customersRepository: CustomersRepository;

  constructor(
    customersRepository: CustomersRepository
  ) {
    this.customersRepository = customersRepository;
  }

  execute: () => Observable<UserInfo[]> = () => this.customersRepository
    .getRecentSearchedCustomers()
    .pipe(
      switchMap(ids => from(ids)),
      flatMap(id => this.customersRepository.getCustomerInfo(id).pipe(take(1))),
      toArray(),
      map(customers =>
        customers
          .filter(info => info !== null)
          .map(info => info as UserInfo)
      )
    )
}